import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Subheader from "../component/Subheader";
import BASE_URL from "../utils/appUrls";
import { memo } from "react";
import {
  createDelist,
  createResell,
  deListBlockChainMetaData,
  getCtnftList,
  getPendingList,
  saveTransactionHash,
} from "../action/Api.action";
import NoRecordsFound from "../component/NoRecordFound";
import styles from "../common/WithdrawList/order.module.scss";
import { useDispatch, useSelector } from "react-redux";
import ProducerStatus from "../common/modal/producerStatus";
import Pagination from "../common/pagination";
import axios from "axios";
import fileDownload from "js-file-download";
import { setDapp, setResubmission } from "../slices/home";
import { BASE_CURRENCY, NAME } from "../constant/constant";
import { createResellTransaction } from "../component/walletConnect";
import { useLoader } from "../context/Loader/useLoader";
import { useToast } from "../context/Toast/useToast";
import CustomLoader from "../common/customLoader";
import { useLocation } from 'react-router-dom';
const ListingCTNFT = ({ history, status }) => {
 
  const { state } = useLocation();
  const [subPagination, SetPagination] = useState()
  const itemsPerPage = process.env.REACT_APP_ITEMPERPAGE;
  const [ctnftList, setCTNFTList] = useState([]);
  const [paging, setPaging] = useState({
    count: 0,
    totalCount: 0,
  });
  const [viewMore, setViewMore] = useState(false);
  const [cardOpen, setCardOpen] = useState();
  const [width, setWidth] = useState();
  const userData = useSelector((state) => state?.auth?.profileData);
  const [CurrentStep, setCurrentStep] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReformatCertificates, setReformatCertificates] = useState([]);
  const [allCertificates, setAllCertificates] = useState([]);
  const [IsLoading,setIsLoading] = useState(false)
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const { addToast } = useToast();


  const formatDate = (dateString) => {
    const d = new Date(dateString);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };


  useEffect(()=>{
    if(!localStorage.getItem('userId'))
    history.push('/')
    SetPagination(state)
  },[state])
  const handleViewMore = (i) => {
    let checkCardOpen = cardOpen ? cardOpen : i;
    setViewMore(i === checkCardOpen ? !viewMore : viewMore);
    setCardOpen(i);
    let certificates = [];
    if (ctnftList[i].docs) {
      ctnftList[i].docs.forEach((info) => {
        certificates.push(info);
      });
    } else {
      ctnftList[i].certificates_ids.forEach((info) => {
        certificates.push(info);
      });
    }
    let reformatCertificates = [];
    for (let j = 0; j < itemsPerPage; j++) {
      reformatCertificates.push(certificates[j]);
    }
    setReformatCertificates(reformatCertificates);
    setAllCertificates(certificates);
  };

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  /*const getUserProductList = async()=>{
		let resp = await getUserProductListAction({});

		if(resp.code === 200){
			setCTNFTList(resp.data);
		}
	}*/

  const fetchCtnftList = async () => {
    setIsLoading(true)
    if (status == "inactive") {
      const resp = await getPendingList();
      if (resp?.status) {
        console.log(resp)
        resp?.data &&
          resp.data.map((nft) => {
            if (nft.state == "resubmission") {
              addToast({
                message: "CNFT Documents Rejected, Please Resubmit Documents",
                type: "error",
                duration: 3000,
              });
              return;
            }
          });
        setCTNFTList(resp?.data);
       
      }
    } else {
      let request = {
        status,
      };
      const res = await getCtnftList(request, currentPage);
      if (res?.status) {
        setCTNFTList(res?.list_ctnft);
        setPaging({
          count: paging.count == 0 ? res.ctnft_count : paging.count,
          totalCount: res.total_ctnft_count,
        });
      }
    }
    setIsLoading(false)
  };

  /* Api Calling */
  useEffect(() => {
    //getUserProductList();
    fetchCtnftList();
  }, []);

  useEffect(() => {
    // const partner_type = userData?.partner_type;
    // const partner_state = userData?.partner_state;

    // if (partner_type !== "producer") {
    //   history.push(`/edit-profile`);
    // }
    const partner_type = localStorage.getItem('userType');
 
    const partner_state = "approved"; // fetch this state from the backend first to proceed with the dashboard rendering


    //use this part to check for proper entries of supplier/producer
    
    if (partner_state !== "approved" && partner_type === "producer") {
      setModalOpen(true);
      setTimeout(() => {
        history.push(`/edit-profile`);
        setModalOpen(false);
      }, 5000);
    }
  }, []);
  const handleClose = () => {
    setModalOpen(false);
    history.push(`/edit-profile`);
  };

  const handleDeList = async (id) => {
    console.log(id);

    let certificate = [];
    certificate.push(id.certificate_id);
    let body = {
      certificates: certificate,
    };
    const res = await deListBlockChainMetaData(body);
    setLoading(
      true,
      "Please accept the transaction on your Phaeton Wallet for Delist"
    );

    if (res.status) {
      const resp = await createResellTransaction(res?.data);

      if (resp.status) {
        setLoading(true, "Creating Delisting Request");
        addToast({
          message: resp?.message,
          type: "success",
          duration: 3000,
        });

        const response = await createDelist(body);
        if (response.status) {
          addToast({
            message: "Certificate Delisted Successfull.",
            type: "success",
            duration: 3000,
          });
          // addToast({
          //   message: response?.message,
          //   type: "success",
          //   duration: 3000,
          // });
          let infos = {
            txn_type: "withdraw_relist",
            object_id: response?.object_id,
            transaction: resp?.transactionHash,
          };
          const result = await saveTransactionHash(infos);
          setLoading(true, "Saving Transaction Hash");
          if (result.status) {
            history.push("/resell");
            addToast({
              message: result?.message,
              type: "success",
              duration: 3000,
            });
            setLoading(false);
          }
        } else {
          addToast({
            message: response?.message,
            type: "error",
            duration: 3000,
          });
          setLoading(false);
        }
      } else {
        addToast({
          message: resp?.message,
          type: "error",
          duration: 3000,
        });
        setLoading(false);
      }
    } else {
      addToast({
        message: res?.message,
        type: "error",
        duration: 3000,
      });
      setLoading(false);
    }
  };

  const handleDocPagination = (page) => {
    if (page) {
      let startPage = page * itemsPerPage;
      let endPage = page + 1 * itemsPerPage;
      let nextPrevData = [];
      allCertificates.forEach((data, index) => {
        if (index + 1 >= startPage && index + 1 < endPage) {
          nextPrevData.push(data);
        }
      });
      setReformatCertificates(nextPrevData);
      //window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  const handlePagination = (page) => {
    if (page) {
      // window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    if (currentPage) {
      fetchCtnftList();
    }
  }, [currentPage]);

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const handleEdit = (dapp) => {
    dispatch(setDapp(dapp.dapp_id));
    history.push("/edit-ctnft");
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const shortHeader = (name) => {
    if (name.length > 8) {
      let shortName = name?.substr(0, 8);
      return shortName + "...";
    } else {
      return name;
    }
  };
  const mintNft = (accu) =>{
    history.push(`/mintCNFT/${accu}`);
  }

  const viewCTNFT = (e) =>{
    // history.push('/view-CTNFT')
  }
  // Deepak change
  return (
    <div>
      <Subheader subpagi={subPagination} />
   
       {IsLoading && (
        <div>
          <CustomLoader />
        </div>
      )}
      <Container className='bg-white' fluid>
            <Row>
                <Col lg={12}>
                {status === "inactive" ? (
                  <div className='table-resposive tableBox tablewithSubHeader'>
                        <table>
                            <thead>
                                <tr>
                                    <td>Sr. No.</td>
                                    <td>Project Title </td>
                                    <td>Registration No.</td>
                                    <td>Asset ID </td>
                                    <td>NFT cost </td>
                                    <td>Status  </td>
                                    <td>Action </td>


                                </tr>
                            </thead>
                            <tbody>
                            {ctnftList && ctnftList?.length > 0 ? (
                        ctnftList?.map((res, i) => {
                          if( res?.certificates_ids.length > 0)
                          return (
                            <>
                            <tr>
                                    {/* <td> {shortHeader(
                                              res?.erf_registration_number
                                            )}</td>
                                    <td>{shortHeader(res?.producer_name)}</td>
                                    <td> {res?.total_certificates_generated}</td> */}
                                    <td>{i+1}</td>
                                    <td>{res?.project_title || "No project title"}</td>
                                    <td>{res?.erf_registration_number} </td>
                                    <td>{res?.certificates_ids[0].accu}</td>
                                    <td>{res?.initial_sales_price_per_unit}</td>
                                    <td className={res?.state === 'draft' ? 'draft':res?.state === 'pending'? 'pending':res?.state === 'rejected'?'rejected':res?.state === 'submitted'?'submitted':''}> {capitalizeFirstLetter(res?.state)}</td>
                                    <td>
                                    {viewMore &&
                                      width <= 768 &&
                                      cardOpen === i ? (
                                        <></>
                                      ) : (
                                        <div className="btnbox">
                                          {/* <a
                                            href={res?.explorer_link}
                                            target="_blank"
                                            rel="noreferrer">
                                            View in Explorer
                                          </a> */}
                                          <button className="btn py-0"
                                            onClick={() => viewCTNFT(i)}>
                                              <i class="fa-regular fa-eye"></i>
                                          </button>
                                          
                                         
                                          {res?.state.toUpperCase() !==
                                          "SUBMITTED" ? (
                                            <button
                                              className="btn py-0"
                                              onClick={() => {
                                                handleEdit(res);
                                                console.log(
                                                  " res?.state.toLowerCase()",
                                                  res?.state.toLowerCase()
                                                );
                                                if (
                                                  res?.state.toLowerCase() ==
                                                  "resubmission"
                                                ) {
                                                  dispatch(
                                                    setResubmission(true)
                                                  );
                                                }
                                              }}>
                                             <i class="fa-regular fa-pen-to-square"></i>
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )}
                                      </td>
                                </tr>
                            </>
                          )
                        })
                        ):(   
                          <tr>
                            <td colSpan={8}>No Records Found.</td>
                          </tr>
                          )}
                           
                            </tbody>
                        </table>
                    </div>
                ):(
                  <div className='table-resposive tableBox tablewithSubHeader'>
                        <table>
                            <thead>
                                <tr>
                                    <td>Sr. No</td>
                                    <td>Project Title</td>
                                    <td>Registration No. </td>
                                    <td>Asset ID</td>
                                    <td>Date </td>   
                                    <td>NFT cost</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                            {ctnftList && ctnftList?.length > 0 ? (
                        ctnftList?.map((res, i) => {
                          if(res?.minted === false)
                          return(<>
                            <tr>
                              {console.log(res)}
                                    {/* <td>{res?.accu}</td>
                                    <td>{res?.authority_id}</td>
                                    <td>{res?.price}</td>
                                    <td>{capitalizeFirstLetter(res?.state)}</td> */}
                                    <td>{i+1}</td>
                                    <td>{res?.projectTitle || "No Project Title"} </td>
                                    <td>{res?.erf_registration_number}</td>
                                    <td>{res?.accu}</td>
                                    <td>{formatDate(res.date)}</td>   
                                    <td>{res?.price}</td>
                                    <td>
                                    {viewMore &&
                                        width <= 768 &&
                                        cardOpen === i ? (
                                          <></>
                                        ) : (
                                          <div className="btnbox">
                                           
                                            <button className="btn py-0"
                                              onClick={() => viewCTNFT(i)}>
                                              <i class="fa-regular fa-eye"></i>
                                             
                                            </button>
                                            <button className="btn btn-outline-primary" onClick={()=>mintNft(res?.accu)}>Mint</button>
                                            {/* <button
                                              onClick={() => handleDeList(res)}>
                                              De-List
                                            </button> */}
                                            {/* <button  className="btn py-0" onClick={()=>mintNft(res?.accu)}>
                                            <img style={{paddingBottom:'4px'}} src="/images/png/mintnft.png" width={15}  alt=""/> </button> */}
                                          </div>
                                        )}
                                        </td>   
                                </tr>
                          </>)
                        })
                        ):(  
                          <tr>
                            <td colSpan={8}>No Records Found.</td>
                          </tr>
                        )}
                            
                            </tbody>
                        </table>
                    </div>
                )}
                   
                </Col>
                <Col className="text-end" lg={12}>
                {ctnftList && paging?.totalCount > 0 ? (
                      <Pagination
                        itemsCount={paging?.totalCount || 0}
                        itemsPerPage={paging?.count || 9}
                        currentPage={currentPage}
                        setCurrentPage={(e) => handlePagination(e)}
                        alwaysShown={false}
                      />
                    ) : null}
                </Col>
            </Row>
        </Container>
      <div className="quoteRequestSec CreateCTNFTSec burger" hidden>
        <Container>
          <Row className="justify-content-md-center">
            <Col xs={12}>
              <div className="CTNFTrequestQuote tableListing boxShaddow burger">
                <h4 className={`mb-5 ${styles.bold}`}>
                  {status == "active"
                    ? "Listed"
                    : status == "inactive"
                    ? "Pending"
                    : "Sold"}{" "}
                  {NAME.certificate} Lists
                </h4>
                <div className={styles.orderSection} >
                  {ctnftList && ctnftList?.length > 0 ? (
                    <div className={`${styles.list}`}>
                      <div className={`${styles.listItems}`}>
                        <div className={styles.orderListBox}>
                          <div>
                            <div className={`${styles.listBoxBody}`}>
                              <div className={`${styles.listAlignment}`}>
                                {status == "inactive" ? (
                                  <>
                                    <div>
                                      <p>ERF Registration </p>
                                    </div>
                                    <div>
                                      <p>{NAME.supplier} Name </p>
                                    </div>

                                    <div>
                                      <p>Total Certificate </p>
                                    </div>
                                    <div>
                                      <p>Status</p>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      <p>ACCU </p>
                                    </div>
                                    <div>
                                      <p>Authority </p>
                                    </div>

                                    <div>
                                      <p>Price ({BASE_CURRENCY.CURRENCY}) </p>
                                    </div>
                                    <div>
                                      <p>Status</p>
                                    </div>
                                    
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="container-wrapper">
                    <div className={`${styles.list}`}>
                      {ctnftList && ctnftList?.length > 0 ? (
                        ctnftList?.map((res, i) => {
                          if (status == "inactive") {
                            return (
                              <div key={i} className={`${styles.listItems}`}>
                                <div className={styles.orderListBox}>
                                  <div>
                                    <div className={`${styles.listBoxBody}`}>
                                      <div
                                        className={`${styles.listAlignment}`}>
                                        <div>
                                          <span>
                                            {shortHeader(
                                              res?.erf_registration_number
                                            )}
                                          </span>
                                        </div>
                                        <div>
                                          <span>
                                            {shortHeader(res?.producer_name)}
                                          </span>
                                        </div>
                                        <div>
                                          <span>
                                            {res?.total_certificates_generated}
                                          </span>
                                        </div>
                                        <div>
                                          <span>
                                            {capitalizeFirstLetter(res?.state)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={`${styles.listBoxFooter}`}>
                                    <div className={styles.ExplorerButton}>
                                      {/* <a
                                href={res.explorer_link}
                                target="_blank"
                                rel="noreferrer">
                                View in Explorer
                              </a> */}

                                      {viewMore &&
                                      width <= 768 &&
                                      cardOpen === i ? (
                                        <></>
                                      ) : (
                                        <>
                                          {/* <a
                                            href={res?.explorer_link}
                                            target="_blank"
                                            rel="noreferrer">
                                            View in Explorer
                                          </a> */}
                                          <button
                                            onClick={() => handleViewMore(i)}>
                                            {viewMore && cardOpen === i
                                              ? "View Less"
                                              : "View More"}
                                          </button>
                                          
                                         
                                          {res?.state.toUpperCase() !==
                                          "SUBMITTED" ? (
                                            <button
                                              className="button-green"
                                              onClick={() => {
                                                handleEdit(res);
                                                console.log(
                                                  " res?.state.toLowerCase()",
                                                  res?.state.toLowerCase()
                                                );
                                                if (
                                                  res?.state.toLowerCase() ==
                                                  "resubmission"
                                                ) {
                                                  dispatch(
                                                    setResubmission(true)
                                                  );
                                                }
                                              }}>
                                              Edit {NAME.certificate}
                                            </button>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                
                                {viewMore && cardOpen === i ? (
                                  <div className={styles.viewMoreContainer}>
                                    {isReformatCertificates?.map(
                                      (resp, index) => {
                                        return (
                                          <div
                                            className={styles.orderDetails}
                                            key={index}>
                                            <div
                                              className={
                                                styles.orderBondDetail
                                              }>
                                              <div>
                                                <h4>ACCU</h4>
                                                <p>{resp?.accu}</p>
                                              </div>
                                              <div>
                                                <h4>Sale Price</h4>
                                                <p>{resp?.sale_price}</p>
                                              </div>
                                              
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                    <Pagination
                                      itemsCount={
                                        ctnftList[i]?.certificates_ids.length ||
                                        0
                                      }
                                      itemsPerPage={
                                        // ctnftList[i]?.certificates_ids.length
                                        itemsPerPage
                                      }
                                      currentPage={currentPage}
                                      setCurrentPage={handleDocPagination}
                                      alwaysShown={false}
                                    />
                                    {viewMore && width <= 768 ? (
                                      <div className={"d-grid"}>
                                        <div
                                          className={`${styles.listBoxFooter}`}>
                                          <button
                                            onClick={() => handleViewMore(i)}>
                                            {viewMore
                                              ? "View Less"
                                              : "View More"}
                                          </button>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                            );
                          } else {
                            return (
                              <div key={i} className={`${styles.listItems}`}>
                                <div className={styles.orderListBox}>
                                  <div>
                                    <div className={`${styles.listBoxBody}`}>
                                      <div
                                        className={`${styles.listAlignment}`}>
                                        <div>
                                          <span>{res?.accu}</span>
                                        </div>
                                        <div>
                                          <span>{res?.authority_id}</span>
                                        </div>
                                        <div>
                                          <span>{res?.price}</span>
                                        </div>
                                        <div>
                                          <span>
                                            {capitalizeFirstLetter(res?.state)}
                                          </span>
                                        </div>
                                        
                                      </div>
                                    </div>
                                  </div>
                                  <div className={"d-grid"}>
                                    <div className={`${styles.listBoxFooter}`}>
                                      <div className={styles.ExplorerButton}>
                                        {/* <a
                                href={res.explorer_link}
                                target="_blank"
                                rel="noreferrer">
                                View in Explorer
                              </a> */}

                                        {viewMore &&
                                        width <= 768 &&
                                        cardOpen === i ? (
                                          <></>
                                        ) : (
                                          <>
                                           
                                            <button
                                              onClick={() => handleViewMore(i)}>
                                              {viewMore && cardOpen === i
                                                ? "View Less"
                                                : "View More"}
                                            </button>
                                            {/* <button
                                              onClick={() => handleDeList(res)}>
                                              De-List
                                            </button> */}
                                            <button>Mint</button>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {viewMore && cardOpen === i ? (
                                  <div className={styles.viewMoreContainer}>
                                    {isReformatCertificates?.map(
                                      (resp, index) => {
                                        return (
                                          <div
                                            className={styles.orderDetails}
                                            key={index}>
                                            <div
                                              className={
                                                styles.orderBondDetail
                                              }>
                                              <div>
                                                <h4>Doc Name</h4>
                                                <p>{resp?.doc_name}</p>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                    <Pagination
                                      itemsCount={allCertificates?.length || 0}
                                      itemsPerPage={itemsPerPage}
                                      currentPage={currentPage}
                                      setCurrentPage={handleDocPagination}
                                      alwaysShown={false}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            );
                          }
                        })
                      ) : (
                        <NoRecordsFound />
                      )}
                    </div>
                    {/* )} */}
                    {ctnftList && paging?.totalCount > 0 ? (
                      <Pagination
                        itemsCount={paging?.totalCount || 0}
                        itemsPerPage={paging?.count || 9}
                        currentPage={currentPage}
                        setCurrentPage={(e) => handlePagination(e)}
                        alwaysShown={false}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {modalOpen && (
        <ProducerStatus
          handleClose={handleClose}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  );
};

export default memo(ListingCTNFT);
