import { Row, Col, Button } from 'react-bootstrap';
import BASE_URL from "../../utils/appUrls";
const PostRequestSec = ({ data }) => {
  return (
    <div className="postRequestSec burger">
        <Row>
            <Col xs={12}>
                <div className="postRequestContent">
                    <img src={BASE_URL.HOST + data?.image_url} className="img-fluid" />
                    <div dangerouslySetInnerHTML={{ __html: data?.banner_text }} />
                </div>
            </Col>
        </Row>
    </div>
  );
};

export default PostRequestSec;
