import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import NFTcard from './NFTcard'
import { getMarketPlaceNft } from '../../action/walletApiAction'
import { useToast } from "../../context/Toast/useToast";
import CustomLoader from "../../common/customLoader/index"
function Market() {
    const [nfttype, SetNftType] = useState('Fixed')
    const { addToast } = useToast();
    const [marketPlaceNft, setMarketPlaceNft] = useState([])
    const [loading, setLoading] = useState(false)
    const [timeZone, setTimeZone] = useState('')
    const categories = [
        "Agriculture forestry, and other land use",
        "Wind",
        "Energy efficient",
        "Biomass",
        "Liquid biofuel",
        "Solar Thermal",
        "Afforestration, reforestration and revegetation",
        "Water flow restoration",
        "Waste handling and disposal",
        "Other",
    ];
    const [filter, setFilter] = useState({
        sell_type: "fixed",
        status: true,
        category: '',
        sortBy: "DESC",
        sortField: "create_date"
    })
    useEffect(() => {
        // marketPlaceNftFunc()
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimeZone(timeZone)
    }, [])
    useEffect(() => {
        marketPlaceNftFunc()
    }, [filter])
    const marketPlaceNftFunc = async () => {
        setLoading(true)
        const res = await getMarketPlaceNft(filter)
        setLoading(false)
        console.log("nftmar", res)
        // console.log("resdata", res.data)
        if (res.Status === "success") {
            setMarketPlaceNft(res.data)
        } else {
            addToast({
                message: "Error in fetching Market Place NFT",
                type: "error",
                duration: 3000,
            });
        }
    }
    const nfttypeFun = (e) => {
        SetNftType(e)
        var sellType = "fixed"
        if (e === "Auction") {
            sellType = "auction"
        }
        setFilter((p) => ({ ...p, sell_type: sellType }))
    }
    useEffect(() => {
        console.log("filter", filter)
    }, [filter])
    const [showClearFilter, setShowClearFilter] = useState(false)
    useEffect(() => {
        if (filter.sell_type !== "fixed" || filter.category !== '' || filter.sortBy !== "DESC" || filter.sortField !== 'create_date') setShowClearFilter(true)
    }, [filter])
    const clearFilterFunc = () => {
        setFilter((p) => ({ ...p, sell_type: "fixed", status: true, category: '', sortBy: "DESC", sortField: "create_date" }))
        SetNftType('Fixed')
    }
    return (
        <>
            {loading && (
                <div>
                    <CustomLoader />
                </div>
            )}
            <Container className='marketplacebx' fluid>
                <div className='marketplaceMaine'>
                    <Row>
                        <Col lg={8}>

                            <ul>
                                <li> <h1>Marketplace</h1></li>
                            </ul>
                            <h2>Carbon Credits</h2>
                            <p>Carbon credits for the wind project provide tangible evidence of its positive environmental impact and contribute to global efforts to mitigate climate change. By incentivizing investments in renewable energy and rewarding emissions reductions, carbon credits play a crucial role in accelerating the transition to a sustainable and low-carbon future.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mb-4' lg={12}>
                            <div className='marketplaceMaineInner'>
                                <h1>Recent Added Project</h1>
                                <Row className='filtersBoxMarketPlace mt-3'>
                                    <Col lg={2}>
                                        <div className='customeCheckBoxMaine'>
                                            <h2 className='form-select'>
                                                <img src='/images/svg/shortby.svg' alt='' />
                                                Short by
                                            </h2>
                                            <div className='customecheckBox'>
                                                <ul>
                                                    <li>
                                                        <input type="radio" id='newest' />
                                                        <label htmlFor='newest' onClick={() => setFilter((p) => ({ ...p, sortBy: "DESC" }))}> Newest</label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id='oldest' />
                                                        <label htmlFor='oldest' onClick={() => setFilter((p) => ({ ...p, sortBy: "ASC" }))}> Oldest</label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id='lowtohigh' />
                                                        <label htmlFor='lowtohigh'>Price Low to High</label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id='hightolow' />
                                                        <label htmlFor='hightolow'>Price High to Low</label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg={2}>
                                        <div className='customeCheckBoxMaine'>
                                            <h2 className='form-select'>
                                                <img src='/images/svg/categoryIcon.svg' alt='' />
                                                Category
                                            </h2>
                                            <div className='customecheckBox'>
                                                <ul>
                                                    {categories.length > 0 && categories.map((item, i) =>
                                                        <li key={i}>
                                                            <input type="radio" id='newest' value={item} />
                                                            <label htmlFor='newest' onClick={(e) => setFilter((p) => ({ ...p, category: item }))}> {item}</label>
                                                        </li>
                                                    )}
                                                    {/* <li>
                                                        <input type="radio" id='oldest' />
                                                        <label htmlFor='oldest'> Newest</label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id='lowtohigh' />
                                                        <label htmlFor='lowtohigh'>Price Low to High</label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id='hightolow' />
                                                        <label htmlFor='hightolow'>Price High to Low</label>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>

                                    </Col>
                                    <Col lg={8}>
                                        <div className='d-flex align-items-center gap-3'>
                                            <div className='searchBox w-100'>
                                                <input type='text' placeholder='Search..' onChange={(e) => setFilter((p) => ({ ...p, search: e.target.value }))} />
                                                <button>
                                                    <i class="fa-solid fa-magnifying-glass"></i>
                                                </button>
                                            </div>
                                            <div>
                                                {showClearFilter === true &&
                                                    <button className='btn p-0' onClick={(e) => clearFilterFunc()}>
                                                        {/* <i class="fa-solid fa-magnifying-glass"></i> */}
                                                        <i class="fa-solid fa-arrows-rotate"></i>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <div className='marketTabs'>
                                            <button onClick={() => nfttypeFun('Fixed')} className={nfttype === 'Fixed' ? 'active' : ''}>Fixed</button>
                                            <button onClick={() => nfttypeFun('Auction')} className={nfttype === 'Auction' ? 'active' : ''} >Auction</button>

                                        </div>
                                    </Col>
                                    <Col className='mt-5' lg={12}>
                                        <Row>
                                            {marketPlaceNft.length > 0 && marketPlaceNft.map((item, index) =>
                                                <Col className='mb-3' lg={3} key={index}>

                                                    <NFTcard NFTType={item} />

                                                </Col>
                                            )}

                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        {/* <Col lg={12}> */}
                        {/* <div className='marketplaceMaineInner'>
                                <h1>Top Project</h1>

                                <Row>
                                    <Col lg={12}>
                                        <Row>
                                            <Col lg={3}>
                                                <NFTcard />
                                            </Col>
                                            <Col lg={3}>
                                                <NFTcard />
                                            </Col>
                                            <Col lg={3}>
                                                <NFTcard />
                                            </Col>
                                            <Col lg={3}>
                                                <NFTcard />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div> */}
                        {/* </Col> */}
                    </Row>
                </div>
            </Container>
        </>
    )
}

export default Market