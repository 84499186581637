import Web3 from "web3";
import { BigNumber, ethers } from "ethers";
import NFTManager from './contract/artifacts/NFTManager.json';
const contractABI = [
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "target",
				"type": "address"
			}
		],
		"name": "AddressEmptyCode",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "implementation",
				"type": "address"
			}
		],
		"name": "ERC1967InvalidImplementation",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ERC1967NonPayable",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "FailedInnerCall",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "InvalidInitialization",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "NotInitializing",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "UUPSUnauthorizedCallContext",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "bytes32",
				"name": "slot",
				"type": "bytes32"
			}
		],
		"name": "UUPSUnsupportedProxiableUUID",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint64",
				"name": "version",
				"type": "uint64"
			}
		],
		"name": "Initialized",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			}
		],
		"name": "Mint",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "implementation",
				"type": "address"
			}
		],
		"name": "Upgraded",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "UPGRADE_INTERFACE_VERSION",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "buyPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "platformpercent",
				"type": "uint256"
			}
		],
		"name": "buyNFT",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "platformpercent",
				"type": "uint256"
			}
		],
		"name": "endAuction",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_nftContractAddress",
				"type": "address"
			}
		],
		"name": "initialize",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "projectType",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "registry",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "name",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "creator",
				"type": "address"
			},
			{
				"internalType": "string",
				"name": "hash",
				"type": "string"
			}
		],
		"name": "mintNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nextTokenId",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "nftAuctions",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "seller",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "startingPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "currentBid",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "currentBidder",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "auctionEndTime",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "isAuctionActive",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "nftBurn",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "nftContract",
		"outputs": [
			{
				"internalType": "contract CCNFTContract",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "nftInfo",
		"outputs": [
			{
				"internalType": "string",
				"name": "name",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "id",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "startingPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "currentprice",
				"type": "uint256"
			},
			{
				"internalType": "string",
				"name": "registry",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "projectType",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "hash",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "creator",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "currentOwner",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "isSellActive",
				"type": "bool"
			},
			{
				"internalType": "bool",
				"name": "isburn",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "nftSells",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "id",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "creator",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "seller",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "price",
				"type": "uint256"
			},
			{
				"internalType": "bool",
				"name": "isSellActive",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"name": "onERC721Received",
		"outputs": [
			{
				"internalType": "bytes4",
				"name": "",
				"type": "bytes4"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "bidPrice",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "newBidder",
				"type": "address"
			}
		],
		"name": "placeBid",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "proxiableUUID",
		"outputs": [
			{
				"internalType": "bytes32",
				"name": "",
				"type": "bytes32"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "sellPrice",
				"type": "uint256"
			}
		],
		"name": "sellNFT",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_admin",
				"type": "address"
			}
		],
		"name": "setAdmin",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "startingPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "auctionDuration",
				"type": "uint256"
			}
		],
		"name": "startAuction",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newImplementation",
				"type": "address"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "upgradeToAndCall",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
]
const provider = new ethers.JsonRpcProvider(process.env.REACT_APP_RPC_URl_TESTNET);


// import Web3 from "web3";
// import { BigNumber, ethers } from "ethers";
// import HDWalletProvider from '@truffle/hdwallet-provider';
// import NFTContract from './contract/artifacts/NFTContract.json';
// import NFTManager from './contract/artifacts/NFTManager.json';
//  const provider = new ethers.JsonRpcProvider("https://rpc-amoy.polygon.technology/");
//const provider = new ethers.providers.InfuraProvider("Amoy", "c429648c90fd48929e9b50e4fd3037a4");

class Web3Intraction {

    constructor(privateKey) {
        this.wallet = new ethers.Wallet(privateKey, provider);
        this.contractAddress = '0xdB4ddF725B02fA88B40A268C32A023604c8630Fd'; // Address of your deployed NFT contract
        this.nftContract = new ethers.Contract(this.contractAddress, contractABI, this.wallet);
    //     this.wallet = new ethers.Wallet(privateKey, provider);
    //     this.networkUrl    = "https://rpc-amoy.polygon.technology/";
    //     //this.networkUrl = process.env.REACT_APP_RPC_URl_TESTNET ;
    //     // console.log("Privatekey",privateKey ,"network",this.networkUrl)
    //     // this.provider = new HDWalletProvider(privateKey,this.networkUrl );
    //     // console.log("provider",this.provider)
    //    this.web3 = new Web3(provider);
    //     this.contractAddress = process.env.REACT_APP_NFTMANAGER_CONTRACT_ADDRESS_TESTNET; // Address of your deployed NFT contract
    //     this.nftContract = new ethers.Contract(this.contractAddress, NFTManager.abi, this.wallet);
    //    this.nftContract = new this.web3.eth.Contract(NFTManager.abi, this.contractAddress);
    }

	async  mintNFT(walletAddress, data){
        try {
			const balance = await provider.getBalance(walletAddress);
			if (Number(balance) < '0.001') {
				         throw new Error('Insufficient balance for transaction fees');
				    }
            // const gasPrice = (await provider.getFeeData()).gasPrice;
            // let tokenPrice = data?.price.toString();
			let tokenPrice = ethers.parseEther(Number(data?.price).toString());

            console.log("all data", tokenPrice, data?.projectType, data?.registry, data?.name, walletAddress, data?.hash);

            const tx = await this.nftContract.mintNFT(tokenPrice, data?.projectType, data?.registry, data?.name, walletAddress, data?.hash);
			const receipt = await tx.wait();
			const transferEvent = receipt.logs[1].args;
			console.log("fhas ",transferEvent.toString())
			
            // console.log('NFT minted successfully. Transaction Hash:', receipt);
            return {success:true ,token:transferEvent.toString(),hash:tx.hash};
        } catch (error) {
            console.error('Error minting NFT:', error);
            throw error;
        }
    }

	async  nftSaleActivate(walletAddress, data){
        try {
			const balance = await provider.getBalance(walletAddress);
			if (Number(balance) < '0.001') {
				         throw new Error('Insufficient balance for transaction fees');
				    }
            // const gasPrice = (await provider.getFeeData()).gasPrice;
            // let tokenPrice = data?.sellPrice.toString();
			let tokenPrice = ethers.parseEther(Number(data?.sellPrice).toString());

            console.log("all data", data?.tokenId, tokenPrice);

            const tx = await this.nftContract.sellNFT(data.tokenId, tokenPrice);
			 await tx.wait();
			
			// .filter(event => event.event === 'Transfer' && event.args.from === ethers.constants.AddressZero);
            // const tokenId = transferEvent.args.tokenId.toString();
			//  const token = parseInt(Number(tx.logs[1].data));
            // console.log('NFT minted successfully. Transaction Hash:', receipt);
            return {success:true ,hash:tx.hash};
        } catch (error) {
            console.error('Error Sale NFT Activation:', error);
            throw error;
        }
    }

	async  startAuctionActivate(walletAddress, data){
        try {
			const balance = await provider.getBalance(walletAddress);
			if (Number(balance) < '0.001') {
				         throw new Error('Insufficient balance for transaction fees');
				    }
            // const gasPrice = (await provider.getFeeData()).gasPrice;
            // let tokenPrice = data?.sellPrice.toString();
			let tokenPrice = ethers.parseEther(Number(data?.sellPrice).toString());

            console.log("all data", data?.tokenId, tokenPrice);

            const tx = await this.nftContract.startAuction(data.tokenId, tokenPrice,data.auctionDuration);
			 await tx.wait();
			
			// .filter(event => event.event === 'Transfer' && event.args.from === ethers.constants.AddressZero);
            // const tokenId = transferEvent.args.tokenId.toString();
			//  const token = parseInt(Number(tx.logs[1].data));
            // console.log('NFT minted successfully. Transaction Hash:', receipt);
            return {success:true ,hash:tx.hash};
        } catch (error) {
            console.error('Error Sale NFT Activation:', error);
            throw error;
        }
    }

    // mintNFT = async (walletAddress, data ) => {
    //     try {
    //         // Check balance for fees
    //         // const balance = await provider.getBalance(walletAddress);
    //         // console.log("balance ",balance)
    //        const gasPrice = (await provider.getFeeData()).gasPrice;
    //         console.log("gasPrice ",gasPrice)
    //         let tokenPrice = data?.price;
    //         // if (Number(data?.price) > 999) {
    //         //  tokenPrice = this.web3.utils.toBN(data?.price);
               
        
    //         //   } else {
    //         //     tokenPrice = this.web3.utils.toHex(this.web3.utils.toWei(data?.price, 'ether'))
        
    //         //   }
    //       console.log("all data" ,tokenPrice,data?.projectType,data?.registry,data?.name,walletAddress,data?.hash)
    //         // const estimatedGasCost = await this.nftContract.estimateGas.mintNFT(tokenPrice,data?.projectType,data?.registry,data?.name,walletAddress,data?.hash);
    //         // console.log("estimatedGasCost ",estimatedGasCost)
    //         // // Calculate total gas cost
    //         // const totalGasCost = gasPrice.mul(estimatedGasCost);
    //         // console.log("totalGasCost ",totalGasCost)
    //         // // Check if the balance is enough for gas fees
    //         // if (balance.lt(totalGasCost)) {
    //         //     throw new Error('Insufficient balance for transaction fees');
    //         // }
    //         // const gasLimit = BigNumber.from(500000); // Define gas limit as a BigNumber
           
    //         // Then you can convert it to hex using the toHexString method
    //         const hexGasLimit = this.web3.utils.toHex(500000);
    //         // Mint NFT
    //         const tx = await this.nftContract.mintNFT(tokenPrice,data?.projectType,data?.registry,data?.name,walletAddress,data?.hash, {
    //             gasPrice,
    //             gasLimit: hexGasLimit, // adjust gas limit as needed
    //         });
    //         console.log("tx ",tx)
    //         // await tx.wait();
    //         console.log('NFT minted successfully. Transaction Hash:', tx.hash);
    //         return tx.hash;
    //     } catch (error) {
    //         console.error('Error minting NFT:', error);
    //         throw error;
    //     }
    // }

    // oldmintNFT = async (walletAddress, data) => {
    //     try {
    //         const account = this.web3.eth.accounts.privateKeyToAccount(this.privateKey);
    //         const nonce = await this.web3.eth.getTransactionCount(walletAddress);
    //         const gasPrice = await this.web3.eth.getGasPrice();
    //         let tokenPrice ;
    //         if (Number(data?.price) > 999) {
    //          tokenPrice = this.web3.utils.toBN(data?.price);
               
        
    //           } else {
    //             tokenPrice = this.web3.utils.toHex(this.web3.utils.toWei(data?.price, 'ether'))
        
    //           }

    //         const rawTx = {
    //             from: walletAddress,
    //             to: this.contractAddress,
    //             nonce: this.web3.utils.toHex(nonce),
    //             gasPrice: this.web3.utils.toHex(gasPrice),
    //             gasLimit: this.web3.utils.toHex(500000), // adjust gas limit as needed
    //             value: '0x0',
    //             data: this.nftContract.methods.mintNFT(tokenPrice,data?.projectType,data?.registry,data?.name,walletAddress,data?.hash).encodeABI()
    //         };

    //         const signedTx = await account.signTransaction(rawTx);
    //         const txHash = await new Promise((resolve, reject) => {
    //             this.web3.eth.sendSignedTransaction(signedTx.rawTransaction)
    //                 .on('transactionHash', hash => resolve(hash))
    //                 .on('error', err => reject(err));
    //         });

    //         console.log('NFT minted successfully. Transaction Hash:', txHash);
    //         return txHash;
    //     } catch (error) {
    //         console.error('Error minting NFT:', error);
    //         throw error;
    //     }
    // };
}

export default Web3Intraction;


