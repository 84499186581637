import { Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const MoreDetailsSec = ({ data }) => {
  return (
    <div className="moreDetailsSec burger">
      <Row>
        <Col xs={12}>
          <div dangerouslySetInnerHTML={{ __html: data?.title }} />
        </Col>

        <Col lg={8}>
          <div className="videoSec">
            <iframe
              width="736"
              height="380"
              src={data?.banner_image[0]?.link_url}></iframe>
          </div>
        </Col>
        <Col lg={4}>
          {/* <Form className="formContent">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Full Name" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Control type="number" placeholder="Mobile No" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="email" placeholder="Email Address" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Producer/Trader/Polluter" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Remarks" />
                    </Form.Group>

                    <Form.Group className="mb-3 checkBox" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="I am not robot" />
                    </Form.Group>
            
                    <Button className="primaryBtn" variant="primary" type="submit">Contact Me</Button>
                </Form> */}
          <div className="contact-button">
            <h1>Reach out to us</h1>
            <h5>
              Let’s schedule a catch up over a coffee, beer or online to
              discover how we can help you
            </h5>
            <Link to="/contact">
              <Button className="primaryBtn" variant="primary" type="submit">
                Contact Us
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MoreDetailsSec;
