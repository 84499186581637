import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SubHeader from '../../Subheader'
import { useLocation } from 'react-router-dom';

function OfferMade() {
  const { state } = useLocation();
  const [subPagination, SetPagination] = useState()
useEffect(()=>{
  SetPagination(state)
},[state])
  return (
    <>
        <SubHeader subpagi={subPagination} />
        <Container className='bg-white' fluid>
            <Row>
                <Col lg={12}>
                    <div className='table-resposive tableBox tablewithSubHeader'>
                        <table>
                            <thead>
                                <tr>
                                    <td>Sr. No</td>
                                    <td>Quantity Require</td>
                                    <td>Start Date</td>
                                    <td>End Date</td>
                                    <td>Polluter/Buyer</td>
                                    <td>Proposed Buying Price($) </td>
                                    <td>Price as per last 30 Days($) </td>
                                    <td>Listing Status </td>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>1</td>
                                    <td>10 CTNFT</td>
                                    <td>16-jun-2021</td>
                                    <td>16-jun-2021</td>
                                    <td>Baston Trading Company</td>
                                    <td>$130,000</td>
                                    <td>$135 ,000 </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default OfferMade