import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductAction,
  addProject,
  getAuthorityData,
  uploadProjectAssets,
} from "../../../action/Api.action";
import { NAME } from "../../../constant/constant";
import { useToast } from "../../../context/Toast/useToast";
import { setDapp } from "../../../slices/home";
import { StorageHelper } from "../../../utils/StorageHelper";
import { Country, State, City }  from 'country-state-city';

const CreateCTNFT = ({ changeStep, editDapp = false }) => {
  // Latest version - v3.0.0 with Tree Shaking to reduce bundle size

  const [base64Images, setBase64Images] = useState([]);
  const [validated, setValidated] = useState(false);
  const [accuValidated, setAccuValidated] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isUpdate, setUPdate] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [country, SetCountry] =  useState()
  const [statess, setStates] = useState()
  const [city, SetCity] =  useState()
  const [assetImg, setAssetImg] =  useState([]) 
  const [imguel, setimg] = useState([])


  






  const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};













  const masterDetail = useSelector((state) => state?.home?.masterDetail);
  const userDetail = StorageHelper.getUserInfo();
  const WalletAddress = "TestAddressForWallet"; //const [WalletAddress] = [userDetail.wallet_address]
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const rx_live = /^[0-9\b]+$/;
  const project_types = [
    "Agriculture forestry, and other land use",
    "Wind",
    "Energy efficient",
    "Biomass",
    "Liquid biofuel",
    "Solar Thermal",
    "Afforestration, reforestration and revegetation",
    "Water flow restoration",
    "Waste handling and disposal",
    "Other",
  ];
  const reg_bodies = ["Verra", "Gold standard", "Climate Action Reserve", "American Carbon Registry", "Other"];

  const [kycList, setKycList] = useState([
    {
      accu: "",
      sale_price: "",
      owner_type: "producer",
      owners_public_key: WalletAddress,
      //new field addition in code
      id: localStorage.getItem("userId"),
    },
  ]);











  // const setBase64Enc = async()=>{
  //   try {
  //     let base64img;
  //   let imgArr = [];
  //   for(img in assetImg){
  //    base64img = await convertToBase64(img)
  //   console.log("Image  : " ,base64img)
  //   imgArr.push(base64img)
  //   }
  //   console.log("Image Array : " ,imgArr)

     
  //   setBase64Images( imgArr)
  //   console.log("base64 array :", base64Images)
  
  //   } catch (error) {
  //   console.log(error)    
  //   }
  // }














  const formHandler = async (e, field, index = "", check) => {
    if (e.target.value !== " " && e.target.value.toString().length < 15) {
      let data = formData;
      let err = formErrors;
      if (field === "accu") {
        let kyc = kycList;
        kyc[index].accu = e.target.value;

        setKycList(kyc);
      } else if (field === "sale_price") {
        let kyc = kycList;

        if (rx_live.test(e.target.value)) {
          kyc[index].sale_price = parseFloat(e.target.value);
          setKycList(kyc);
        }
        if (
          kyc[index].sale_price &&
          kyc[index].sale_price.toString().length == 1 &&
          e.target.value == ""
        ) {
          kyc[index].sale_price = "";
          setKycList(kyc);
        }
      } else if (field === "total_certificates_generated") {
        let num = e.target.value;
        if (rx_live.test(num)) {
          data[field] = parseFloat(num);
        }
        if (
          formData?.total_certificates_generated?.toString().length == 1 &&
          e.target.value == ""
        ) {
          data[field] = "";
        }
        let list = [];
        for (let i = 0; i < num; i++) {
          // console.log(kycList);
          list.push({
            accu: kycList[i]?.accu ? kycList[i]?.accu : "",
            owners_public_key: WalletAddress,
            sale_price: kycList[i]?.sale_price
              ? kycList[i]?.sale_price
              : formData?.initial_sales_price_per_unit
              ? formData?.initial_sales_price_per_unit
              : "",
            owner_type: "producer",
            id: kycList[i]?.id ? kycList[i]?.id : false,
          });
        }
        console.log(list);
        setKycList(list);
      } else if (index === "number") {
        if (check) {
          if (rx_live.test(e.target.value)) {
            data[field] = parseFloat(e.target.value);
            let checkKyc = kycList.map((p) => ({
              ...p,
              sale_price: e.target.value,
            }));
            setKycList(checkKyc);
          } else if (
            formData?.initial_sales_price_per_unit &&
            formData?.initial_sales_price_per_unit.toString().length == 1 &&
            e.target.value == ""
          ) {
            data[field] = "";
          }
        } else {
          data[field] = e.target.value.trim();
          const res = await getAuthorityData(e.target.value);
          console.log(res);
          if (res.status) {
            data.country_id = res.result[0].country_id;
          }
        }
      } else {
        data[field] = e.target.value.trim();
      }

      if (err[field]) {
        delete err[field];
      }
      setFormErrors(err);
      setFormData(data);
      setUPdate(!isUpdate);
    }
  };
  const fileChangedHandler = async(event, elename, index) => {
    console.log(event, elename, index);
    event.preventDefault();
    let formErrorsData = formErrors;

    let kycData = kycList;
    let file = event.target.files[0];
    if (!file && file === undefined) {
      //formDataData['preview'] = '';
      //formDataData[elename] = '';
      //setFormData({...formData,...formDataData});
      return false;
    }
    var fileName = file && file.name ? file.name : "";
    let extensions = fileName
      .substring(fileName.lastIndexOf(".") + 1)
      .toLowerCase();

    if (file.size > 31457280) {
      //formErrorsData[elename] = "File size not greater then 30MB.";
    } else if (
      extensions == "jpg" ||
      extensions == "png" ||
      extensions == "jpeg"
    ) {
      // kycData[index].image = event.target.files[0];
     
      // if(kycData.image){
      //   // const image = {
          
      //   // }
      //   kycData.image = {...kycData.image} + event.target.files
      // } else {
       
      // }
      // console.log("event.target.files", ...event.target.files)
      let data = [...assetImg,...event.target.files ]
      const base64img = await convertToBase64(event.target.files[event.target.files.length-1])
      console.log(base64img)
      let b64img = base64Images
      b64img.push(base64img)
      console.log("Array for base 64 img : ", b64img)
      setBase64Images(b64img)
      setAssetImg(data )
      console.log("image", assetImg)
      console.log("base64 array :", base64Images)
    
      
      // kycData.image = event.target.files
    } else {
      // formErrorsData[elename] = "File extensions doesn't match.";
    }
    //setFormErrors({...formErrors,...formErrorsData});

    setKycList(kycData);
  };
useEffect(()=>{
  var binaryData = [];
  // for(let i = 0; i < assetImg.length; i++){
 
  //   const imggg = window.URL.createObjectURL(new Blob(assetImg[i], {type: "application/zip"}))
  //   console.log('dd',imggg);
  // //   let data = []
  // //   data.push(imggg)
  // //   console.log('assetImg', data);
  // //   setimg(data)
  // }

  const imagesArray = assetImg.map((file) =>
      window.URL.createObjectURL(file)
    );
    console.log(imagesArray);
    setimg(imagesArray);

  console.log('assetImg', assetImg, );
},[kycList, assetImg])

useEffect(()=>{
  console.log(imguel);
},[imguel])

const removeAssets = (index) =>{
 const images =  assetImg.filter((img, i) => i !== index)
 const b64 = base64Images.filter((img,i) => i != index)
 setBase64Images(b64)
 console.log("Altered " ,base64Images)
 setAssetImg(images)
}


  const formValidation = () => {
    let check = true;
    kycList.forEach((data) => {
      if (!data.accu || !data.sale_price) {
        check = false;
      }
    });
    let valuesAlreadySeen = [];
    for (let i = 0; i < kycList.length; i++) {
      let value = kycList[i].accu;
      if (valuesAlreadySeen.indexOf(value) !== -1) {
        setAccuValidated(true);
        addToast({
          message: "ACCU Document cannot be same",
          type: "error",
        });
        check = false;
        return check;
      }
      setAccuValidated(false);
      valuesAlreadySeen.push(value);
    }
    return check;
  };












 
  const handleSubmit = async (event) => {
  //  console.log("images",assetImg)
    // await setBase64Enc();
 

    console.log("User ID is  : ", localStorage.getItem("userId"));
    // console.log("User Details Object");
    // console.log(userDetail);
   
       //project assets upload


      //  console.log(imagesArray)
      //  const imgData = []
      //  try {
      //    const base64Promises = imagesArray.map((image) => convertToBase64(image));
      //    const base64Results = await Promise.all(base64Promises);
      //    setBase64Images(base64Results);
      //  } catch (error) {
      //    console.error('Error converting images to Base64:', error);
      //  }
      //  console.log(base64Images)


       //project assets upload ends.


    const form = event.currentTarget;
    const validation = formValidation();
    if (form.checkValidity() === false || !validation) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      event.stopPropagation();
      // setDisabled(true);
      let postData;
      if (editDapp) {
        postData = {
          ...formData,
          certificates_ids: kycList,
          currency_id: "21",
          dapp_id: editDapp.dapp_id,
          
        };
      } else {
        postData = {
          ...formData,
          certificates_ids: kycList,
          currency_id: "21",
          ccredits : document.getElementById('ccredits').value,
          standard : document.getElementById('standard').value,
          verifier : document.getElementById('verifier').value 
        };
      }

      console.log("Post Data is :");
      console.log(postData);
       let response = await addProductAction(postData);
       // console.log(response)
       //   alert(response);
       if (response.status) {
         console.log(response);
         console.log(localStorage.getItem("userId"));
         dispatch(setDapp(response?.dapp_id));
         let projectData = {
           user_id: localStorage.getItem("userId"),
           certificate_id: response?.dapp_id,
           project_title: document.getElementById("proj_title").value,
           project_description: document.getElementById("proj_desc").value,
           project_type: document.getElementById("proj_type").value,
           project_reg_body: document.getElementById("proj_reg_body").value,
           project_address: document.getElementById("proj_address").value,
           project_apt: document.getElementById("proj_apt").value,
           project_city: document.getElementById("proj_city").value,
           project_state: document.getElementById("proj_state").value,
           project_postal_code:
             document.getElementById("proj_postal_code").value,
           project_country: document.getElementById("proj_country").value,
         };
         console.log(projectData);
         let projectRegister = await addProject(projectData);
         if (projectRegister.status) {
           addToast({
             message: projectRegister?.message,
             type: "success",
             duration: 3000,
           });
           
            console.log(projectRegister)
            console.log("IDs : ",{ 
              "Certificate_id" : response?.dapp_id,
              "project_id" : projectRegister?.project_id
             })

            console.log("Base 64 Data",base64Images)
            let projAssetData = {
              "certificate_id" : response?.dapp_id,
              "project_id" : projectRegister?.project_id,
              "imageData" : base64Images
            }


            const projAssetUpload = await uploadProjectAssets(projAssetData)
            console.log(projAssetUpload)
            


            // let assetStore = await addProjectAssets({
              
            //   imgData : base64Images,
            //   certificate_id: response?.dapp_id
            
            
            // });
            // if(assetStore.status){
            //  addToast({
            //    message : "Assets stored successfully",
            //    type : "success",
            //    duration : 3000
            //  })
            // }
           
         }

         addToast({
           message: response?.message,
           type: "success",
           duration: 3000,
         });
         changeStep(1);
       } else {
         setDisabled(false);
         console.log("API Test Failed");
         addToast({
           message: response?.message,
           type: "error",
           duration: 3000,
         });
       }
    }
  };
  useEffect(() => {
    if (editDapp) {
      setFormData({
        erf_registration_number: editDapp?.erf_registration_number,
        initial_sales_price_per_unit: editDapp?.initial_sales_price_per_unit,
        authority_id: editDapp?.authority_id,
        country_id: editDapp?.country_id,
        total_certificates_generated: editDapp?.total_certificates_generated,
      });
      console.log("Wallet address : ", WalletAddress);
      setKycList(
        editDapp?.certificates_ids?.map((info) => {
          return {
            accu: info.accu,
            owners_public_key: WalletAddress,
            sale_price: info.sale_price,
            owner_type: "producer",
            id: info?.id,
          };
        })
      );
    }
  }, [editDapp]);

  useEffect(() =>{
    SetCountry(Country.getAllCountries())
    
  }, [Country])

  const counrtySelect = async (e) =>{
    const coutryCode =  e.target.value
    const allCountry = Country.getAllCountries()
    const allState = State.getAllStates()
    const country =  allCountry.filter(country => country.isoCode === coutryCode)
    setStates(allState.filter(state => state.countryCode === coutryCode))

   
 
    
  }
  useEffect(()=>{
    console.log('deepak', statess);
  },[statess])
  const selectState = (e) =>{
    const stateCode =  e.target.value
    const allState = State.getAllStates()
    const allCities = City.getAllCities()
    const state =  allState.filter((state)=>state.isoCode === stateCode)
    const Cities =  allCities.filter(cities =>cities.stateCode === stateCode)
    SetCity(Cities)
    console.log('deepak',state,Cities );

  }

  return (
    <div>
      <div className="quoteRequestSec CreateCTNFTSec burger">
        <Container>
          <Row className="justify-content-md-center">
            <Col sm={12}>
              <Form
                className="moreInfoSec formContent boxShaddow"
                noValidate
                validated={validated}
                onSubmit={(e) => handleSubmit(e)}
                
              >
                {/* New Code Here */}
                <Row>
                  <Col lg={12}>
                    <h4 className="heading ">
                      {/* {window.location.pathname !== "/createCNFT"
                        ? `Edit Project Details`
                        : `Add Project Details`} */}

                        Project Details
                    </h4>
                  </Col>
                  <Col lg={12}>
                    <Form.Group controlId="projectDetails">
                      <Row>
                        <Col md={3} className="mb-3" >
                          <Form.Control
                            type="text"
                            id="proj_title"
                            placeholder="Project Title"
                            name="project_title"
                            // onChange={(e) =>
                            //   formHandler(e, "erf_registration_number")
                            // }
                            // pattern="/^\S*$/"
                         
                            maxLength="64"
                            autoComplete="off"
                            required
                          />
                        </Col>
                        

                        <Col md={3} className="mb-3">
                          <Form.Group controlId="formBasicSelect">
                            <Form.Control
                              as="select"
                              className="form-select"
                              id="proj_type"
                              name="project_type"
                              placeholder = "Please select project type"
                            required

                            >
                              <option value="" disabled selected>
                                Please select Project type
                              </option>
                              {project_types.map((type, i) => {
                                return <option value={type} key={i}> {type} </option>;
                              })}
                            </Form.Control>
                          </Form.Group>
                        </Col>
                        <Col md={3} className="mb-3">
                          <Form.Control
                            as="select"
                            className="form-select"
                            name="project_reg_body"
                            id="proj_reg_body"
                            required

                          >
                            <option value="" disabled selected >
                              Please select Registering body
                            </option>

                            {reg_bodies.map((type) => {
                              return <option value={type}> {type} </option>;
                            })}
                          </Form.Control>
                        </Col>

                        <Col md={12} >
                          <Form.Control
                            type="text"
                            id="proj_desc"
                            placeholder="Project Description"
                            name="project_desc"
                            className="mb-4"
                            // onChange={(e) =>
                            //   formHandler(e, "erf_registration_number")
                            // }
                            // pattern="/^\S*$/"
                            
                            autoComplete="off"
                            required

                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <h4 className="heading mt-4 ">
                      {/* {window.location.pathname !== "/createCNFT"
                        ? `Edit Project Address`
                        : `Add Project Address`}{" "} */}
                        Project Address
                    </h4>
                  </Col>
                  <Col lg={12}>
                    <Row>
                      <Col className="mb-3" md={3}>
                        <Form.Control
                          type="text"
                          placeholder="Address"
                          id="proj_address"
                          name="project_address"
                          // onChange={(e) =>
                          //   counrtySelect(e, "project_address")
                          // }
                          // pattern="/^\S*$/"
                          
                          maxLength="64"
                          autoComplete="off"
                          required

                        />
                      </Col>
                      <Col className="mb-3" md={3}>
                        <Form.Control
                          type="text"
                          placeholder="Apartment/Suite"
                          id="proj_apt"
                          name="project_apt"
                          // onChange={(e) =>
                          //   formHandler(e, "erf_registration_number")
                          // }
                          // pattern="/^\S*$/"
                          
                          maxLength="100"
                          autoComplete="off"
                          required

                        />
                      </Col>
                      <Col className="mb-3" md={3}>
                        <Form.Control
                          as="select" className="form-select"
                          placeholder="Country"
                          id="proj_country"
                          name="project_country"
                          // onChange={(e) =>
                          //   formHandler(e, "erf_registration_number")
                          // }
                          // pattern="/^\S*$/"
                          onChange={(e) =>
                            counrtySelect(e)
                          }
                          // value={'dee'}
                          minLength="4" 
                          maxLength="100"
                          autoComplete="off"
                          required

                        >
                          <option value="">Select Country</option>
                          {country?.map((item) => {
                            return(
                              <>
                              <option value={item.isoCode}>{item.name}</option>
                              </>
                            )
                          })}
                        </Form.Control>
                      </Col>
                      <Col className="mb-3" md={3}>
                        <Form.Control
                          as="select" className="form-select"
                          placeholder="state/province"
                          id="proj_state"
                          name="project_state"
                          onChange={(e) =>
                            selectState(e)
                          }
                          // pattern="/^\S*$/"
                          minLength="10"
                          maxLength="100"
                          autoComplete="off"
                          required

                        >
                          <option value="">Select State/Province</option>
                          {statess?.map((state) =>{
                            return(
                              <>
                          <option value={state.isoCode}>{state.name}</option>

                              </>
                            )
                          })}
                        </Form.Control>
                      </Col>
                      <Col className="mb-3" md={3}>
                        <Form.Control
                          as="select" className="form-select"
                          placeholder="City"
                          id="proj_city"
                          name="project_city"
                          // onChange={(e) =>
                          //   formHandler(e, "erf_registration_number")
                          // }
                          // pattern="/^\S*$/"
                          minLength="10"
                          maxLength="100"
                          autoComplete="off"
                          required

                        >
                          <option value="">Select City</option>
                          {city?.map(city=>{
                            return(
                         
                              <>
                              <option value={city.name}>{city.name}</option>
                              </>
                            )
                          })}
                        </Form.Control>
                      </Col>

                    

                      <Col className="mb-3" md={3}>
                        <Form.Control
                          type="text"
                          placeholder="Postal Code"
                          name="project_postal_code"
                          id="proj_postal_code"
                          // onChange={(e) =>
                          //   formHandler(e, "erf_registration_number")
                          // }
                          // pattern="/^\S*$/"
                          minLength="2"
                          maxLength="10"
                          autoComplete="off"
                          required

                        />
                      </Col>
                  
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <h4 className="heading ">
                      {window.location.pathname !== "/createCNFT"
                        ? `Upload Project Assets`
                        : `Project Assets`}{" "}
                    </h4>
                  </Col>
                  <Col lg={12}>
                    <Row>
                    {imguel.length > 0 && (
                      <Col  lg={12}>
                        <div className="uploadedimageBox">
                       
                        <Row>
                        {imguel.map((image, index) => {
                          return(
                            <>
                            <Col  lg={2}>
                          <div className="selectedImg">
                            <div className="ImageBox">
                              <img src={image} alt="" />
                              <div className="ovrelay">
                                <button onClick={() =>{removeAssets(index)}} className="closeBtnimg" type="button">
                                  <img src="/images/svg/CloseWindow.svg" alt="" />
                                </button>
                              </div>
                            </div>
                            {/* <h3>Banner239303bdcfgg.jpeg</h3> */}
                          </div>
                        </Col>
                            </>
                          )
                        })}
                       
                    
                        </Row>
                        </div>
                     
                      </Col>
                    )}
                  
                      <Col lg={12}>
                      <div className="selectImage">
                          <input className="d-none" type="file"  accept=".jpg, .jpeg, .png" multiple 
                          onChange={async(e,b,c)=>{
                            fileChangedHandler(e,b,c);
                            // console.log(e.target.value)
                            
                            // let res =  await base64Convert(assetImg)
                            }} id="uploadassets"></input>
                          <label className="uploadassets" htmlFor="uploadassets">
                            <img src={"/images/svg/UploadImage.svg"} alt="upload" />
                            <h3>Upload from computer</h3>
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* New Code Ends */}

                <Row>
                  <h4 className="heading mt-4">
                    {window.location.pathname !== "/createCNFT"
                      ? `Edit ${NAME.certificate}`
                      : `Create ${NAME.certificate}`}{" "}
                  </h4>
                  <Col className="mb-3" md={3}>
                    <div className="documentType mb-2">
                      {/* <Form className="formContent erfReg"> */}
                      <Form.Group controlId="formBasicQtyRequir">
                        <Form.Control
                          type="text"
                          placeholder="Registration Number"
                          value={formData.erf_registration_number}
                          name="erf_registration_number"
                          disabled={editDapp.dapp_id ? true : false}
                          onChange={(e) =>
                            formHandler(e, "erf_registration_number")
                          }
                          // pattern="/^\S*$/"
                          minLength="2"
                          maxLength="64"
                          autoComplete="off"
                          required
                        />
                      </Form.Group>
                      {/* </Form> */}
                    </div>
                  </Col>
                  <Col className="mb-3" md={4}>
                    <div className="documentType mb-2">
                      <Form className="formContent erfReg">
                      <Form.Group controlId="formBasicQtyRequir">
                        <Form.Control
                          type="number"
                          placeholder="Initial Sale Price"
                          defaultValue={formData.initial_sales_price_per_unit}
                          name="initial_sales_price_per_unit"
                          value={
                            formData.initial_sales_price_per_unit
                              ? formData.initial_sales_price_per_unit
                              : ""
                          }
                           onChange={(e) =>
                             formHandler(
                               e,
                               "initial_sales_price_per_unit",
                               "number",
                               true
                             )
                           }
                          minLength="1"
                          maxLength="64"
                          autoComplete="off"
                          required
                          hidden
                        />
                      </Form.Group>
                      </Form>
                    </div>
                  </Col> 
                  {/* <Col >
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-top`}>
                          On purchase of your {NAME.certificate}, 1% Platform
                          fee will be deducted.
                        </Tooltip>
                      }
                    >
                      <i className={`fas fa-info-circle tooltip-info`} />
                    </OverlayTrigger>
                  </Col> */}
                  <Col className="mb-3" md={6}>
                  <Form.Control
                   className="documentType mb-2"
                          type="text" 
                          placeholder="Credits"
                          id="ccredits"
                          name="ccredits"
                          // onChange={(e) =>
                          //   formHandler(e, "erf_registration_number")
                          // }
                          // pattern="/^\S*$/"
                        
                          maxLength="100"
                          autoComplete="off"
                          required

                        />
                    <div className="documentType mb-2">
                      {/* <Form className="formContent erfReg"> */}
                      <Form.Group controlId="formBasicSelect">
                        
                        <Form.Control
                          required className="form-select"
                          as="select"
                          name="authority_id"
                          value={formData.authority_id}
                          onChange={(e) =>
                            formHandler(e, "authority_id", "number", false)
                          }
                        >
                          
                         
                          <option value="">Please Select Authority</option>
                          {masterDetail &&
                            masterDetail?.authority_ids.map((data, index) => {
                              return (
                                <option value={data?.id} key={index}>
                                  {data?.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      
                      </Form.Group>
                      
                      {/* </Form> */}
                    </div>
                  </Col>
                  <Col className="mb-3" md={3}>
                        <Form.Control
                          as="select" className="form-select"
                          placeholder="Verifier"
                          id="verifier"
                          name="verifier"
                          // onChange={(e) =>
                          //   formHandler(e, "erf_registration_number")
                          // }
                          // pattern="/^\S*$/"
                          minLength="10" 
                          maxLength="100"
                          autoComplete="off"
                          required

                        >
                            <option value="" disabled selected>Select Verifier</option>
                            <option value="CRI : Carbon Registry - India">CRI : Carbon Registry - India</option>
                            <option value="California Air Resources Board (CARB)">California Air Resources Board (CARB)</option>
                            <option value="Environmental Protection Agency (EPA)">Environmental Protection Agency (EPA)</option>
                            <option value="Clean Energy Regulator (CER)">Clean Energy Regulator (CER) </option>
                        </Form.Control>
                      </Col>
                      <Col className="mb-3" md={3}>
                        <Form.Control
                          type="text" 
                          placeholder="Standard"
                          id="standard"
                          name="standard"
                          // onChange={(e) =>
                          //   formHandler(e, "erf_registration_number")
                          // }
                          // pattern="/^\S*$/"
                        
                          maxLength="100"
                          autoComplete="off"
                          required

                        />
                        
                         
                      </Col>
                  <Col className="mb-3" md={3}>
                    <div className="documentType mb-2">
                      {/* <Form className="formContent erfReg"> */}
                      <Form.Group controlId="formBasicSelect">
                        <Form.Control
                          required
                          disabled
                          as="select"
                          name="country_id"
                          value={formData.country_id}
                          onChange={(e) =>
                            formHandler(e, "country_id", "number", false)
                          }
                          

                        >
                          <option value="" disabled selected>Please Select Country</option>
                          {masterDetail &&
                            masterDetail?.country_ids.map((data, index) => {
                              return (
                                <option value={data.country_id} key={index}>
                                  {data?.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Form.Group>
                      {/* </Form> */}
                    </div>
                  </Col>
                  <Col className="mb-3" md={3}>
                    <div className="documentType mb-2">
                      {/* <Form className="formContent erfReg"> */}
                      <Form.Group controlId="formBasicSelect">
                        <Form.Control
                          as="select"
                          // required
                          disabled
                          name="currency_id"
                          onChange={(e) =>
                            formHandler(e, "currency_id", "number", false)
                          }
                        >
                          {/* <option value="">Please Select Currency</option> */}
                          {masterDetail &&
                            masterDetail?.currency_ids.map((data, index) => {
                              return (
                                <option value={data.currency_id} key={index}>
                                  {data?.name}
                                </option>
                              );
                            })}
                        </Form.Control>
                      </Form.Group>
                      {/* </Form> */}
                    </div>
                  </Col>
                </Row>
                
                <hr className="w-100" />
                <Row>
                  <h4 className="heading mt-4">Upload Certificate</h4>
                  <Col xs={12}>
                    <Row>
                      <Col className="mb-3" lg={12}>
                        <Form.Group
                          
                          controlId="formBasicQtyRequir"
                        >
                          <Form.Control
                            type="number"
                            placeholder="No of Certificates to be minted"
                            // defaultValue={formData.total_certificates_generated}
                            value= "1"
                            name="total_certificates_generated"
                            onChange={(e) =>
                              formHandler(
                                e,
                                "total_certificates_generated",
                                "number",
                                true
                              )
                            }
                            min="1"
                            autoComplete="off"
                            required
                            hidden
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {kycList.length > 0 &&
                      kycList.map((item, index) => {
                        return (
                          <div className="documentType certificatessss" key={index}>
                            <Row>
                              <Col md={6} xs={3}>
                                <Form className="formContent">
                                  <Form.Group
                                    className="selectBox"
                                    controlId="formBasicCompanyType"
                                  >
                                    <Form.Control
                                      class={
                                        accuValidated
                                          ? "form-control is-invalid"
                                          : "form-control"
                                      }
                                      type="text"
                                      placeholder="Enter Asset ID"
                                      // defaultValue={item.accu}
                                      value={item.accu}
                                      name="accu"
                                      onChange={(e) =>
                                        formHandler(e, "accu", index)
                                      }
                                      required
                                      autoComplete="off"
                                    />
                                  </Form.Group>
                                </Form>
                              </Col>
                              <Col md={6} xs={3}>
                                <Form className="formContent">
                                  <Form.Group
                                    className="selectBox"
                                    controlId="formBasicCompanyType"
                                  >
                                    <Form.Control
                                      type="number"
                                      placeholder="Enter Sale Price"
                                      value={item.sale_price}
                                      // defaultValue={item.sale_price}
                                      name="sale_price"
                                      onChange={(e) =>{
                                        formHandler(e, "sale_price", index)
                                        console.log(e.target.value)
                                        let newData = formData
                                        newData.initial_sales_price_per_unit = e.target.value
                                        console.log(newData.initial_sales_price_per_unit)
                                        
                                      }
                                      }
                                      required
                                      autoComplete="off"
                                    />
                                  </Form.Group>
                                </Form>
                              </Col>
                              {/* <Col md={4}>
                                <Form.Group
                                  controlId="formFile"
                                  className="selectFileOption mt-2">
                                  <Form.Label>Upload Document</Form.Label>
                                  <span>
                                    <img src="assets/img/mountain.png" />
                                  </span>
                                  <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) =>
                                      formHandler(e, "image", index)
                                    }
                                    required
                                  />
                                </Form.Group>
                              </Col> */}
                            </Row>
                          </div>
                        );
                      })}
                  </Col>
                
                  <Col className="ms-auto" xs={12} lg={2}>
                    <div className="moreInfoBtns">
                      <Button
                        className="secondaryBtn submitBtn"
                        variant=""
                        type="submit"
                        disabled={isDisabled}
                      >
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>
               
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CreateCTNFT;
