import React, { useEffect, useState } from 'react'
import WalletMenu from '../WalletMenu'
import { ButtonGroup, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import { getUserBalance } from '../../../action/walletApiAction';


function Index(props) {
const [walletBalance, setWalletBalance]= useState(0);
const [curr, setCurr] = useState({value:'0.0000 USDC ', arrow:<i class="fa-solid fa-angle-down"></i>})
const {page} = props
 
const getWalletBalance = async()=>{
  let body={}
  body.user_id= localStorage.getItem("userId")
  const balanceRes= await getUserBalance(body)
  setWalletBalance(balanceRes.data.balance)
  console.log("balanceRes", balanceRes.data.balance)
}
useEffect(()=>{
  
  getWalletBalance()
  const togglebtn = document.getElementById('dropdown-button-drop-end')
    togglebtn.classList.remove('btn-primary')
}, [])
  return (
    <>
      <div className='walletMaineBox'>    
        <WalletMenu pagename={page} />
        <div className='walletRanderBox'>
          <Row>
            <Col lg={4}>
              <div className='TotalBalanaceBox'>
                <h3>Total balance <i class="fa-solid fa-eye"></i></h3>
                <div className="dropdownwallet dashboarddrop">
                      
                      <DropdownButton
                        as={ButtonGroup} className="btn"
                        key={'end'}
                        id={`dropdown-button-drop-end`}
                        drop={'end'}
                        varient={'link'}
                        title={'000 USDC'}
                      >
                         <Dropdown.Item eventKey="1">Matic</Dropdown.Item>
                         <Dropdown.Item eventKey="1">USDC</Dropdown.Item>

                      </DropdownButton>
                      
                      
                      </div>
                {/* <h2>{Number(walletBalance).toFixed(5)} USDC</h2> */}
                <div className='btnBoxBalance'>
                  <div className='innerBox'>
                    <div className='imageBox'>
                      <img src="/images/svg/withdrawalIcon.svg" width={53} height={53} alt='' />
                    </div>
                    <h4>Withdraw</h4>
                  </div>
                  <div className='innerBox'>
                    <div className='imageBox'>
                      <img src="/images/svg/depositIcon.svg" width={53} height={53} alt='' />
                    </div>
                    <h4>Deposit</h4>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className='contentBoxWallet'>
                <h2>NFT’s</h2>
                <div className='table-responsive walletTable '>
                  <table>
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Balance</td>
                        <td>Binary Address</td>
                        <td>Public Key</td>
                        <td>Address</td>

                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>PHAE</td>
                        <td>200.5584785 PHAE</td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div> </td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td>

                      </tr>
                      <tr>
                        <td>PHAE</td>
                        <td>200.5584785 PHAE</td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div> </td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td>

                      </tr>
                      <tr>
                        <td>PHAE</td>
                        <td>200.5584785 PHAE</td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div> </td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td>

                      </tr>
                      <tr>
                        <td>PHAE</td>
                        <td>200.5584785 PHAE</td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div> </td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td>

                      </tr>
                      <tr>
                        <td>PHAE</td>
                        <td>200.5584785 PHAE</td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div> </td>
                        <td><div className='addresswa'>23fhgfdtywdbs... <button className='btn'><i class="fa-regular fa-copy"></i></button> </div></td>

                      </tr>
                    </tbody>
                  </table>
                </div>
              
              </div>
            </Col>
            <Col className='text-end' lg={12}>
              <Pagination className='justify-content-end mt-3'>
                <Pagination.First />
                <Pagination.Prev />
                <Pagination.Item>{1}</Pagination.Item>

                <Pagination.Item>{10}</Pagination.Item>
                <Pagination.Item>{11}</Pagination.Item>
                <Pagination.Item active>{12}</Pagination.Item>
                <Pagination.Item>{13}</Pagination.Item>
                <Pagination.Item disabled>{14}</Pagination.Item>

                <Pagination.Item>{20}</Pagination.Item>
                <Pagination.Next />
                <Pagination.Last />
              </Pagination>
            </Col>
          </Row>
        </div>
    </div>
    </>
  )
}

export default Index