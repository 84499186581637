import React, { useState, useEffect, useRef } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import style from "./style.module.scss";
import { capitalizeFirstLetter, useContainerDimensions } from "../Constant";

const BarCharts = ({ data, color }) => {
  const [barData, setBarData] = useState();

  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);

  useEffect(() => {
    if (data) {
      const arr = [];
      Object.entries(data[1]).map((info) => {
        arr.push({ name: capitalizeFirstLetter(info[0]), value: info[1] });
      });
      setBarData(arr);
    }
  }, []);
  // console.log("width", color);
  return (
    <>
      <div className={` ${style.customCard}`}>
        <div className="card">
          <div className={`card-body  ${style.barChart} `} ref={componentRef}>
            <h3>{data && data[0]}</h3>
            <BarChart
              width={width - 20}
              height={250}
              data={barData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              {/* <Legend /> */}
              <Bar dataKey="value">
                {color.map((single, index) => {
                  return <Cell key={`cell-${index}`} fill={single} />;
                })}
              </Bar>
            </BarChart>
          </div>
        </div>
      </div>
    </>
  );
};
export default BarCharts;
