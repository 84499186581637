import styles from "./blog.module.scss";
import BASE_URL from "../../utils/appUrls";

const ShortReads = ({ data }) => {
  return (
    <div className={styles.ShortReadsWrapper}>
      <div className={styles.ShortReadsTitle}>
        <div dangerouslySetInnerHTML={{ __html: data?.title }} />
      </div>
      <div className={styles.ShortReadsRowWrapper}>
        {data?.image_by_head &&
            data?.image_by_head?.other.length > 0 &&
            data?.image_by_head?.other.map((data, index) => (
          <>
            <div className={styles.ShortReadsCard} key={index}>
              <img
                src={BASE_URL.HOST + data?.image_url}
                className={styles.ShortReadsCardImage}
              />
              <div className={styles.ShortReadsCardContent}>
                <div className={styles.ShortReadsCardContentTitle}>
                  <div dangerouslySetInnerHTML={{ __html: data?.heading }} />
                </div>
                <div className={styles.ShortReadsCardContentSubtitle}>
                  <div dangerouslySetInnerHTML={{ __html: data?.text }} />
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
};

export default ShortReads;
