import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { getFooterData } from "../action/Api.action";
import BASE_URL from "../utils/appUrls";
import { Link } from "react-router-dom";
const MiniFooter = () => {
  const [column2, setColumn2] = useState([]);
  const [column3, setColumn3] = useState([]);
  const [footerLogo, setFooterLogo] = useState("");
  const [copy_right, setCopyright] = useState("");
  const [socialMedia, setSocialMedia] = useState("");

  const fetchFooterData = async () => {
    const result = await getFooterData();
    if (result && result.success_msg === "Successfully") {
      setColumn2(result.column2);
      setColumn3(result.column3);
      setCopyright(result.copyright);
      setFooterLogo(result.footer_logo);
      setSocialMedia(result.social_media_dict);
    }
  };
  useEffect(() => {
    fetchFooterData();
  }, []);
  let footer_logo;
  if (footerLogo) {
    footer_logo = BASE_URL.HOST + footerLogo;
  } else {
    footer_logo = "../assets/img/carbon_exchnage.png";
  }

  return (
    <div className="footerSec ">
      <div className="container">
        <div className="footer-section">
          <div className="copyrightText border-top">
            {copy_right ? (
              <>{copy_right}</>
            ) : (
              <>
                © Carbon Credit Exchange {new Date().getFullYear()}. All rights
                reserved.
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniFooter;
