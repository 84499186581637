import React from "react";
import styles from "./input.module.scss";

const CustomInput = ({
  label,
  important,
  name,
  onChange,
  value,
  error,
  readonly,
  type = "text",
}) => {
  return (
    <div className={styles.InputContainer}>
      <div className={styles.CustomInputWrapper}>
        {/* <label className={styles.CustomInputLabel}>
          {label}
          {important && <b className={styles.CustomInputStar}>*</b>}
        </label> */}
        <input
          type={type}
          className="form-control mb-3"
          placeholder={` ${label}`}
          value={value}
          name={name}
          onChange={onChange}
          readOnly={readonly}
          autoComplete="off"
        />
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default CustomInput;
