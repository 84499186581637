import React from "react";
import NoRecordsFound from "../../component/NoRecordFound";
import { NAME } from "../../constant/constant";
import styles from "./style.module.scss";
const TableData = ({ data, tableHead }) => {
  return (
    <>
      <div className={styles.tableData}>
        <div className={`card  ${styles.card}`}>
          <div className={`card-body ${styles.cardBody}`}>
            <h4 className="my-4">{tableHead}</h4>
            <div className={styles.orderSection}>
              <div className={`${styles.list}`}>
                <div className={`${styles.listItems}`}>
                  <div className={styles.orderListBox}>
                    <div>
                      <div className={`${styles.listBoxBody}`}>
                        <div className={`${styles.listAlignment}`}>
                          <div>
                            <p>Id #</p>
                          </div>
                          <div>
                            <p>ERF Reg No</p>
                          </div>
                          <div>
                            <p>ACCU</p>
                          </div>
                          <div>
                            <p>Authority</p>
                          </div>
                          <div>
                            <p>{NAME.supplier} Name</p>
                          </div>
                          <div>
                            <p>Price</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.orderSection}>
              <div className="container-wrapper">
                <div className={`${styles.list}`}>
                  {data && data.length > 0 ? (
                    data?.slice(0, 5).map((res, i) => {
                      return (
                        <div className={`${styles.listItems}`} key={i}>
                          <div className={styles.orderListBox}>
                            <div>
                              <div className={`${styles.listBoxBody}`}>
                                <div className={`${styles.listAlignment}`}>
                                  <div>
                                    <span>{res?.id}</span>
                                  </div>
                                  <div>
                                    <span>{res?.erf_reg_no}</span>
                                  </div>
                                  <div>
                                    <span>{res?.accu}</span>
                                  </div>
                                  <div>
                                    <span>{res?.authority_name}</span>
                                  </div>
                                  <div>
                                    <span>{res?.producer_name}</span>
                                  </div>
                                  <div>
                                    <span>{res?.price}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={"d-grid"}>
                              <div className={`${styles.listBoxFooter}`}>
                                <div className={styles.ExplorerButton}>
                                  <a
                                    className="button-yellow2"
                                    href={res.explorer_link}
                                    target="_blank"
                                    rel="noreferrer">
                                    View in Explorer
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <NoRecordsFound />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TableData;
