import React from "react";
import { capitalizeFirstLetter } from "../Constant";
import styles from "./style.module.scss";
const Toptital = ({ data, index }) => {
  return (
    <>
      <div className={`${styles.topTitle} ${styles.customCard}`}>
        <div className="card">
          <div className="card-body">
            <h4 className={`card-title randomColor_${index}`}>
              {data && data[1]}
            </h4>
            <p className="card-text">
              {data && capitalizeFirstLetter(data[0])}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Toptital;
