import React, { useCallback, useState, useEffect, useRef } from "react";
import { PieChart, Pie, Sector, Cell, Legend } from "recharts";
import { NAME } from "../../constant/constant";
import { capitalizeFirstLetter, useContainerDimensions } from "../Constant";
import style from "./style.module.scss";

const DonutChart = ({ data }) => {
  const [chartData, setChartData] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);

  useEffect(() => {
    if (data) {
      const arr = [];
      Object.entries(data).map((info) => {
        if (info[1] !== 0) {
          arr.push({ name: info[0], value: info[1] });
        }
      });
      setChartData(arr);
    }
  }, []);
  const COLORS = ["#00C49F", "#0088FE", "#FFBB28"];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 2.3;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <>
        <text
          x={x}
          y={y}
          fill="black"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central">
          {`(${(percent * 100).toFixed(1)}%)`}
          <br />
        </text>
        <text
          x={x}
          y={y + 20}
          fill="black"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central">
          {capitalizeFirstLetter(name)}

          <br />
        </text>
      </>
    );
  };
  const renderLegendText = (value) => {
    return <span>{capitalizeFirstLetter(value)}</span>;
  };
  return (
    <>
      <div className={style.customCard}>
        <div className={`card text-left ${style.donutChart}`}>
          <div className="card-body" ref={componentRef}>
            <h3>{NAME.certificate} Transactions</h3>
            {chartData && chartData.length > 0 ? (
              <PieChart width={width - 20} height={300}>
                <Legend iconType="plainline" formatter={renderLegendText} />
                <Pie
                  activeIndex={activeIndex}
                  // activeShape={renderActiveShape}
                  data={chartData}
                  cx={180}
                  cy={120}
                  innerRadius={60}
                  outerRadius={70}
                  // fill="#8884d8"
                  dataKey="value"
                  // onMouseEnter={onPieEnter}
                  label={renderCustomizedLabel}>
                  {/* <Legend /> */}
                  {chartData?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            ) : (
              <p>
                <h4>No Transaction Found</h4>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default DonutChart;
