import React, { useEffect, useState } from 'react'
import { Nav, NavLink } from 'react-bootstrap'
import { Link, useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useToast } from "../../context/Toast/useToast";
function WalletMenu(props) {
  const { addToast } = useToast();
  const [walletAddress, setWalletAddress] = useState('')
  const [pageName, setPageName] = useState()
  const activeFun = (e) => {
    setPageName(e)
  }
  useEffect(() => {
    setWalletAddress(localStorage.getItem("wallet_address"))
    setPageName(props.pagename)
  }, [props])

  const handleCopyToClipboard = () => {
    console.log("warrrere", walletAddress)
    // navigator.clipboard.writeText(navigator.clipboard.writeText(walletAddress))
    navigator.clipboard.writeText(walletAddress)
    addToast({
      message: "Wallet Address Copied Successfully",
      type: "success",
    });
  }
  return (
    <>
      <div className='walletMenu'>
        <div className='walletDetails'>
          <div className='walletIcon'>D</div>
          <h3>{walletAddress.slice(0, 4)}...{walletAddress.slice(walletAddress.length - 5, walletAddress.length)} <button className='btn' onClick={(e) => handleCopyToClipboard()}><i class="fa-regular fa-copy" ></i></button></h3>
          <h4>{localStorage.getItem("user_name")}</h4>
        </div>
        <div className='WalletMenuOptions'>
          <ul>
            <li className={pageName === 'dashboard' ? 'active' : ""} > <Nav.Link as={Link} to="./dashboard" >
              <div className='menuIcon dashboard'></div> Dashboard
            </Nav.Link></li>
            {/* <li className='active'> </li> */}
            <li className={pageName === 'deposit' ? 'active' : ""}> <Nav.Link as={Link} to="./deposit"><div className='menuIcon deposit'></div> Deposit</Nav.Link></li>
            <li className={pageName === 'withdrawal' ? 'active' : ""}><Nav.Link as={Link} to="./withdrawal"> <div className='menuIcon withdrawal'></div> Withdraw </Nav.Link></li>
            <li className={pageName === 'transection' ? 'active' : ""}> <Nav.Link as={Link} to="./transection"><div className='menuIcon trasectionHistory'></div> Transaction History </Nav.Link></li>
            <li className={pageName === 'changePassord' ? 'active' : ""}><Nav.Link as={Link} to="./changePassord"> <div className='menuIcon changePassword'></div> Change Password </Nav.Link></li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default WalletMenu