import React from "react";
import { Col, Row } from "react-bootstrap";
import BarCharts from "./BarChart";
import style from "./style.module.scss";
const ChartBar = ({ data }) => {
  const COLOR = [
    ["#83e5bd", "#00c575"],
    ["#65b8ff", "#1f78b4"],
    ["#fade4d", "#ffd500"],
  ];
  return (
    <>
      <div className={style.chartData}>
        <div className={`card ${style.card} `}>
          <div className="card-body">
            <h4>Monthly Comparison of Burn & Resell</h4>
            <Row>
              {Object.entries(data?.bar_chart).map((info, index) => {
                return (
                  <Col md={4} key={index}>
                    <BarCharts data={info} color={COLOR[index]} />
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChartBar;
