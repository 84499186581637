import React, { useEffect, useState } from 'react'
import { useHistory, Link } from "react-router-dom";
import { convertCurrency } from '../../utils/convertCurrency';
function NFTcard(props) {
    const history = useHistory();
    const [timeZone, setTimeZone] = useState('')
    const [dollarExchangeRate, setDollarExchangeRate] = useState("USD")
    const [nft, SetNft] = useState()


    useEffect(() => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimeZone(timeZone)
        console.log(props);
        SetNft(props.NFTType)
        return () => {
            SetNft([]);
        };
    }, [props])
    const getCurrency = async () => {
        const res = await convertCurrency("MATIC", "USD");
        setDollarExchangeRate(res.USD)
    }

    useEffect(() => {
        getCurrency();
    }, [])
    useEffect(() => {
        console.log("nftfnwejnfcjk", nft)
    }, [nft])
    return (
        <>
            <div className='NFTCardBox'>
                <div className='imageBox'>
                    <img src={nft?.nftImages?.length > 0 ? nft?.nftImages[0]?.image : ""} alt='' />

                    {nft?.sell_type === 'fixed' ? (
                        ''
                    ) : (<div className='auctionLabel'>
                        {new Date(nft?.create_date).toLocaleString('en-US', { timeZone })}
                    </div>)}

                </div>
                <div className='NFTDetails'>
                    <h1>{nft?.name}</h1>
                    <p>{new Date(nft?.create_date).toLocaleString('en-US', { timeZone })}, {nft?.country}</p>
                    <table>
                        <tr>
                            <td>Price</td>
                            <td>{nft?.price + "   "} (${dollarExchangeRate * nft?.price})</td>
                        </tr>
                        <tr>
                            <td>Project Type</td>
                            <td>{nft?.project_type}</td>
                        </tr><tr>
                            <td>Total Credit</td>
                            <td>{nft?.total_credit}</td>
                        </tr>
                        <tr>
                            <td>Fixed/Auction</td>
                            <td>{nft?.sell_type === 'fixed' ? 'Fixed' : 'Auction'}</td>
                        </tr>
                    </table>
                    <Link to={{ pathname: `/viewctnft/${nft?.id}`, state: { "view": "Market Place" } }}> {nft?.sell_type === 'fixed' ? "Buy" : "Bid"}
                    </Link>

                </div>
            </div>
        </>
    )
}

export default NFTcard