import styles from "./about.module.scss";
import BASE_URL from "../../utils/appUrls";
const OurVision = ({ data }) => {
  return (
    <div className={styles.OurVisionWrapper}>
      <div className="container-wrapper">
        <div className={styles.OurVision}>
          <div className={styles.OurVisionFirstColumn}>
            <img
              src={BASE_URL.HOST + data?.image_url}
              alt="Our Vision"
              className={styles.OurVisionImage}
            />
          </div>
          <div>
            <h2>
              <div dangerouslySetInnerHTML={{ __html: data?.title }} />
            </h2>
            <p>
              <div dangerouslySetInnerHTML={{ __html: data?.banner_text }} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurVision;
