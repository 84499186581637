import React, { useEffect, useState } from "react";
import { useToast } from "../context/Toast/useToast";
import { Col, Container, Form, Row } from "react-bootstrap";
import SubHeader from "../component/Subheader";
import { Label } from "recharts";
import { useLocation } from 'react-router-dom';
import CustomLoader from "../common/customLoader";
import { fetchProfilePicture, getUserData, updateUserPassword, updateUserProfile, uploadProfilePicture } from "../action/Api.action";

function ViewProfile() {
  const {addToast} = useToast()
  const { state } = useLocation();

const [showPass, setshowPass] = useState("password");
const [confshowPass, setconfshowPass] = useState("password");
const [selectedImgm, SetSelectedImg] = useState([])
const [imgPreview, SetImagePreview] = useState([])
const [isUpdateProfilem, Setisupdateprofile] = useState(false)
const [formData,setFormData] = useState({})
const [IsLoading,setIsLoading] = useState(false)
const [passwordFormData , setPasswordFormData] = useState({
  user_id : localStorage.getItem('userId')
})
const [errors , setErrors] = useState({
  email : "Kill em all"
})


  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };




  const handleImgUpload = async (encFile) => {
    try {

     
         const upload = await uploadProfilePicture({ "user_id": localStorage.getItem("userId"),"image": encFile})
      
      console.log(upload); // Handle the response as needed
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
  };


    const uploadProfile = async(e) =>{
        setIsLoading(true)
        const file =  [...e.target.files]
        console.log(e.target.files)
        SetSelectedImg(file)
        try {
            const encFile = await convertToBase64(file[0]) 
       // console.log("test for encryption",encFile)
       console.log(encFile)
     
        await handleImgUpload(encFile)
        
        } catch (error) {
            console.log('The error is : ' ,error )    
        }
        
        
        
        
        
        setIsLoading(false)
    }
    useEffect(()=>{
        const imagesArray = selectedImgm.map((file) =>
        window.URL.createObjectURL(file)
      );
      
        SetImagePreview(imagesArray) 
        console.log(selectedImgm);
    },[selectedImgm])

    useEffect(()=>{
        console.log(imgPreview);
     },[imgPreview])
    const removeAssets = () => {
        SetSelectedImg([])
    }

    const unlockEdit = () =>{
        Setisupdateprofile(true)
    }

    const validate = () =>{
      
        const errorData = {}

        if(!formData.phone.match((/^[0-9]{9,12}$/)))
        errorData.phone = "Enter valid phone number"
        if(!formData.mobile.match((/^[0-9]{9,12}$/)))
        errorData.mobile = "Enter valid mobile number" 
        if(!formData.name.match((/^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*/)))
      errorData.name = "Enter valid name"
        setErrors(errorData)

    }




    








    const editProfile = async()=>{
      console.log(formData,errors)
      console.log("error log for error length" , errors.length)
      if((errors.name || errors.phone || errors.mobile)){
          console.log("remove validation errors")
      }
      else{
      setIsLoading(true)
       const response = await updateUserProfile(formData)
        if(response.status){
        console.log("Success")
        addToast({
          message : "Profile Updated Successfully",
          type:"success"
        })
          localStorage.setItem('userName',formData.name)
          
      }
      else{
        addToast({
          message : "Profile update failed",
          type:"error"
        })
      }
      Setisupdateprofile(false)
      setIsLoading(false)
      }    
    }

    const showPassword = (e) =>{
        console.log(e.target);
        const getPassId =  document.getElementById(e)
        if(getPassId.id === 'password'){
            setshowPass(showPass === 'password' ? 'text':'password')
        } else {
            setconfshowPass(confshowPass === 'password' ? 'text':'password')
    
    
        }
        
    
    }

    const updateFormData = (index, value) => {
      console.log(value)
      setFormData(prevState => ({
        ...prevState,
        [index]: value
      }));
      validate()
      console.log(errors)
    };
    const updatePasswordFormData = (index, value) => {
      setPasswordFormData(prevState => ({
        ...prevState,
        [index]: value
      }));
      console.log(passwordFormData)
    };
    const submitPasswordForm = async()=>{
      setIsLoading(true)
      console.log(passwordFormData)
      if(passwordFormData.newPassword !== passwordFormData.reNewPassword)
      addToast({
        message : "Passwords do not match",
        type:"error"
      })
      else
      {
        addToast({
          message : "Password matched",
          type:"success"
        })
        const response = await updateUserPassword(passwordFormData)
        if(response.status == 200)
        {
          addToast({
            message : "Password updated successfully",
            type:"success"
          })
          setPasswordFormData({
            user_id : localStorage.getItem('userId')
          })
        }
        else if(response.status == 401)
        {
          addToast({
            message : "Enter correct password",
            type:"error"
          })
        }
        else{
          addToast({
            message : "Some error occured",
            type:"error"
          })
        }
      }
      setIsLoading(false)
    }




    useEffect(async()=>{
        setIsLoading(true)
        const userData = await getUserData({user_id : localStorage.getItem('userId')})
        console.log("User Data is : ",userData.user_details)
        setFormData(userData.user_details[0])
        console.log("Formdata is : ")
        const profileImg = await fetchProfilePicture({user_id : localStorage.getItem('userId')})
        if(profileImg.status)
        SetImagePreview(profileImg.imageData)
        setIsLoading(false)
      },[])




  return (
    <>

     <SubHeader/>

     {IsLoading && (
        <div>
          <CustomLoader />
        </div>
      )} 
      <Container fluid>
        <Container>
          <Row className="justify-content-md-center ">
            <Col className="mb-3" sm={12}>
              <div className="moreInfoSec formContent boxShaddow viewProfileBox">
                    <h1>{isUpdateProfilem ===  true ? 'Edit Profile':'Account Details'}</h1>
                <Row>
                    <Col lg={4}>
                        {imgPreview.length > 0 ? (
                            <>
                            <div className="photoUpload">
                               <div className="imgpreview">
                               <img src={imgPreview} className="img-fluid" alt="" />
                               <button disabled={isUpdateProfilem ===  true ? false:true}  onClick={() =>{removeAssets()}} className="closeBtnimg" type="button">
                                  <img src="/images/svg/CloseWindow.svg" alt="" />
                                </button>
                               </div>
                            </div>
                            </>
                        ):(
                            <>
                            <div className="photoUpload">
                                <input disabled={isUpdateProfilem ===  true ? false:true}  type="file" id="profilephoto" onChange={async(e) =>await uploadProfile(e)} />
                                <label htmlFor="profilephoto">
                                <img src={"/images/svg/UploadImage.svg"} alt="upload" />
                                <h3>Upload Profile Image</h3>
                                </label>
                            </div>
                            </>
                        )}
                       
                    </Col>
                    <Col lg={8}>
                        <div className="accountDetails">
                            <div className="d-flex align-items-center gap-3 mb-4">
                            <button className="typebtn" type="button">
                                <span>User Type</span>
                                {formData.user_type}
                            </button>
                            <button className="typebtn" type="button">
                                <span>User Role</span>
                                {localStorage.getItem('userType') == 'producer' ? 'Supplier' : 'Buyer' }
                            </button>
                            </div>
                            <Form>
                            <Row>
                                <Col lg={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control type="text"  disabled={isUpdateProfilem ===  true ? false:true} placeholder="" onChange={async(e)=>{
                                          updateFormData('name', e.target.value)
                                    }} 
                                    value={formData.name}/>
                                    {(isUpdateProfilem && errors.name)&& <p style={{"color" : "red", "alignItems" : "left"}}>*{errors.name}</p>}
                                </Form.Group>
                                </Col>
                                <Col lg={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control type="text" disabled={isUpdateProfilem ===  true ? false:true}  placeholder="" onChange={async(e)=>{
                                          updateFormData('phone', e.target.value)
                                    }} value={formData.phone} />

                                    {(isUpdateProfilem && errors.phone) && <p style={{"color" : "red", "alignItems" : "left"}}>*{errors.phone}</p>}
                                </Form.Group>
                                </Col>
                                <Col lg={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" disabled={true}  placeholder="" value={formData.email} />
                                </Form.Group>
                                </Col>
                                <Col lg={6}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="text" disabled={isUpdateProfilem ===  true ? false:true}  placeholder="" value={formData.mobile} onChange={async(e)=>{
                                          updateFormData('mobile', e.target.value)
                                    }}/>
                                    {(isUpdateProfilem && errors.mobile)&& <p style={{"color" : "red", "alignItems" : "left"}}>*{errors.mobile}</p>}
                                </Form.Group>
                                </Col>
                            </Row>
                            </Form>
                        </div>
                      
                    </Col>
                    <Col lg={12}>
                        <h3></h3>
                    </Col>
                    <Col lg={12}>
                    {isUpdateProfilem ===  false ? (
                        <><button className="  secondaryBtn   btn w-auto ms-auto" onClick={()=> unlockEdit()}>Edit Profile</button></>
                    ):<><button className=" secondaryBtn   btn w-auto  ms-auto" onClick={async()=> editProfile()}>Update</button></>}
                    
                    </Col>
                </Row>
              </div>
            </Col>
            <Col sm={12}>
              <div className="moreInfoSec formContent boxShaddow viewProfileBox">
                    <h1>Change Password</h1>
                    <Row>
                    <Col lg={6}>
                    <Form autoComplete="false">
                   
                    <Form.Group className="mb-3" >
                      <Form.Label>old Password</Form.Label>
                     <div className="position-relative">
                     <Form.Control type='password' id="password" value={passwordFormData.password} onChange={async(e)=>{
                                          updatePasswordFormData('password', e.target.value)
                                    }}
                                    placeholder="Enter old Password" />
                     </div>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                      <Form.Label>Password</Form.Label>
                     <div className="position-relative">
                     <Form.Control type={showPass} id="password" placeholder="Enter Password" onChange={async(e)=>{
                                          updatePasswordFormData('newPassword', e.target.value)
                                    }}/>
                      <button type="button" className="btn passwordSHow" value={passwordFormData.newPassword} onClick={()=> showPassword('password')}><i class={"fa-regular "  + (showPass === 'text' ? 'fa-eye':'fa-eye-slash')}></i></button>
                     </div>
                    </Form.Group>
                    <Form.Group className="mb-0" >
                      <Form.Label>Confirm Password</Form.Label>
                      <div className="position-relative">
                     <Form.Control type={confshowPass} id="Confirmpassword" placeholder="Enter Confirm Password" onChange={async(e)=>{
                                          updatePasswordFormData('reNewPassword', e.target.value)
                                    }} />
                      <button type="button" className="btn passwordSHow" value={passwordFormData.reNewPassword} onClick={()=> showPassword('Confirmpassword')}><i class={"fa-regular  " + (confshowPass === 'text' ? 'fa-eye':'fa-eye-slash') }></i></button>
                     </div>
                    </Form.Group>
                  </Form>
                
                   <button className=" secondaryBtn   btn mt-4  ms-auto" onClick={submitPasswordForm}>Change Password</button>
                    
                   
                    </Col>
                </Row>
                </div>
              
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default ViewProfile;
