
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Navbar, Button, Form } from 'react-bootstrap';
import { resetPassword } from '../action/walletApiAction';
import { setTopCategorie } from '../slices/home';
import { useToast } from '../context/Toast/useToast';

// import { Snackbar } from "@material-ui/core";
// import MuiAlert from "@material-ui/lab/Alert";

// import {saveStorage, getStorageData} from "../utils/common";

const ResetPassword = ({ history }) => {
	const [showPass, setshowPass] = useState("password");
	const [confshowPass, setconfshowPass] = useState("password");
	const { register, handleSubmit, watch, formState: { errors } } = useForm();
	const { addToast } = useToast();

	const [formData, setFormData] = useState({
		password: '',
		cpassword: '',
		token: ''
	});
	const [isUpdate, setUpdate] = useState(false);

	//const [otp, setOTP] = useState('');
	//const [password, setPassword] = useState('');
	//const [cPassword, setCPassword] = useState('');
	//const [emailAddress, setEmailAddress] = useState('');

	const [error, setError] = useState({});

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const dataParam = queryParams.get('data');
		setFormData((p) => ({ ...p, token: dataParam }))
	}, [])
	const onSubmit = async data => {

		if (formData.password === formData.cpassword) {


			let res = await resetPassword(formData);
			console.log("response", res.data.status)

			if (res.data.status === 200) {
				addToast({
					message: "Password Updated Successfully",
					type: "success",
					duration: 3000,
				})

				history.push({ pathname: "/login" });

			} else if (res.data.status === 401) {
				addToast({
					message: "Password Link has expired",
					type: "Error",
					duration: 3000,
				})
				history.push({ pathname: "/forgot-password" });
			}
		} else {
			let err = error;
			err['cpassword'] = 'Password not match.';
			setError(err);
			setUpdate(!isUpdate);
		}
	};


	const formHandler = (e, field) => {
		let data = formData;
		let err = error;
		data[field] = e.target.value;
		if (err[field]) {
			delete err[field];
		}
		setError(err);
		setFormData(data);
		setUpdate(!isUpdate);

	}

	/* Api Calling */
	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const dataParam = queryParams.get('data');
		console.log("PARAMS%%%%%%%%%%%%:", dataParam)


		if (history.location.state && history.location.state.email) {
			setFormData({ email: history.location.state.email });
		} else {
			// window.location.href = '/login';
		}

	}, []);
	const showPassword = (e) => {
		console.log(e.target);
		const getPassId = document.getElementById(e)
		if (getPassId.id === 'password') {
			setshowPass(showPass === 'password' ? 'text' : 'password')
		} else {
			setconfshowPass(confshowPass === 'password' ? 'text' : 'password')


		}


	}

	const onChangeHandler = (e, name) => {
		setFormData((p) => ({ ...p, [name]: e }))

	}

	useEffect(() => {
		console.log("formdata", formData)
	}, [formData])

	return (
		<Container>

			{/* <Snackbar open={loginError.code != 0} autoHideDuration={8000} onClose={hideLoginError}>
				<MuiAlert elevation={10} variant="outlined" severity={loginError.severity} onClose={hideLoginError}>
					<h4>
						{loginError.message}
					</h4>
				</MuiAlert>
			</Snackbar> */}

			<div className="loginForm burger">
				<Row className="align-items-center  justify-content-center">
					<Col xs={12} sm={9} md={7} lg={5}>

						<form className="formContent boxShaddow" onSubmit={handleSubmit(onSubmit)}>
							<div className="heading">
								<h2>Reset Password</h2>
							</div>

							{/* <div className="mb-3 form-group">
							<input type="number" className="form-control" placeholder="Enter code" defaultValue={formData.code} {...register("code", { required: true })} onChange={e=>formHandler(e, 'code')} />
							{errors.code && <span className="error">Code is required.</span>}
							<small className="error">{error.code?error.code:''}</small>
						</div> */}

							<div className="mb-3 form-group">
								<div className='position-relative'>
									<input type={showPass} id="password" className="form-control" placeholder="New Password" defaultValue={formData.password} {...register("password", { required: true })} onChange={e => onChangeHandler(e.target.value, 'password')} />
									<button type="button" className="btn passwordSHow" onClick={() => showPassword('password')}><i class={"fa-regular " + (showPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>
								</div>
								{errors.password && <span className="error">Password is required.</span>}
								<small className="error">{error.password ? error.password : ''}</small>
								{/* <Form.Text className="text-muted">
									Password Strength Indicator: <span className='week'>Weak</span> | <span className='medium'>Medium</span> | <span className='strong'>Strong</span>
								</Form.Text> */}
							</div>

							<div className="mb-3 form-group">
								<div className='position-relative'>
									<input type={confshowPass} id="Confirmpassword" className="form-control" placeholder="Confirm Password." defaultValue={formData.cpassword} {...register("cpassword", { required: true })} onChange={e => onChangeHandler(e.target.value, 'cpassword')} />
									<button type="button" className="btn passwordSHow" onClick={() => showPassword('Confirmpassword')}><i class={"fa-regular  " + (confshowPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>
								</div>
								{errors.cpassword && <span className="error">Confirm password is required.</span>}
								<small className="error">{error.cpassword ? error.cpassword : ''}</small>
							</div>

							<div className="loginFrgtPwdBtns">
								<Button className="secondaryBtn btn" type="submit">Reset Password</Button>
								<Navbar.Text className='text-center'>
									<Link to="/login" className="forgotPwd  mx-auto">Go to Login</Link>
								</Navbar.Text>
							</div>
						</form>


					</Col>
				</Row>
			</div>

		</Container>
	);
};

export default ResetPassword;