import { Row, Col } from 'react-bootstrap';
import BASE_URL from "../../utils/appUrls";
const FactSec = ({ data }) => {
    return (
        <div className="factSec burger">
            <Row>
                {data?.banner_image?.length > 0 &&
                    data?.banner_image?.map((info, index) => (
                    <Col sm={4} key={index}>
                        <div className="factContent">
                            <img src={BASE_URL.HOST + info.image_url} alt="producer-icon" />
                            <div>
                                <h4 dangerouslySetInnerHTML={{ __html: info?.heading }} ></h4>
                                <p dangerouslySetInnerHTML={{ __html: info?.text }} ></p>
                            </div>
                        </div>
                    </Col>
                ))}            
            </Row>
        </div>
    );
};

export default FactSec;
