import { Row, Carousel, Button } from 'react-bootstrap';
import BASE_URL from "../../utils/appUrls";
import {Link} from 'react-router-dom'

const Banner = ({ banners, pageType }) => {
    
    return (
        <div className={`homeBanner ${pageType}`}>
            <Row>
                <Carousel>
                    {banners?.length > 0 &&
                        banners?.map((data, index) => (
                        <Carousel.Item>
                            <img className="d-block w-100" src='/images/png/homeBanner1.png' alt={data?.name} />
                            <Carousel.Caption>
                                <div dangerouslySetInnerHTML={{ __html: data?.title }} />
                                <div dangerouslySetInnerHTML={{ __html: data?.banner_text }} />
                                <div className="bannerBtns">
                                    <Button variant="" className="secondaryBtn">Contact us</Button>
                                    <Link to="/login"><Button variant="" className="primaryBtn">Join Now</Button></Link>
                                </div>
                            </Carousel.Caption>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Row>
        </div>
    );
};

export default Banner;