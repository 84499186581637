export const BASE_URL = process.env.REACT_APP_API_URL;
const Url = {
  HOST: BASE_URL,
  UPLOAD_PROFILE_PICTURE : 'api/user/uploadimg',
  FETCH_PROFILE_PICTURE : 'api/v1/user/fetchpicture',
  UPDATE_USER_PROFILE : 'api/user/profile/update',
  UPDATE_USER_PASSWORD : 'api/user/password/update',
  UPLOAD_PROJECT_ASSETS:"api/project/assets/upload",
  RANDOM_MESSAGE: "api/v1/wallet/safe/connect/ccx/",
  VERIFY_SIGN: "api/v1/wallet/safe/connect/verify/ccx",
  PROFILE_DETAIL: "api/v1/profile/detail",
  COUNTRY_DROWPDOWN: "api/v1/get/user/master/detail",
  STATE_DROPDOWN: "api/v1/get/state/list/",
  UPLOAD_PROFILE_IMAGE: "api/v1/image/profile/upload",
  DOWNLOAD_PROFILE_DOCUMENTS: "api/v1/get/partner/document/list/download/",
  GET_REQUIRED_DOCUMENTS: "api/v1/get/partner/document/required",
  UPLOAD_PROFILE_DOCUMENT: "api/v1/partner/document/upload",
  PROFILE_UPDATE: "api/v1/profile/update",
  CERTIFICATE_UPDATE: "api/v1/dapp/update",
  CERTIFICATE_DOCUMENTS_REQUIRE: "api/v1/get/dapp/",
  CERTIFICATE_DOCUMENTS_UPLOAD: "api/v1/ccx/document/upload",
  GET_QUOTE: "api/v1/certificate/get/quote",
  GET_PRICE: "api/v1/get/price",
  UPDATE_CART: "api/v1/certificate/cart/update",
  GET_CART_DETAIL: "api/v1/cart/details",
  TRADING_HISTORY: "api/v1/trade/history",
  ORDER_HISTORY: "api/v1/get/booking",
  PHAE_VALUE: "api/v1/get/phae/rate ",
  MY_COLLECTION: "api/v1/certificate/collection",
  MY_RESELL: "api/v1/get/resell",
  MY_ORDER: "api/v1/get/myorder",
  SAVE_TRANSACTION_HASH: "api/v1/blockchain/transaction/store",
  CREATE_RESELL: "api/v1/create/resell/request",
  DELIST_CTNF: "api/v1/create/withdraw/request",
  CREATE_BURN: "api/v1/create/burn/order",
  RESELL_BLOCKCHAIN_METADATA: "api/v1/get/resale/blockchain/metadata",
  DELIST_BLOCKCHAIN_METADATA: "api/v1/delist-meta-data",
  BURN_ORDER_LIST: "api/v1/list/burn/order",
  RESELL_PRICE_CHANGE: "api/v1/update/resell/request",
  WITHDRAW_LIST: "api/v1/list/withdraw",
  CREATE_BURN_ORDER: "api/v1/get/burn/nft/metadata",
  GET_NONCE: "https://service-ccx-dev.prosofo.com/api/v2/accounts?publicKey=",
  SERVICE_URL: "https://service-truss.clymene.network",
  // SERVICE_URL: "https://service-ccx-dev.prosofo.com",
  CTNFTLIST_LIST: "api/v1/list/status/ctnft",
  USER_LOGOUT: "api/v1/logout",
  USER_REGISTER: "api/v1/register", // new added
  USER_verification:  "userverify/generateOTP",// new added
  verifyauth: "api/v1/verifyauth", //new added"
  USER_AUTH: "api/auth", // new added login route
  SALE_ORDER_METADATA: "api/v1/get_order_payment/detail",
  CERTIFICATE_ACTIVATE: "api/v1/certificate/active",
  CTNFT_SUBMIT_APPROVAL: "api/v1/dapp/submitforapproval",
  CREATE_CTNFT_SIGNATURE: "api/v1/producer/signature",
  PROJECT_CREATE : "api/v1/project/register",
  PROJECT_ASSET_UPLOAD : "api/v1/project/register",
  HOMEPAGE: "api/v1/homepage/",
  FOOTER_API: "api/v1/footer/home",
  CMS_API: "api/v1/cms/pages/",
  RESELL_LIST: "api/v1/get/pending/dapp/list",
  GET_AUTHORITY: "api/v1/get/country/authority/",
  DASHBOARD_POLLUTER: "api/v1/dashbord/polluter",
  CONTACT_US: "api/v1/ccx/contactus",
  FETCH_USER : "api/v1/fetchUser", // new added
  USER_DATA : "api/v1/get/user/detail",
  CREATE_WALLET:"wallet/create_wallet",
  GET_WALLET:"wallet/get_wallet_from_useId",
  CONNECT_WALLET:"wallet/connect_wallet",
  GET_PARTICULAR_LISTING:"nft/get_particular_nft_listing",
  CREATE_MINTNFT:"nft/create_nft",
  CREATE_SALENFT:"nft/create_sell_nft",
  GET_USER_BALANCE:"wallet/get_balance_from_userId",
  GET_LISTED_NFT:"nft/get_listed_nft_from_userId",
  GENERATE_OTP : "userverify/generateOTP",
  VERIFY_OTP : "userverify/verifyOTP",
  VERIFY_USER : "userverify/verifyUser",
  UPDATE_MINTED_STATUS:"nft/update_carbon_credit",
  GET_MARKET_PLACE_NFT:"nft/nft_for_market_place",
  NFT_FROM_ID_RELATED_PROJECT:"nft/get_particular_nft_from_nft_id_related_nft",
  forgotPassword: "userverify/forgotPassword",
  resetPassword: "userverify/resetPassword"

};

export default Url;
