import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import NFTcard from '../Marketplace/NFTcard'
import ImageGallery from "react-image-gallery";
import { getNftFromIdAndRelatedProjects } from '../../action/walletApiAction';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useToast } from "../../context/Toast/useToast";
import CustomLoader from "../../common/customLoader/index"
import { convertCurrency } from '../../utils/convertCurrency';
function ViewCTNFT() {
    const [nfttype, Setnfttype] = useState("Market Place")
    const location = useLocation()
    const history = useHistory()
    const [index, setIndex] = useState(0);
    // const handleSelect = (selectedIndex) => {
    //     setIndex(selectedIndex);
    // };

    const params = useParams()
    const { addToast } = useToast();
    const [nftDet, setNftDet] = useState({})
    const [timeZone, setTimeZone] = useState('')
    const [loading, setLoading] = useState(false)
    const [relatedProejcts, setRelatedProjects] = useState([])
    const [dollarExchangeRate, setDollarExchangeRate] = useState("USD")
    const [images, setImages] = useState([])
    const getNftFromIdAndRelatedProjectFunc = async () => {
        setLoading(true)
        let body = {}
        body.res_nft_id = params.nft_id
        body.view = location.state.view
        const nftRes = await getNftFromIdAndRelatedProjects(body)
        setLoading(false)
        if (nftRes.Status === "success") {
            console.log("nftRes.data.nftDet", nftRes.data.nftDet)
            setNftDet(nftRes.data.nftDet)
            let arr = []
            for (let i = 0; i < nftRes.data.nftDet.nftImages.length; i++) {
                if (nftRes.data.nftDet.nftImages[i]) {
                    arr.push({ original: nftRes.data.nftDet.nftImages[i].image })
                }
            }
            console.log()
            setImages(arr)
            if (nftRes?.data?.relatedProjects.length > 0) {
                setRelatedProjects(nftRes?.data?.relatedProjects)
            }
        } else {
            addToast({
                message: nftRes.message,
                type: "error",
            });
        }
    }
    useEffect(() => {
        getNftFromIdAndRelatedProjectFunc()
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setTimeZone(timeZone)
    }, [params])
    useEffect(() => {
    }, [relatedProejcts])
    const getCurrency = async () => {
        const res = await convertCurrency("MATIC", "USD");
        setDollarExchangeRate(res.USD)
    }
    useEffect(() => {
        Setnfttype(location?.state?.view === "Listed" ? "Listed NFT's" : "Market Place")
        getCurrency();
    }, [location])

    useEffect(() => {
        console.log("history", history)
        console.log("location" ,location)
        if (location) {
            if (location.state.view)
                console.log("true");
        } else {
            console.log("false");
        }
    }, [location, history])
    // const handleRedirect=(page)=>{
    //     var url=""
    //     if(page==="Listed") url="/approvedCNFT"
    //     if(page==="Market Place") url="/marketplace"

    //     history.push({pathname: url})
    // }
    return (
        <>
            {loading && (
                <div>
                    <CustomLoader />
                </div>
            )}

            <Container className='marketplacebx' fluid>
                <div className='marketplaceMaine'>
                    <Row>
                        <Col lg={8}>
                            <ul>
                                <li > <h1>{nfttype}</h1></li>
                                <li> <h1 className='viewpagehead'>{nftDet?.name}</h1></li>
                            </ul>

                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col lg={7}>
                            <div className='ImagesBox'>
                                <ImageGallery autoPlay={true} items={images} />

                                {/* <div className='imgbx bx1'>
                            <img src={'https://s3-alpha-sig.figma.com/img/1ddd/b97b/8c574bd43ccfe1de990c38023eacd89e?Expires=1716768000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fX4v5PZXTNlFyWmU38BONV2cf2qxnzO4Ifi91xSH7nW44aTVcQWagp1tCS4g8K5PtTbUHwYJ9j0xtz8t3Ijtb1HiZ--WLjr0g004DW3ERvld2DmUCLmfSgDCxvmyzVxL3h4wnI4a51ZENpWzllu~1k5TQkQ6AQQsim7em4pJC3pS3H1b4~ML8xD7fNl20DjSiw8kWLLw1MNdVuHqkVU-tkLWHzg~RRQKrqWGfEYG28HN18S9Rayj2UlkFyznYXNmVy7zCPDtgP76nq9ZEtaHgbb-L2XxQWii0KRygD5FhPDfRMBaePt-DC8beuFKxuHB659bayXqPumBuUnmoZqZrg__'} alt='' />
                            </div>
                            <div className='imgbx noimg'>
                                <img src="/images/svg/imageIcon.svg" alt="" />
                            </div>
                            <div className='imgbx noimg'>
                                <img src="/images/svg/imageIcon.svg" alt="" />
                            </div>
                            <div className='imgbx noimg'>
                                <img src="/images/svg/imageIcon.svg" alt="" />
                            </div>
                            <div className='imgbx noimg'>
                                <img src="/images/svg/imageIcon.svg" alt="" />
                            </div> */}

                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className='NFTcontentBox'>
                                <div className='d-flex align-items-center gap-2 viewprice mb-3'><img width={35} src="/images/svg/usdcIcon.svg" alt="" />  (${nftDet?.price * dollarExchangeRate})</div>
                                <h2>{nftDet?.name}</h2>
                                <p>{new Date(nftDet?.create_date).toLocaleString('en-US', { timeZone })}, {" " + nftDet.country}</p>
                                <div>
                                    <p>{nftDet?.description}</p>
                                </div>
                                <h3>Address</h3>
                                <p>{nftDet?.address}, {nftDet?.city}, {nftDet?.country} </p>
                                <h3>Registry</h3>
                                <p>{nftDet?.registry}</p>
                                {/* <h3>Other Details</h3>
                                <table>
                                    <tr>
                                        <td>Price</td>
                                        <td>{nftDet?.price} (${nftDet?.price * dollarExchangeRate})</td>
                                    </tr>
                                    <tr>
                                        <td>Project Type</td>
                                        <td>{nftDet?.project_type}</td>
                                    </tr>
                                    <tr>
                                        <td>Total Credit</td>
                                        <td>{Number(nftDet?.total_credit)}</td>
                                    </tr>
                                    <tr>
                                        <td>Fixed/Auction</td>
                                        <td>{nftDet?.sell_type === "fixed" ? "Fixed" : "Auction"}</td>
                                    </tr>
                                </table> */}
                                {(nfttype !== "Listed" && nftDet?.sell_type == "fixed") &&
                                    < button className='primaryBtn btn'>Buy</button>
                                }
                                {nftDet?.sell_type === 'auction' && nfttype !== 'Listed' &&
                                    (<>
                                        <h3>Place Bid</h3>
                                        <Form.Control type='text' placeholder='Enter Your Bid Amount'></Form.Control>
                                        <button className='primaryBtn btn'>Place Bid</button>
                                    </>)}
                            </div>
                        </Col>

                    </Row>
                    {/* // view and auction  */}

                    <Row className='mb-4'>
                        <Col lg={7}>
                            <Row className='h-100'>
                                <Col lg={6}>
                                    <div className='nftDetailsBox'>
                                        <h3>Offset</h3>
                                        <table>
                                            <tr>
                                                <td>Verifier</td>
                                                <td>{nftDet?.verifier}</td>
                                            </tr>
                                            <tr>
                                                <td>Credit</td>
                                                <td>{nftDet?.total_credit}</td>
                                            </tr>
                                            <tr>
                                                <td>Currency</td>
                                                <td>{nftDet?.currency}</td>
                                            </tr>

                                            <tr>
                                                <td>Serial Number</td>
                                                <td>{nftDet?.certificate_id}</td>
                                            </tr>
                                            <tr>
                                                <td>Standard</td>
                                                <td>{nftDet?.standard}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className='nftDetailsBox'>
                                        <h3>Other Details</h3>
                                        <table>

                                            <tr>
                                                <td>Project Type</td>
                                                <td>{nftDet?.project_type}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Credit</td>
                                                <td>{nftDet?.total_credit}</td>
                                            </tr>
                                            <tr>
                                                <td>Fixed/Auction</td>
                                                <td>{nftDet?.sell_type==='fixed'? "Fixed" :"Auction"}</td>
                                            </tr>
                                            <tr>
                                                <td>No. of Certificates</td>
                                                <td>{nftDet?.no_of_docs}</td>
                                            </tr>

                                        </table>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                        {nftDet?.sell_type === 'auction' && (<>
                            <Col lg={5}>
                                <div className='nftDetailsBox offertable px-0'>
                                    <h3><i class="fa-solid fa-list"></i> Offers</h3>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>Price</td>
                                                <td>From</td>
                                                <td>To</td>
                                                <td>Time</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><div className='d-flex align-items-center gap-2'><img width={20} src="/images/svg/usdcIcon.svg" alt="" /> 0.0231</div></td>
                                                <td>0x2ed0feb3e7fd202212</td>
                                                <td>0x2ed0feb3e7fd202212</td>
                                                <td>12 Days <span className='d-block'>(15 May 2024,5.35 PM)</span> </td>
                                            </tr>
                                            <tr>
                                                <td><div className='d-flex align-items-center gap-2'><img width={20} src="/images/svg/usdcIcon.svg" alt="" /> 0.0231</div></td>
                                                <td>0x2ed0feb3e7fd202212</td>
                                                <td>0x2ed0feb3e7fd202212</td>
                                                <td>12 Days <span className='d-block'>(15 May 2024,5.35 PM)</span> </td>
                                            </tr>
                                            <tr>
                                                <td><div className='d-flex align-items-center gap-2'><img width={20} src="/images/svg/usdcIcon.svg" alt="" /> 0.0231</div></td>
                                                <td>0x2ed0feb3e7fd202212</td>
                                                <td>0x2ed0feb3e7fd202212</td>
                                                <td>12 Days <span className='d-block'>(15 May 2024,5.35 PM)</span> </td>
                                            </tr>
                                            <tr>
                                                <td><div className='d-flex align-items-center gap-2'><img width={20} src="/images/svg/usdcIcon.svg" alt="" /> 0.0231</div></td>
                                                <td>0x2ed0feb3e7fd202212</td>
                                                <td>0x2ed0feb3e7fd202212</td>
                                                <td>12 Days <span className='d-block'>(15 May 2024,5.35 PM)</span> </td>
                                            </tr>
                                            <tr>
                                                <td><div className='d-flex align-items-center gap-2'><img width={20} src="/images/svg/usdcIcon.svg" alt="" /> 0.0231</div></td>
                                                <td>0x2ed0feb3e7fd202212</td>
                                                <td>0x2ed0feb3e7fd202212</td>
                                                <td>12 Days <span className='d-block'>(15 May 2024,5.35 PM)</span> </td>
                                            </tr>
                                        </tbody>


                                    </table>
                                </div>
                            </Col>
                        </>)}
                        {nfttype === "Listed NFT's" && (<>
                            <Col lg={5}>
                                <div className='nftDetailsBox h-auto'>
                                    <h3> Certificates</h3>
                                    <div className='certificateBox'>
                                        {nftDet?.doc_uris?.length > 0 ? (nftDet?.doc_uris?.map((item, i) =>
                                            // <div className='certificates images' key={i}>
                                            //     <img src={item?.document_uri} alt='' />
                                            // </div>
                                            <a href={item?.document_uri} download="MyExampleDoc" rel="noreferrer" target='_blank'>
                                                <img src="/images/svg/pdfIcon.svg" alt='' />
                                            </a>
                                            // <div style={{ height: '500px' }}>
                                            //     {/* <Worker workerUrl="path/to/pdf.worker.js"> */}
                                            //         <Viewer fileUrl={item?.document_uri} />
                                            //     {/* </Worker> */}
                                            // </div>
                                        ))

                                            : "No Documnet Found"}
                                        {/* <div className='certificates pdfasset'>
                                                <img src="/images/svg/pdfIcon.svg" alt='' />
                                            </div>
                                            <div className='certificates images'>
                                                <img src='https://s3-alpha-sig.figma.com/img/66b8/889a/0c9c213ed62941b2febb12377ced3e1c?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jt7w1rq54RFGERjdpDSAIsqfj3yuyrilwiFu6d5rePbrU0xpFrGQztPoKbYq~qT2Zu0yR0G9dXl7sVXNHimydonWzEoajW4n0VXz0PLgJXB3ZUZ2ofLJtDiHVvFD33nIxaXYioub0z8jIGPbrOmLizsOeaCKec2yOHEg~EFpiJf3L4Jm9lB3usC1MkqdfAoDCn-LjHqKpdG6pVZXUNt1XxxE2QSOV8FobS-5DK7kjNJXki3EAEvgNTCsMIVqexYsn4x5qhJz2NiVgOm8jgdw0zAXBFVaXdRtf1Pi6OYEzwBEpXa7objhU~DGVtiaicC1H-y9SN1Ne1KR5y6ztMcLqg__' alt='' />
                                            </div>
                                            <div className='certificates pdfasset'>
                                                <img src="/images/svg/pdfIcon.svg" alt='' />
                                            </div> */}


                                    </div>
                                </div>
                                <button className='primaryBtn btn mt-4 ms-auto'>Update</button>
                            </Col>
                        </>)}


                    </Row>

                    {nfttype !== "Listed NFT's" && (
                        <Row>

                            <Col lg={12}>
                                <div className='marketplaceMaineInner'>
                                    <div className='headwithView'>
                                        <h1>Related Project</h1>
                                        <p className='d-flex align-items-center gap-2'>View All <img width={30} src="/images/svg/arrowRight.svg" alt='' /></p>
                                    </div>
                                    <Row>
                                        <Col lg={12}>
                                            <Row>
                                                {relatedProejcts.length > 0 && relatedProejcts.map((item, i) =>
                                                    <Col lg={3} key={i}>
                                                        <NFTcard NFTType={item} />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
            </Container >
        </>
    )
}

export default ViewCTNFT