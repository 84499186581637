import styles from "./about.module.scss";
import BASE_URL from "../../utils/appUrls";

const OurMission = ({ data }) => {
  return (
    <>
      {data ? (
        <div className={styles.OurMissionWrapper}>
          <div className="container-wrapper">
            <div className={styles.OurMission}>
              <div className={styles.OurMissionFirstColumn}>
                <h2 dangerouslySetInnerHTML={{ __html: data.title }} />
                <p dangerouslySetInnerHTML={{ __html: data.banner_text }} />
              </div>
              <div className={styles.OurMissionSecondColumn}>
                <img
                  src={BASE_URL.HOST + data?.image_url}
                  alt="Our Mission"
                  className={styles.OurMissionImage}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default OurMission;
