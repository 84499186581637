import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Subheader from "../component/Subheader";
import BASE_URL from "../utils/appUrls";
import { memo } from "react";
import moment from 'moment';
import {
  createDelist,
  createResell,
  deListBlockChainMetaData,
  getCtnftList,
  getPendingList,
  saveTransactionHash,
} from "../action/Api.action";
import NoRecordsFound from "../component/NoRecordFound";
import styles from "../common/WithdrawList/order.module.scss";
import { useDispatch, useSelector } from "react-redux";
import ProducerStatus from "../common/modal/producerStatus";
import Pagination from "../common/pagination";
import axios from "axios";
import fileDownload from "js-file-download";
import { setDapp, setResubmission } from "../slices/home";
import { BASE_CURRENCY, NAME } from "../constant/constant";
import { createResellTransaction } from "../component/walletConnect";
import { useLoader } from "../context/Loader/useLoader";
import { useToast } from "../context/Toast/useToast";
import { getListedNft } from "../action/walletApiAction";
import { useLocation, Link } from 'react-router-dom';

const ApprovedCTNFT = ({ history, status, location }) => {
  const itemsPerPage = process.env.REACT_APP_ITEMPERPAGE;
  const [ctnftList, setCTNFTList] = useState([]);
  const [timeZone, setTimeZone] = useState('')
  const [paging, setPaging] = useState({
    count: 0,
    totalCount: 0,
  });
  const [viewMore, setViewMore] = useState(false);
  const [cardOpen, setCardOpen] = useState();
  const [width, setWidth] = useState();
  const userData = useSelector((state) => state?.auth?.profileData);
  const [CurrentStep, setCurrentStep] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReformatCertificates, setReformatCertificates] = useState([]);
  const [allCertificates, setAllCertificates] = useState([]);
  const [listedNfts, setListedNfts] = useState([])
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const { addToast } = useToast();
  const [subPagination, SetPagination] = useState()
  const { state } = useLocation();

  useEffect(() => {
    SetPagination(state)
  }, [state])
  const handleViewMore = (i) => {
    let checkCardOpen = cardOpen ? cardOpen : i;
    setViewMore(i === checkCardOpen ? !viewMore : viewMore);
    setCardOpen(i);
    let certificates = [];
    if (ctnftList[i].docs) {
      ctnftList[i].docs.forEach((info) => {
        certificates.push(info);
      });
    } else {
      ctnftList[i].certificates_ids.forEach((info) => {
        certificates.push(info);
      });
    }
    let reformatCertificates = [];
    for (let j = 0; j < itemsPerPage; j++) {
      reformatCertificates.push(certificates[j]);
    }
    setReformatCertificates(reformatCertificates);
    setAllCertificates(certificates);
  };

  useEffect(() => {
    setWidth(window.innerWidth);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone)

    // const convertedDateTime = 
  }, []);

  /*const getUserProductList = async()=>{
    let resp = await getUserProductListAction({});

    if(resp.code === 200){
      setCTNFTList(resp.data);
    }
  }*/

  const fetchCtnftList = async () => {
    if (status == "inactive") {
      const resp = await getPendingList();
      if (resp?.status) {
        resp?.data &&
          resp.data.map((nft) => {
            if (nft.state == "resubmission") {
              addToast({
                message: "CNFT Documents Rejected, Please Resubmit Documents",
                type: "error",
                duration: 3000,
              });
              return;
            }
          });
        setCTNFTList(resp?.data);
      }
    } else {
      let request = {
        status,
      };
      const res = await getCtnftList(request, currentPage);
      if (res?.status) {
        setCTNFTList(res?.list_ctnft);
        setPaging({
          count: paging.count == 0 ? res.ctnft_count : paging.count,
          totalCount: res.total_ctnft_count,
        });
      }
    }
  };

  /* Api Calling */
  useEffect(() => {
    //getUserProductList();
    fetchCtnftList();
  }, []);

  useEffect(() => {
    // const partner_type = userData?.partner_type;
    // const partner_state = userData?.partner_state;

    // if (partner_type !== "producer") {
    //   history.push(`/edit-profile`);
    // }
    const partner_type = localStorage.getItem('userType');

    const partner_state = "approved"; // fetch this state from the backend first to proceed with the dashboard rendering


    //use this part to check for proper entries of supplier/producer

    if (partner_state !== "approved" && partner_type === "producer") {
      setModalOpen(true);
      setTimeout(() => {
        history.push(`/edit-profile`);
        setModalOpen(false);
      }, 5000);
    }
  }, []);
  const handleClose = () => {
    setModalOpen(false);
    history.push(`/edit-profile`);
  };

  const handleDeList = async (id) => {
    console.log(id);

    let certificate = [];
    certificate.push(id.certificate_id);
    let body = {
      certificates: certificate,
    };
    const res = await deListBlockChainMetaData(body);
    setLoading(
      true,
      "Please accept the transaction on your Phaeton Wallet for Delist"
    );

    if (res.status) {
      const resp = await createResellTransaction(res?.data);

      if (resp.status) {
        setLoading(true, "Creating Delisting Request");
        addToast({
          message: resp?.message,
          type: "success",
          duration: 3000,
        });

        const response = await createDelist(body);
        if (response.status) {
          addToast({
            message: "Certificate Delisted Successfull.",
            type: "success",
            duration: 3000,
          });
          // addToast({
          //   message: response?.message,
          //   type: "success",
          //   duration: 3000,
          // });
          let infos = {
            txn_type: "withdraw_relist",
            object_id: response?.object_id,
            transaction: resp?.transactionHash,
          };
          const result = await saveTransactionHash(infos);
          setLoading(true, "Saving Transaction Hash");
          if (result.status) {
            history.push("/resell");
            addToast({
              message: result?.message,
              type: "success",
              duration: 3000,
            });
            setLoading(false);
          }
        } else {
          addToast({
            message: response?.message,
            type: "error",
            duration: 3000,
          });
          setLoading(false);
        }
      } else {
        addToast({
          message: resp?.message,
          type: "error",
          duration: 3000,
        });
        setLoading(false);
      }
    } else {
      addToast({
        message: res?.message,
        type: "error",
        duration: 3000,
      });
      setLoading(false);
    }
  };

  const handleDocPagination = (page) => {
    if (page) {
      let startPage = page * itemsPerPage;
      let endPage = page + 1 * itemsPerPage;
      let nextPrevData = [];
      allCertificates.forEach((data, index) => {
        if (index + 1 >= startPage && index + 1 < endPage) {
          nextPrevData.push(data);
        }
      });
      setReformatCertificates(nextPrevData);
      //window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  const handlePagination = (page) => {
    if (page) {
      // window.scrollTo({ top: 0 });
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    if (currentPage) {
      fetchCtnftList();
    }
  }, [currentPage]);

  const handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  const handleEdit = (dapp) => {
    dispatch(setDapp(dapp.dapp_id));
    history.push("/edit-ctnft");
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const shortHeader = (name) => {
    if (name.length > 8) {
      let shortName = name?.substr(0, 8);
      return shortName + "...";
    } else {
      return name;
    }
  };
  const mintNft = (accu) => {
    history.push(`/mintCNFT/${accu}`);
  }

  useEffect(() => {
    getListedNftFunc()
  }, [])
  const getListedNftFunc = async () => {
    let body = {}
    body.user_id = localStorage.getItem("userId")
    const listedNftRes = await getListedNft(body)
    console.log("listedNftRes", listedNftRes)
    setListedNfts(listedNftRes.data)
  }
  const viewCTNFT = (e) => {
    history.push(`/viewctnft/${e}`)
  }
  return (
    <div>
      <Subheader subpagi={subPagination} />
      <Container className='bg-white' fluid>
        <Row>
          <Col lg={12}>

            <div className='table-resposive tableBox tablewithSubHeader'>
              <table>
                <thead>
                  <tr>
                    <td>Sr. No</td>
                    <td>Project Title</td>
                    <td>Txn. Hash</td>
                    <td>Registration No.</td>
                    <td>Asset ID</td>
                    <td>Date </td>
                    <td>NFT cost</td>
                    <td>Action</td>
                  </tr>
                </thead>
                <tbody>
                  {listedNfts && listedNfts?.length > 0 ? (
                    listedNfts?.map((item, i) => {
                      return (<>
                        <tr>
                          {/* <td>{res?.accu}</td>
                                    <td>{res?.authority_id}</td>
                                    <td>{res?.price}</td>
                                    <td>{capitalizeFirstLetter(res?.state)}</td> */}
                          <td>{i + 1}</td>
                          <td>{item?.name}</td>
                          <td> <a href={`https://www.oklink.com/amoy/tx/${item?.token_hash}`} target="_blank" rel="noreferrer">{item?.token_hash.slice(0, 5) + "..." + item?.token_hash.slice(item?.token_hash.length - 5, item?.token_hash.length)}</a></td>
                          <td>{item?.certificate_id}</td>
                          <td>{item?.accu_number}</td>
                          <td>{item?.create_date && moment(item?.create_date).format('DD-MM-YYYY')} </td>
                          <td>{item?.price}</td>
                          <td>
                            {viewMore &&
                              width <= 768 &&
                              cardOpen === i ? (
                              <></>
                            ) : (
                              <div className="btnbox">

                                {/* <button className="btn py-0"
                                  onClick={() => viewCTNFT(item?.id)}>
                                  <i class="fa-regular fa-eye"></i>
                                </button> */}
                                <Link className="btn py-0" to={{pathname:`/viewctnft/${item?.id}`, state:{"view":"Listed"}}}><i class="fa-regular fa-eye"></i>
                                </Link>
                                {/* <button
                                              onClick={() => handleDeList(res)}>
                                              De-List
                                            </button> */}
                              </div>
                            )}
                          </td>
                        </tr>

                      </>)
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>No Records Found.</td>
                    </tr>
                  )}

                </tbody>
              </table>
            </div>


          </Col>
          <Col className="text-end" lg={12}>
            {listedNfts && paging?.totalCount > 0 ? (
              <Pagination
                itemsCount={paging?.totalCount || 0}
                itemsPerPage={paging?.count || 9}
                currentPage={currentPage}
                setCurrentPage={(e) => handlePagination(e)}
                alwaysShown={false}
              />
            ) : null}
          </Col>
        </Row>
      </Container>

      {modalOpen && (
        <ProducerStatus
          handleClose={handleClose}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  );
};

export default memo(ApprovedCTNFT);
