// import ProfileUpdate from "components/modal/profileUpdate";
import React, { useEffect, useState } from "react";
import { ProgressBar, Row } from "react-bootstrap";
import {
  downloadProfileDocuments,
  getRequiredDocuments,
  getWebContent,
  UploadDocument,
} from "../../../action/Api.action";
import CustomLoader from "../../../common/customLoader";
import Loader from "../../../common/loader";
import { useToast } from "../../../context/Toast/useToast";
import Url from "../../../utils/appUrls";
import { StorageHelper } from "../../../utils/StorageHelper";
import styles from "./styles.module.scss";
import axios from "axios";
import fileDownload from "js-file-download";
import BASE_URL from "../../../utils/appUrls";

const AddDocuments = ({ changeStep }) => {
  const userDetail = StorageHelper.getUserInfo();
  const [UserId, AccessToken] = [
    parseInt(userDetail.user_id),
    userDetail.access_token,
  ];
  const { addToast } = useToast();
  const [DownloadedDocuments, setDownloadedDocuments] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [UploadDocuments, setUploadDocuments] = useState([]);
  const fetchRequiredDocuments = async () => {
    const result = await getRequiredDocuments();
    setUploadDocuments(result.data);
  };
  const fetchIssuerDocuments = async () => {
    setIsLoading(true);
    const result = await downloadProfileDocuments(UserId);
    setDownloadedDocuments(result.data);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchRequiredDocuments();
    fetchIssuerDocuments();
  }, []);
  const [ModalOpen, setModalOpen] = useState(false);
  const [IsSaving, setIsSaving] = useState(false);
  const uploadDocuments = async (docs) => {
    setIsSaving(true);
    Promise.all(
      docs.map(async (doc) => {
        if (doc.file) {
          const result = await UploadDocument({
            doc_type_id: doc.doc_id,
            doc_file: doc.file,
            file_name: doc.doc_name,
            user_id: UserId,
            auth_access_token: AccessToken,
            doc_id: doc.document_id,
          });
          if (result.data.status) {
            return { ...doc };
          } else return doc;
        } else return doc;
      })
    ).then((res) => {
      setUploadDocuments(res);
      const uploads = res.filter((r) => r.uploaded).length;

      if (uploads > 0) {
        addToast({
          message: uploads + " documents updated successfully.",
          type: "success",
        });
        changeStep(2);
      } else {
        addToast({ message: "Document failed to update.", type: "error" });
      }
      fetchIssuerDocuments();
    });
    if (DownloadedDocuments.length > docs.length) {
      changeStep(2);
    }
    setIsSaving(false);
  };

  const handleDownload = (url, filename) => {
    axios
      .get(BASE_URL.HOST + url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });
  };

  return (
   <Row>
     <h4 className="my-4">Upload KYC Documents</h4>
    <div className="quoteRequestSec ">
    <div className="moreInfoSec formContent  boxShaddow burger">
     
    <div className={styles.AddDocuments}>
      {/* <div className={styles.MainTitle}>Upload KYC Documents</div> */}
      {IsLoading && (
        <div className={styles.LoadingRow}>
          <CustomLoader />
        </div>
      )}
      <div className={styles.Row}>
        {UploadDocuments.map((doc) => {
          const doc_link = DownloadedDocuments.find(
            (d) => d.doc_type_id === doc.doc_id
          );
          let link = undefined;
          if (doc_link) {
            getWebContent(doc_link.document_url, UserId, AccessToken).then(
              (r) => {
                link = URL.createObjectURL(r.data);
              }
            );
          }
          console.log("WORKININ",localStorage.getItem('userType'))
          return (
            
            <div className={styles.DocumentWrapper} key={doc.doc_id}>
              <div
                className={
                  !!doc.file && doc.file !== null
                    ? styles.TitleUploaded
                    : styles.Title
                }>
                {/* <i className="fa-solid fa-file"></i> */}
                {!!doc.file && doc.file !== null ? (
                  <>
                    <div className={styles.TitleText}>{doc.file.name}</div>
                    <i
                      className="fa-solid fa-xmark"
                      onClick={() => {
                        setUploadDocuments((docs) =>
                          docs.map((d) =>
                            d.doc_id === doc.doc_id
                              ? { ...d, file: undefined }
                              : d
                          )
                        );
                      }}></i>
                  </>
                ) : (
                  <>
                  <div className="selectImage position-relative">
                        
                        <label className="uploadassets cursor-pointer"
                                    for="imageUpload"
                                   >
                                    <img src={"/images/svg/UploadImage.svg"} height={25} width={25} alt="upload" />
                          <h3>Upload from computer</h3>
                                    <input
                                      type="file"
                                      className={styles.UploadFileButton}
                                      onChange={async (e) => {
                        setIsLoading(true)
                        if (
                          e.target.files !== null &&
                          e.target.files.length > 0 
                        ) {
                          const doc_file = e.target.files[0];
                          let validedSize = 20971520;
                          const allowedType = [
                            
                            "image/jpg",
                            "image/png",
                            "application/pdf",
                            "image/jpeg",
                            
                          ];
                          if (!allowedType.includes(doc_file?.type)) {
                            setIsLoading(false)
                            addToast({
                              message:
                                "You can only upload these formats jpg/png/pdf/jpeg.",
                              type: "error",
                              duration: 3000,
                            });
                            return false;
                          }

                          if (doc_file?.size < validedSize) {
                          } else {
                            addToast({
                              message:
                                "Please Choose a file that is less than 20 MB",
                              type: "error",
                              duration: 3000,
                            });
                            return false;
                          }
                          console.log({doc_type_id: doc.doc_id,
                            doc_file: doc_file,
                            file_name: doc.doc_name,
                            user_id: UserId,
                            auth_access_token: AccessToken,
                            doc_id: doc_link?.document_id,})
                          await UploadDocument({
                            doc_type_id: doc.doc_id,
                            doc_file: doc_file,
                            file_name: doc.doc_name,
                            user_id: UserId,
                            auth_access_token: AccessToken,
                            doc_id: doc_link?.document_id,
                            onProgressUpload(event) {
                              setUploadDocuments((docs) =>
                                docs.map((d) =>
                                  d.doc_id === doc.doc_id
                                    ? {
                                        ...d,
                                        uploaded: Math.round(
                                          (100 * event.loaded) / event.total
                                        ),
                                      }
                                      : d
                                  )
                                );
                              },
                            })
                              .then((res) => {
                                setUploadDocuments((docs) =>
                                  docs.map((d) =>
                                    d.doc_id === doc.doc_id
                                      ? {
                                        ...d,
                                        uploaded: undefined,
                                      }
                                      : d
                                  )
                                );
                                // console.log("res", res)
                                if (res.status) {
                                  addToast({
                                    message: "Document uploaded successfully.",
                                    type: "success",
                                  });
                                  fetchIssuerDocuments();
                                } else {
                                  addToast({
                                    message: "Document failed to upload.",
                                    type: "error",
                                  });
                                }
                              })
                              .catch((err) => {
                                console.log("er", err);
                                addToast({
                                  message: "Document failed to upload.",
                                  type: "error",
                                });
                              });
                          }

                          setIsLoading(false)
                        }}
                                    />
                                  </label>
                      </div>
                  <div hidden>
                  <label for="imageUpload" class={styles.UploadFileLabel}>Upload File      
                    <input
                      type="file"
                      className={styles.UploadFileButton}
                      onChange={async (e) => {
                        setIsLoading(true)
                        if (
                          e.target.files !== null &&
                          e.target.files.length > 0 
                        ) {
                          const doc_file = e.target.files[0];
                          let validedSize = 20971520;
                          const allowedType = [
                            
                            "image/jpg",
                            "image/png",
                            "application/pdf",
                            "image/jpeg",
                            
                          ];
                          if (!allowedType.includes(doc_file?.type)) {
                            setIsLoading(false)
                            addToast({
                              message:
                                "You can only upload these formats jpg/png/pdf/jpeg.",
                              type: "error",
                              duration: 3000,
                            });
                            return false;
                          }

                          if (doc_file?.size < validedSize) {
                          } else {
                            addToast({
                              message:
                                "Please Choose a file that is less than 20 MB",
                              type: "error",
                              duration: 3000,
                            });
                            return false;
                          }
                          console.log({doc_type_id: doc.doc_id,
                            doc_file: doc_file,
                            file_name: doc.doc_name,
                            user_id: UserId,
                            auth_access_token: AccessToken,
                            doc_id: doc_link?.document_id,})
                          await UploadDocument({
                            doc_type_id: doc.doc_id,
                            doc_file: doc_file,
                            file_name: doc.doc_name,
                            user_id: UserId,
                            auth_access_token: AccessToken,
                            doc_id: doc_link?.document_id,
                            onProgressUpload(event) {
                              setUploadDocuments((docs) =>
                                docs.map((d) =>
                                  d.doc_id === doc.doc_id
                                    ? {
                                        ...d,
                                        uploaded: Math.round(
                                          (100 * event.loaded) / event.total
                                        ),
                                      }
                                      : d
                                  )
                                );
                              },
                            })
                              .then((res) => {
                                setUploadDocuments((docs) =>
                                  docs.map((d) =>
                                    d.doc_id === doc.doc_id
                                      ? {
                                        ...d,
                                        uploaded: undefined,
                                      }
                                      : d
                                  )
                                );
                                // console.log("res", res)
                                if (res.status) {
                                  addToast({
                                    message: "Document uploaded successfully.",
                                    type: "success",
                                  });
                                  fetchIssuerDocuments();
                                } else {
                                  addToast({
                                    message: "Document failed to upload.",
                                    type: "error",
                                  });
                                }
                              })
                              .catch((err) => {
                                console.log("er", err);
                                addToast({
                                  message: "Document failed to upload.",
                                  type: "error",
                                });
                              });
                          }

                          setIsLoading(false)
                        }}
                      />
                    </label>
                    <i className="fa-solid fa-upload"></i>
                    </div>
                  </>
                )}
              </div>
              <div>
              <div className={styles.DocumentDisplay}>
                {doc_link && doc_link.file_type.includes("image") ? (
                  <img src={BASE_URL.HOST + doc_link.document_url} />
                ) : null}
                {doc_link && doc_link.file_type.includes("pdf") ? (
                  <embed
                    src={`${BASE_URL.HOST + doc_link.document_url}`}
                    type="application/pdf"
                    height="240"
                    width="100%">
                  </embed>
                ) : null}
              </div>
              
              <div className={styles.Name}>
                {/* {doc.doc_name}{" "} */}
                {localStorage.getItem('userType') == "producer" ? "Supplier Tax Document" : "Buyer Tax Document"}
                {doc_link && (
                  <div
                    className={styles.DownloadButton}
                    onClick={() => {
                      handleDownload(
                        doc_link.document_url,
                        doc_link.file_name
                      );
                    }}
                  >
                    ( Download )
                  </div>
                )}
                {!!doc.uploaded && (
                 <> <div className={styles.UploadStatus}>(Uploading...)</div></>
                )}
              </div>
              </div>
              {!!doc.uploaded && (
                <ProgressBar
                  now={doc.uploaded}
                  label={`${doc.uploaded}%`}
                  animated
                />
              )}
            </div>
          );
        })}
      </div>
      {/* <ProfileUpdate
        handleClose={() => {
          setModalOpen(false);
        }}
        modalOpen={ModalOpen}
        label={`${
          UploadDocuments.filter((d) => d.uploaded).length
        } documents updated successfully.`}
      /> */}
      {IsSaving && (
        <div className={styles.LoadingRow}>
          <b>Uploading Documents</b>
          <Loader />
        </div>
      )}
      <div className={styles.Row}>
        <div className={styles.Buttons}>
          <div
            className={'  secondaryBtn-outline submitBtn btn btn-primary'}
            onClick={(e) => {
              e.preventDefault();
              changeStep(0);
            }}>
            Previous
          </div>
          <div
            className={'  secondaryBtn   btn w-auto'}
            onClick={(e) => {
              // uploadDocuments(UploadDocuments);
              e.preventDefault();
              if (DownloadedDocuments.length >= UploadDocuments.length) {
                console.log("Test ashmin for")
                addToast({
                  message: "Documents uploaded successfully.",
                  type: "success",
                });
                changeStep(2);
              } else {
                addToast({
                  message: "Please upload all documents.",
                  type: "error",
                });
              }
            }}>
            {IsSaving ? <Loader /> : "Next"}
          </div>
          {/* <div
            className={styles.NavButton}
            onClick={(e) => {
              e.preventDefault();
              changeStep(2);
            }}
          >
            Next
          </div> */}
        </div>
      </div>
    </div>
   </div>
   </div>
   </Row>
   
  );
};

export default AddDocuments;
