import Steps from "./../../common/Steps/index";
import ProfileComplete from "./profile/ProfileComplete";
import { STEPS } from "../../constant/constant";
import React, { useState } from "react";
import AddDocuments from "./AddDocuments";
import AddInformation from "./AddInformation";
import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import KycStatus from './KycDialog'
const ProducerProfile = () => {
  const [CurrentStep, setCurrentStep] = useState(0);
  const [StatusModalOpen, setStatusModalOpen] = useState(false);
 
  const changeStep = (s) => {
    setCurrentStep(s);
  };
  const history = useHistory();
  const returnToHomePage = () => {
    // localStorage.clear();
    history.push("/");
  };
  console.log("Profile triggered")
  console.log(localStorage.getItem('userId'))
  
  const getCurrentTab = (step) => {
    switch (step) {
      case 0:
        return <AddInformation changeStep={changeStep} />;
      case 1:
        return <AddDocuments changeStep={changeStep} />;

      // goNextStep = {() => {
      //   changeStep(2)
      // }} />
      case 2:
        return <ProfileComplete finishUpdate={returnToHomePage} />;
    }
  };
  const statusdialog = () => {
    setStatusModalOpen(!StatusModalOpen);
    // console.log(walletModalOpen);
    // handleWalletConnect();
  };
  return (
   
   <>
     { CurrentStep != 2  &&  <Steps
        steps={STEPS}
        currentStep={CurrentStep}
        // onClick={(s) => {
        //   setCurrentStep(s);
        // }}
      />}
    
       <div className={styles.IssuerProfile}>
      {getCurrentTab(CurrentStep)}
    </div>
    <KycStatus statusshow={StatusModalOpen} StatusFunc={statusdialog} ></KycStatus>
   </>
  );
};

export default ProducerProfile;
