import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Container, Row, Col, Navbar, Button } from 'react-bootstrap';
// import { forgotPasswordAction } from "../action/Api.action";
import {forgotPassword} from "../action/walletApiAction";
import { useToast } from '../context/Toast/useToast';

const ForgotPassword = ({ history }) => {

	const { register, handleSubmit, watch, formState: { errors } } = useForm();
	const [emailAddress, setEmail] = useState('');
	const { addToast } = useToast();
	const [error, setError] = useState('');

	const onSubmit = async data => {

		let res = await forgotPassword({ email: emailAddress });
		console.log("checkin response",res.data.status)

		if (res.data.status === 200) {
			addToast({
				message: "Link has been send to Email",
				type: "success",
				duration: 3000,
			})
			
		} else {
			addToast({
				message: res.data.message,
				type: "Error",
				duration: 3000,
			})
			setError(res.msg);
		}
	};


	return (
		<Container>

			<div className="loginForm burger">
				<Row className="align-items-center justify-content-center">
					<Col xs={12} sm={9} md={7} lg={5}>

						<form className="formContent boxShaddow" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
							<div className="heading">
								<h2>Forgot Password</h2>
							</div>

							<div className="mb-3 form-group">
								<input type="email" className="form-control" placeholder="Please enter your registered email for recover your password." defaultValue="" {...register("email", { required: true })} onChange={e => { setEmail(e.target.value); setError('') }} />
								{errors.email && <span className="error">Email is required.</span>}
								<small className="error">{error}</small>
							</div>

							<div className="loginFrgtPwdBtns">
								<Button className="secondaryBtn btn" type="submit">Confirm Email</Button>
								<Navbar.Text className='text-center'>
									<Link to="/login" className="forgotPwd mx-auto">Go to Login</Link>
								</Navbar.Text>
							</div>
						</form>


					</Col>
				</Row>
			</div>

		</Container>
	);
};

export default ForgotPassword;

