import React, { useEffect, useState } from "react";
import { Container, Nav, Button, Figure, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProtectedRoute from "../common/ProtectedRoute";
import { StorageHelper } from "../utils/StorageHelper";
import { useLoader } from "../context/Loader/useLoader";
import { setFooter, setPrevious } from "../slices/home";
import { NAME } from "../constant/constant";
import { fetchProfilePicture } from "../action/Api.action";
const SubHeader = (props) => {
  const { setLoading } = useLoader();
  const [pages, setpages] = useState()
  const [profilePic,setProfilePic] = useState("/images/svg/profilePhoto.svg")
  const { subpagi } = props;
  useEffect(()=>{
    setpages(props.subpagi)
  },[props])
  const dispatch = useDispatch();
  useEffect(async()=>{
    //fetch profile image
    const profileImg = await fetchProfilePicture({user_id : localStorage.getItem('userId')})
    
    if(profileImg.status)
    setProfilePic(profileImg.imageData)
  })
  useEffect(()=>{
    console.log('deepakaka',props);
  },[props])
  /* Api Calling */
  useEffect(() => {
    setLoading(false);
    dispatch(setFooter(false));
  }, []);
  const userData = useSelector((state) => state?.auth?.profileData);
  const shortIssuerName = (issuerName) => {
    if (issuerName && issuerName.length > 10) {
      return issuerName.substr(0, 10) + "...";
    } else {
      return issuerName;
    }
  };
  //const partner_type = userData?.partner_type;
  const userDetail = StorageHelper.getUserInfo();
  const [partner_type] = [userDetail.partner_type];
  return (
    <div className="subHeader">
      <ProtectedRoute>
        <Container fluid>
        <Row>
          <Col lg={2}>
            <div className="position-relative w-100">
            <div className="clientInfo">
            <Figure>
              <Figure.Image
                width={144}
                height={144}
                alt="Profile"
                src={profilePic}
              />
            </Figure>
          
            <h5 className="name">{localStorage.getItem('userName') || 'User'}</h5>
            <h6 className="companyName">Green Energy Inc.</h6>
            <span>
              {localStorage.getItem('userType') === "producer" // {userData?.partner_type === "producer" ? (
                ? NAME.supplier
                : NAME.buyer}
            </span>
          </div>
            </div>
          </Col>
          <Col lg={10}>
          <ul className="paginationNewSubhead">
            
            <li>{pages?.page}</li>
            <li>{pages?.pagename}</li>


          </ul>
          <div className="subNav" onClick={() => dispatch(setPrevious(false))}>
            <Nav>
              {localStorage.getItem('userType') === "producer" && localStorage.getItem('userState') === "approved" ? ( // {partner_type === "producer" ? (
               <>
                <Nav.Link as={Link} to="/dashboard">
                    Dashboard
                  </Nav.Link>
                  <Nav.Link  className="submenu">
                  Activities
                  <div className="submenuinn">
                  <ul>
                    <li><Nav.Link as={Link} to={{pathname:"offermade", state:{page:"Activities", pagename:'Offer Made'}}}>Offer Made</Nav.Link></li>
                    <li><Nav.Link as={Link} to={{pathname:"offermade", state:{page:"Activities", pagename:'Offer Received'}}}>Offer Received</Nav.Link></li>

                  </ul>
                  </div>
                  </Nav.Link>
                  <Nav.Link  className="submenu" as={Link}>
                  NFT's
                  <div className="submenuinn">
                  <ul>
                    <li><Nav.Link as={Link} to={{pathname:"/createCNFT",  state:{page:"NFT's", pagename:'Create NFT’s'}}}>Create NFT’s</Nav.Link></li>
                    <li><Nav.Link as={Link} to={{pathname:"/pendingCNFT", state:{page:"NFT's", pagename:'Waiting for approval'}}}>Waiting for approval</Nav.Link></li>
                    <li><Nav.Link as={Link} to={{pathname:"/listingCNFT", state:{page:"NFT's", pagename:'Approved Certificate'}}}>Approved Certificate</Nav.Link></li>
                    <li><Nav.Link as={Link} to={{pathname:"/approvedCNFT", state:{page:"NFT's", pagename:'Listed NFT’s'}}}>Listed NFT’s</Nav.Link></li>
                    <li><Nav.Link as={Link} to={{pathname:"/soldCNFT",  state:{page:"NFT's", pagename:'Sold NFT’s'}}}>Sold NFT’s</Nav.Link></li>
                    <li><Nav.Link as={Link} to={{pathname:"/boughtCNFT",  state:{page:"NFT's", pagename:'Bought NFT’s'}}}>Bought NFT’s</Nav.Link></li>
                    <li><Nav.Link as={Link} to={{pathname:"/retireCNFT", state:{page:"NFT's", pagename:'Retired NFT'}}}>Retired NFT</Nav.Link></li>
                    



                  </ul>
                  </div>
                  </Nav.Link>
                  <Nav.Link as={Link}>
                  Transactions
                  </Nav.Link>
                  {/* <Nav.Link as={Link} to="/createCNFT">
                    Create {NAME.certificate}
                  </Nav.Link>
                  <Nav.Link as={Link} to="/pendingCNFT">
                    Pending {NAME.certificate}
                  </Nav.Link>
                  <Nav.Link as={Link} to="/listingCNFT">
                    Listed {NAME.certificate}
                  </Nav.Link>
                  <Nav.Link as={Link} to="/soldCNFT">
                    Sold {NAME.certificate}
                  </Nav.Link> */}
                  {/* <Nav.Link as={Link} to="/de-listingCTNFT">
                    Delist {NAME.certificate}
                  </Nav.Link> */} 
                </>
              ) : null}
              {localStorage.getItem('userType') === "polluter" || partner_type === "" ? (
                <>
                  <Nav.Link as={Link} to="dashboard">
                    Dashboards
                  </Nav.Link>
                  <Nav.Link as={Link} to="my-collection">
                    My Collection
                  </Nav.Link>
                  <Nav.Link as={Link} to="/my-orders">
                    My Order
                  </Nav.Link>
                  <Nav.Link as={Link} to="/resell">
                    Re-Sell
                  </Nav.Link>
                  {/* <Nav.Link as={Link} to="/withdraw">
                Withdraw
              </Nav.Link> */}
                  <Nav.Link as={Link} to="/burn-orders">
                    Burn Order
                  </Nav.Link>
                </>
              ) : null}
            </Nav>
            {/* <Link to="/edit-profile" className="active">
              <Button
                className="editPrfl"
                variant="primary"
                type="button">
                <i className="fa fa-edit" /> Edit Profile
              </Button>
            </Link> */}
          </div>
          </Col>

        </Row>
         
          
        </Container>
      </ProtectedRoute>
    </div>
  );
};

export default SubHeader;
