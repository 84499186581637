import { Row, Col, Button } from 'react-bootstrap';
import BASE_URL from "../../utils/appUrls";
const FeaturesSec = ({ data }) => {
    return (
        <div className="featuresSec burger">
            <Row>
                <Col md={4} lg={{ span: 3, offset: 1}}>
                    <div className="featuresDetails">
                        <div dangerouslySetInnerHTML={{ __html: data?.title }} />
                        <p dangerouslySetInnerHTML={{ __html: data?.banner_text }} ></p>
                        <Button variant="" className="primaryBtn">View Features</Button>
                    </div>
                </Col>

                <Col lg={1} className="borderCntr"> </Col>

                <Col md={8} lg={7} className="brdrLft">
                    <Row xs={2} sm={3}>
                    {data?.banner_image?.length > 0 &&
                        data?.banner_image?.map((info, index) => (
                        <Col key={index}>
                            <div className="featuresContent">
                                <img src={BASE_URL.HOST + info.image_url} alt="register-icon" />
                                <p dangerouslySetInnerHTML={{ __html: info?.heading }} ></p>
                            </div>
                        </Col>
                    ))}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default FeaturesSec;
