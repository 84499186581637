import Steps from "../common/Steps/index";
import { CREATESTEPS } from "../constant/constant";
import React, { useState, useEffect } from "react";
import AddDocuments from "../component/CreateCTNF/AddDocument";
import AddInformation from "../component/CreateCTNF/AddInformation";
import SubmitForApproval from "../component/CreateCTNF/SubmitForApproval";
// import styles from "./styles.module.scss";
import { useHistory } from "react-router-dom";
import SubHeader from "../component/Subheader";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ProducerStatus from "../common/modal/producerStatus";
import { certificateReqDocument } from "../action/Api.action";
import { setDapp } from "../slices/home";
import { useLocation } from 'react-router-dom';

const CreateCTNFT = () => {
  const { state } = useLocation();
  const userData = useSelector((state) => state?.auth?.profileData);
  const getDapp = useSelector((state) => state?.home?.dapp);
  const previousCheck = useSelector((state) => state?.home?.previous);
  const [CurrentStep, setCurrentStep] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [subPagination, SetPagination] = useState()
  
  useEffect(()=>{
    if(!localStorage.getItem('userId'))
    history.push('/')
    SetPagination(state)
  },[state])

  const history = useHistory();
  const [dappData, setDappData] = useState();
  const dispatch = useDispatch();
  const changeStep = (s) => {
    setCurrentStep(s);
   // console.log(currentStep)
  };
  // Deepak change
  const getCurrentTab = (step) => {
    switch (step) {
      case 0:
        // const page = {parent:"NFT's", child:'Add Information'}
        // setpagination(page)
        return (
          <AddInformation
            changeStep={changeStep}
            editDapp={dappData ? dappData : false}
          />
        );
      case 1:
        return (
          <AddDocuments
            changeStep={changeStep}
            editDapp={dappData ? dappData : false}
          />
        );

      // goNextStep = {() => {
      //   changeStep(2)
      // }} />
      case 2:
        return (
          <SubmitForApproval
            editDapp={dappData ? dappData : false}
            finishUpdate={() => {
              history.push("/dashboard");
              //history.push("/profile/update");
            }}
          />
        );
    }
  };
  useEffect(async () => {
    const partner_type = userData?.partner_type;
    const partner_state = userData?.partner_state;
    // console.log("userData", userData);
    if (partner_type !== "producer") {
      //history.push(`/edit-profile`);
    }
    if (partner_state !== "approved" && partner_type === "producer") {
      setModalOpen(true);
      setTimeout(() => {
        //history.push(`/edit-profile`);
        setModalOpen(false);
      }, 5000);
    }
    if (getDapp && window.location.pathname == "/edit-ctnft") {
      setCurrentStep(1);
      const res = await certificateReqDocument(getDapp);
      setDappData(res);
    } else {
      setCurrentStep(0);
    }
    // dispatch(setDapp(""));
  }, []);
  useEffect(async () => {
    if (getDapp && previousCheck) {
      const res = await certificateReqDocument(getDapp);
      setDappData(res);
    }
  }, [CurrentStep]);
  const handleClose = () => {
    setModalOpen(false);
    history.push(`/edit-profile`);
  };

  return (
    // <div className={styles.IssuerProfile}>
    <div>
      <SubHeader subpagi={subPagination} />
      <Container>
        <Steps
          steps={CREATESTEPS}
          currentStep={CurrentStep}
          /*onClick={(s) => {
            setCurrentStep(s);
          }}*/
        />
        {getCurrentTab(CurrentStep)}
      </Container>
      {modalOpen && (
        <ProducerStatus
          handleClose={handleClose}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
    </div>
  );
};

export default CreateCTNFT;
