import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SubHeader from "../Subheader";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Input } from "@material-ui/core";
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { getParticularListing, createMintnftAction, createSalenftAction, updateMintedStatusAction } from "../../action/walletApiAction";
import { useToast } from "../../context/Toast/useToast";
import { convertCurrency } from "../../utils/convertCurrency";
import Web3Intraction from "../../utils/web3Intraction";
import { current } from "@reduxjs/toolkit";
import StatusDialog from "./StatusDialog";
function MintNFT(props) {
  const history = useHistory()
  const [minttype, setMinttype] = useState('fixed')
  const [startDate, setStartDate] = useState(new Date());
  const [price, setPrice] = useState(0);
  const [convertedPrice, setConvertedPrice] = useState(0);
  const [symbol, setSymbol] = useState("MATIC");
  const [currency, setCurrency] = useState("USD")
  const [walletModalOpen, setWalletModalOpen] = useState(false);
  const [flagForModal, setFlagForModal] = useState("Minting")
  const [mintedError, setMintdError] = useState(false)
  const [disablesubmit, setDisablesubmit] = useState(false);
  const [auction_length,setAuction_length] =useState(7);
  const [arrayauction,setArrayauction] =useState([7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]);
  const walletAddress = localStorage.getItem("wallet_address");
  const privateKey = localStorage.getItem("private_key");

  const [fields, setFields] = useState({
    name: "",
    price: 0,
    current_price: 0,
    address: "",
    registry: "",
    project_type: "",
    total_credit: 0,
    saleType: minttype,
    creator_wallet: "",
    currentowner_wallet: "",
    certificate_id: "",
    accu_number: "",
    issale_active: false,
    isburn: false,
    isFreeze: false,
    image_url: "",
    discount: false,
    description: "",
    blockchain: "polygon",
    timezone: "",
    user_id: 0,
    pin: "",
    city: "",
    country: "",
    currency:""
  });
  const mintType = (e) => {
    if (minttype !== e) {
      setConvertedPrice(0)
      setPrice(0)
    }
    setMinttype(e)
  }

  const params = useParams()
  const { addToast } = useToast();
  const [nftDetails, setNftDetails] = useState({})
  const callGetParticularListing = async () => {
    let body = {}

    body.accu = params.accu
    const resp = await getParticularListing(body);
    if (resp.status === "success") {
      console.log("res", resp)
      let res = resp.data.nftDetails;
      // console.log("nftDetails",res)
      setFields((p) => ({
        ...p,
        name: res?.name,
        address: res?.address,
        registry: res?.issuing_body,
        project_type: res?.project_type,
        saleType: minttype,
        creator_wallet: walletAddress,
        currentowner_wallet: walletAddress,
        certificate_id: res?.certificate_id,
        accu_number: "" + res?.accu,
        create_date: res?.create_date,
        description: res?.description,
        total_credit:res?.credits,
        user_id: res?.user_id,
        id: res?.id,
        pin: "" + res?.postal_code,
        city: res?.city,
        state: res?.state,
        country: res?.country,
        image_url:"",
        currency:res?.currency
      })
      );
      setNftDetails(resp.data.nftDetails)
    } else {
      addToast({
        message: resp.message,
        type: "success",
      });
    }
  }

  useEffect(() => {
    callGetParticularListing()
    // getCurrency("MATIC", "USD")

  }, [params])
  useEffect(() => {
    console.log("fields", fields);
    // getCurrency("MATIC", "USD")

  }, [fields])


  const getCurrency = async (symbol = "MATIC", currency = "USD",) => {
    setCurrency(currency);
    const res = await convertCurrency(symbol, currency);

    if (price !== null) {
      for (const key in res) {
        setConvertedPrice(res[key] * price)
      }
    }
  }

  useEffect(() => {
    getCurrency(symbol, currency);

  }, [price, symbol,])

  useEffect(() => {
    setFields((pre) => ({ ...pre, price: price, current_price: price }))
  }, [price, symbol, convertedPrice])

  const onSubmit = async (evt) => {

    evt.preventDefault();
    setDisablesubmit(true)
    if((fields?.price)<=0){
      setDisablesubmit(false)
      return addToast({
        message: "Price should be greater than zero.",
        type: "error",
      });
    }
    setWalletModalOpen(!walletModalOpen);

    try {
      
      const web3intraction = new Web3Intraction(
        privateKey
      );

      // console.log("hhh", web3intraction)
      
        let data = {
          price: fields?.price,
          projectType: fields?.project_type,
          registry: fields?.registry,
          name: fields?.name,
          creator: walletAddress,
          hash: fields?.accu_number,
        }
  
        await web3intraction.mintNFT(
          walletAddress,
          data
        ).then(async (res) => {
  
  
          let datanft = {
            ...fields, token_hash: res.hash, token_id: res.token
          }
          if (res.success) {
            // console.warn("fields response then ", datanft)
            let saledata = {};
            await createMintnftAction(datanft).then(async (res1) => {
              // console.warn("create mintnft response then res1", res1)
              saledata.nft_id = res1.data.id;
              setFlagForModal("Market")
              addToast({
                message: "Your NFT has minted successfully!",
                type: "success",
              });
  
  
            })
  
  
            saledata.sellPrice = datanft.price;
            saledata.tokenId = datanft.token_id;
            if(minttype == "fixed"){
            
            await web3intraction.nftSaleActivate(walletAddress, saledata).then(async (res2) => {
              // console.log("res2 sale ", res2)
             var minted1 = false
              let mintedObj ={
                accu_number: fields?.accu_number,
                minted:true
              }
              await updateMintedStatusAction(mintedObj).then(async (resminted) => {
              console.log("resminted", resminted)
              if(resminted.status==="Error"){
                minted1=true
                throw new Error("Erron in Minting Status");
              
            
                // return addToast({
                //   message: `Error in updateing minted status ${resminted.message}`,
                //   type: "error",
                // });
              }
                // console.warn("create nft sale response then res3", res11)
                //  sahil 
                // setWalletModalOpen(!walletModalOpen);
                // setFlagForModal("Market")
                // addToast({
                //   message: "Your NFT sale has been active successfully!",
                //   type: "success",
                // });
                // console.log("sale data", datasalenft)
              }).catch((err)=>{
                console.log("errminted", err)
                return addToast({
                  message: `Error in updateing minted status ${err.message}`,
                  type: "error",
                });
              })
              let datasalenft = {
                token_hash: res2.hash,
                token_id: saledata.tokenId,
                nft_id: saledata.nft_id,
                price: saledata.sellPrice,
                user_id:fields.user_id,
                start_timestamp: "",
                end_timestamp: "",
                blockchain: "ploygon",
                status: true,
                no_of_bids: "0",
                sale_status: true,
                sell_type: minttype,
                current_bidder: ""
              }
              // update minted status
              if(minted1===true) return
  
            // update minted status end
            
              await createSalenftAction(datasalenft).then(async (res11) => {
                console.log("insidecreateNft")
                // console.warn("create nft sale response then res3", res11)
                //  sahil 
                // setWalletModalOpen(!walletModalOpen);
                // setFlagForModal("Market")
        
                addToast({
                  message: "Your NFT sale has been activated successfully!",
                  type: "success",
                });
  
                // console.log("sale data", datasalenft)
              })
            })

            // end of fixed nft
          }else{

            //start of auction
            saledata.auctionDuration = auction_length;
            await web3intraction.startAuctionActivate(walletAddress, saledata).then(async (res12) => {
              // console.log("res2 sale ", res2)
             var minted1 = false
              let mintedObj ={
                accu_number: fields?.accu_number,
                minted:true
              }
              await updateMintedStatusAction(mintedObj).then(async (resminted) => {
              console.log("resminted", resminted)
              if(resminted.status==="Error"){
                minted1=true
                throw new Error("Erron in Minting Status");
              
            
                // return addToast({
                //   message: `Error in updateing minted status ${resminted.message}`,
                //   type: "error",
                // });
              }
                // console.warn("create nft sale response then res3", res11)
                //  sahil 
                // setWalletModalOpen(!walletModalOpen);
                // setFlagForModal("Market")
                // addToast({
                //   message: "Your NFT sale has been active successfully!",
                //   type: "success",
                // });
                // console.log("sale data", datasalenft)
              }).catch((err)=>{
                console.log("errminted", err)
                return addToast({
                  message: `Error in updateing minted status ${err.message}`,
                  type: "error",
                });
              })
              let datasalenft = {
                token_hash: res12.hash,
                token_id: saledata.tokenId,
                nft_id: saledata.nft_id,
                price: saledata.sellPrice,
                user_id:fields.user_id,
                start_timestamp: "",
                end_timestamp: "",
                blockchain: "ploygon",
                status: true,
                no_of_bids: "0",
                sale_status: true,
                sell_type: minttype,
                current_bidder: ""
              }
              // update minted status
              if(minted1===true) return
  
            // update minted status end
            
              await createSalenftAction(datasalenft).then(async (res11) => {
                console.log("insidecreateNft")
                // console.warn("create nft sale response then res3", res11)
                //  sahil 
                // setWalletModalOpen(!walletModalOpen);
                // setFlagForModal("Market")
        
                addToast({
                  message: "Your NFT Auction has been activated successfully!",
                  type: "success",
                });
  
                // console.log("sale data", datasalenft)
              })
            })

           // end of auction code
          }
          } else {
            setWalletModalOpen(false);
            setDisablesubmit(false)
            return addToast({
              message: res.message,
              type: "error",
            });
          }
  
  
  
        }).then((res_final) => {
          setWalletModalOpen(false)
          history.push("/approvedCNFT")
          // console.log("final", res_final)
        })

    
     
    
  
    } catch (err) {
      setWalletModalOpen(false);
      setDisablesubmit(false)
      console.log("nft page error", err)
      return addToast({
        message: err.message,
        type: "error",
      });
    }


  }

  const selectWalletConnect = () => {
    setWalletModalOpen(!walletModalOpen);
    // console.log(walletModalOpen);
    // handleWalletConnect();

  };

  const flagForModalFunc = () => {
    setFlagForModal("Market")
  }

  return (
    <>
      <SubHeader />

      <Container fluid>
        <div className="quoteRequestSec CreateCTNFTSec burger">
          <Container>
            <Row className="justify-content-md-center">
              <Col sm={12}>
                <Form
                  className="moreInfoSec formContent mintNFT boxShaddow"
                  noValidate
                // validated={validated}
                // onSubmit={(e) => handleSubmit(e)}
                // onSubmit={(e) => handleSubmit2(e)}
                >
                  <h1> Listing</h1>
                  <h2>Assets ID : <span>{nftDetails?.accu}</span>  </h2>
                  {/* <h3>Certificates: 10 CTNFT </h3> */}
                  <hr className="w-100" />
                  <div className="mintpriceBox">
                    <h1>Type</h1>
                    <Row>
                      <Col lg={6}>
                        <div className="choosemintBox">
                          <button type="button" className={minttype === 'fixed' ? 'active' : ''} onClick={() => mintType('fixed')}> <div className="circle"></div> Fixed Price</button>
                          <button type="button" className={minttype === 'auction' ? 'active' : ''} onClick={() => mintType('auction')}><div className="circle"></div> Time Auction</button>
                        </div>
                      </Col>

                    </Row>
                    <h1>Price</h1>
                    {minttype === 'fixed' && (
                      <>
                        <Row>

                          <Col lg={6}>
                            <div className="enterAmountbox">
                              <div className="mainePrice">
                                <Form.Control
                                  type="text"
                                  id="proj_title"
                                  placeholder="Price"
                                  name="project_title"
                                  // onChange={(e) =>
                                  //   formHandler(e, "erf_registration_number")
                                  // }
                                  // pattern="/^\S*$/"
                                  onChange={(e) => { setPrice(e.target.value !== "" ? e.target.value : 0) }}
                                  minLength="2"
                                  maxLength="64"
                                  autoComplete="off"
                                />
                                <img src="/images/svg/usdcIcon.svg" alt="" />
                              </div>
                              <div>
                                <img src="/images/svg/exchangeArrow.svg" alt="" />

                              </div>
                              <div className="d-flex align-item-center gap-2">
                                <Form.Control
                                  type="text" readOnly
                                  id="proj_title"
                                  placeholder="Price "
                                  name="project_title"
                                  // onChange={(e) =>
                                  //   formHandler(e, "erf_registration_number")
                                  // }
                                  // pattern="/^\S*$/"
                                  value={Number(convertedPrice).toFixed(5)}

                                  minLength="2"
                                  maxLength="64"
                                  autoComplete="off"
                                />
                                <Form.Control
                                  as="select"
                                  className="form-select"
                                  id="proj_type"
                                  name="project_type"
                                  onChange={(e) => getCurrency("MATIC", e.target.value)}
                                >
                                  {/* <option value="" disabled={true} selected>Select Currency</option> */}
                                  <option value="USD">USD</option>
                                  <option value="AUD">AUD</option>
                                  <option value="INR">INR</option>

                                </Form.Control>
                              </div>
                            </div>
                            <p className="notetext">Service Fee 3.25%</p>
                            <p className="notetext">You Will Receive {(Number(convertedPrice) - Number(convertedPrice) * 0.025).toFixed(5)} {currency}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6}>
                            <div className="unloackBox">
                              <div>
                                <h1>Unlock once purchased</h1>
                                <p>Carbon Credit Certificates will be unlocked after successful transaction</p>
                              </div>
                              <div className="customeSwitch">
                                <input type="checkbox" id="unlock" />
                                <label htmlFor="unlock"></label>
                              </div>
                            </div>
                          </Col>

                        </Row>
                      </>
                    )}
                    {minttype === 'auction' && (
                      <>
                        <Row>
                          <Col lg={6}>
                            <Row>
                              <Col className="mb-3" lg={12}>
                                <div className="enterAmountbox">
                                  <div className="mainePrice">
                                    <Form.Control
                                      type="text"
                                      id="proj_title"
                                      placeholder="Price per CTNFT"
                                      name="project_title"
                                      onChange={(e) => setPrice(e.target.value)}
                                      // onChange={(e) =>
                                      //   formHandler(e, "erf_registration_number")
                                      // }
                                      // pattern="/^\S*$/"
                                      minLength="2"
                                      maxLength="64"
                                      autoComplete="off"
                                    />
                                    <img src="/images/svg/usdcIcon.svg" alt="" />
                                  </div>
                                  <div>
                                    <img src="/images/svg/exchangeArrow.svg" alt="" />

                                  </div>
                                  <div className="d-flex align-item-center gap-2">
                                    <Form.Control
                                      type="text" readOnly
                                      id="proj_title"
                                      placeholder="Price "
                                      name="project_title"
                                      // onChange={(e) =>
                                      //   formHandler(e, "erf_registration_number")
                                      // }
                                      // pattern="/^\S*$/"
                                      value={Number(convertedPrice).toFixed(2)}
                                      minLength="2"
                                      maxLength="64"
                                      autoComplete="off"
                                    />
                                    <Form.Control
                                      as="select"
                                      className="form-select"
                                      id="proj_type"
                                      name="project_type"
                                      onChange={(e) => getCurrency("MATIC", e.target.value)}
                                    >
                                      <option value="USD">USD</option>
                                      <option value="AUD">AUD</option>
                                      <option value="INR">INR</option>


                                    </Form.Control>
                                  </div>
                                </div>
                                <p className="notetext">Service Fee 3.25%</p>
                                {/* <p className="notetext">Minimum increment is $1.</p> */}

                              </Col>
                              <Col lg={6}>
                                <label>Auction length</label>

                                {/* <DatePicker className="form-control w-100" selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                                {/* <Form.Control
                                  type="text"
                                  id="proj_title"
                                  placeholder="Minimum bid for per CTNFT"
                                  name="project_title"
                                 
                                  // onChange={(e) =>
                                  //   formHandler(e, "erf_registration_number")
                                  // }
                                  // pattern="/^\S*$/"
                                  // minLength="2"
                                  // maxLength="64"
                                  // autoComplete="off"
                                > */}
                                <Form.Control
                                      as="select"
                                      className="form-select"
                                      id="proj_type"
                                      name="project_type"
                                      onChange={(e) => setAuction_length(e.target.value )}
                                    >
                                      {
                                     arrayauction.map((res)=>
                                      <option value={res}>{res} Days</option>
                                     )
                                      }
                                    </Form.Control>
                               
                                      
                              </Col>
                             {/* <Col lg={6}> */}
                                {/* <DatePicker className="form-control w-100" selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                                {/* <label>End date & time</label>
                                <Form.Control
                                  type="date"
                                  id="proj_title"
                                  placeholder="Minimum bid for per CTNFT"
                                  name="project_title"
                                  
                                  value={'deepak'}
                                  minLength="2"
                                  maxLength="64"
                                  autoComplete="off"
                                />
                              </Col>  */}
                            </Row>
                          </Col>



                        </Row>
                        <Row>
                          <Col lg={6}>
                            <div className="unloackBox">
                              <div>
                                <h1>Unlock once purchased</h1>
                                <p>Carbon Credit Certificates will be unlocked after successful transaction</p>
                              </div>
                              <div className="customeSwitch">
                                <input type="checkbox" id="unlock" />
                                <label htmlFor="unlock"></label>
                              </div>
                            </div>
                          </Col>

                        </Row>
                      </>
                    )}
                    <Row>
                      <Col lg={6}>
                        <div className="moreInfoBtns">
                          <Button
                            className="secondaryBtn submitBtn"
                            variant=""
                            type="submit"
                            onClick={onSubmit}
                          // onClick={()=>selectWalletConnect()}
                           disabled={disablesubmit}
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
      <StatusDialog flagForModalParam={flagForModalFunc} flagForModal={flagForModal} walletshow={walletModalOpen} walletFunc={selectWalletConnect}></StatusDialog>
    </>
  );
}

export default MintNFT;