import React from "react";
import styles from "./profile.module.scss";
import { Link } from "react-router-dom";

const ProfileComplete = ({ finishUpdate }) => {
  return (
    <div className={styles.ProfileComplete}>
     <img src="/images/svg/submitDocs.svg" width={200} className="mx-auto" alt="" />
      <div className={styles.ProfileCompleteMessage}>
        You have provided your personal & KYC Documents
        successfully
      </div>
      <div className={styles.ProfileCompleteSummary}>
        Thank you for providing updated personal information with
        KYC documents for KYC process. Once platform verify your personal information and documents then you got an email confirmation for
        your account activation.
      </div>
      <div className={styles.ProfileCompleteButtonWrapper}>
        <a onClick={finishUpdate}>
          <div className={'secondaryBtn submitBtn btn'}>Back to Home</div>
        </a>
      </div>
    </div>
  );
};

export default ProfileComplete;
