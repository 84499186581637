import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from "../context/Toast/useToast";
import { generateVerificationOTP, verifyAccountOTP } from '../action/walletApiAction';
import { ToastProvider } from '../context/Toast';

const Verification = ({ history }) => {
	//console.log(history);
	const { addToast } = useToast();
	var authData = useSelector((state) => state?.admin?.verificationData)

	console.log("verification data", authData )
	console.log(history.location.state)
	authData = authData ? authData : history.location.state;
	const [otp, setOTP] = useState('');
	const [error, setError] = useState('');
	const [isDisabled, setisDisabled] = useState(true)


	console.log("localStorage", localStorage.getItem('userId'))

	const [time, setTime] = useState(10); // 600 seconds = 10 minutes

	// const timer = {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
	useEffect(async () => {
		try {
			console.log("Auth Data :", authData)
			if (time > 0) {
				const interval = setInterval(() => {
					setTime(prevTime => {
						if (prevTime <= 0) {
							clearInterval(interval);
							return 0;
						}
						return prevTime - 1;
					});
				}, 1000)
			} else {
				setisDisabled(true)
			}
			

			let verify = await generateVerificationOTP(authData)
			console.log("checking response from wallet api", verify)
			if (verify.status === "success") {
				console.log("working")
					; // Update every second
				addToast({
					message: "OTP sent successfully",
					type: "success",
					duration: 3000,
				})
			} if (verify.status === "Error") {
				addToast({
					message: "OTP generation failed",
					type: "Error",
					duration: 3000,
				})
			}

		} catch (error) {
			console.log(error)
		}
	}, [0]);

	const minutes = Math.floor(time / 60);
	const seconds = time % 60;
	const [objAuth, setObjAuth] = useState({
		email: "",
		partner_id: "",
		otp: 0
	})

	const resendOTP = async () => {
		setTime(10)
		
		setisDisabled(true)

		try {
			console.log("data to be sent for otp generation", authData)
			let res = await generateVerificationOTP(authData)
			console.log("otp on resend button", res)
			if (res.status === "success") {
				// alert("OTP GENERATED")

				addToast({
					message: "OTP sent Successfully",
					type: "success",
					duration: 3000,
				})

			}if (res.status === "Error") {
				addToast({
					message: "OTP generation failed",
					type: "Error",
					duration: 3000,
				})
			}
		} catch {

			// alert('OTP Regeneration failed')
			addToast({
				message: "OTP send failed",
				type: "Error",
				duration: 3000,
			})

		}


	}

	useEffect(() => {
		if (time === 0) {
			setisDisabled(false)
		}
	}, [time])

	const verifyAction = async (event) => {
		// console.log("event", event);
		console.log("otp", otp);
		let objAuth = {}
		objAuth.email = authData.email || history.state.email
		objAuth.partner_id = authData.partner_id || history.state.partner_id

		objAuth.otp = otp
		console.log(objAuth)
		console.log("POST Data :: >", objAuth)
		if (otp.length === 6) {
			var verifyAuth = await verifyAccountOTP(objAuth)
			console.log("Response :: > ", verifyAuth.data.status)
			if (verifyAuth.data.status === "Success") {
				addToast({

					message: "SuccessFully verified",
					type: "success",
				});
				history.push({ pathname: "/login" });
			} if (verifyAuth.data.status === "Error") {
				addToast({
					message: "wrong OTP",
					type: "error",
				});

			} else {

				addToast({
					message: "SuccessFully verified",
					type: "success",
				});
			}

		} else {
			addToast({
				message: "Please enter 6-digit OTP",
				type: "error",
			});
		}
	}

	/* Api Calling */
	/** useEffect(() => {
		
		if(history.location && history.location.state && history.location.state._id){
			setUserId(history.location.state._id);
		}else{
			window.location.href = "/login";
		}
	},[]);
	*/
	return (
		<Container>

			<div className="verificationSec burger">
				<Row className="align-items-center">
					<Col xs={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
						<Form className="boxShaddow">
							<div className="heading">
								<h1>Verification</h1>
								<h6>We have just sent you SMS and email with OTP, <br /> please enter below to verify</h6>
							</div>

							<div className="verifyCode">
								<div className="verifySMS">
									<Form.Group as={Row} controlId="formHorizontalOTP">
										<Form.Label column sm={3} className="text-right">OTP</Form.Label>
										<Col sm={6}>
											<Form.Control type="number" placeholder="Enter OTP" pattern="[0-9]{6}" minLength="6" maxLength="6" name="otp" defaultValue={otp} onChange={e => { setOTP(e.target.value); setError('') }} />
											<small className="error">{error}</small>
										</Col>
									</Form.Group>
								</div>
								{/* <ul className="verifySMS">
									<li>OTP</li>
									<li>
										
										 <Form.Control type="text" placeholder="|" />
										<Form.Control type="text" placeholder="|" />
										<Form.Control type="text" placeholder="|" /> 
									</li>
									<li>
										<Navbar.Text>
											<span>Resend on <span>00:59</span></span>
											<a href="#"></a>
										</Navbar.Text>
									</li> 
								</ul> */}
								{/* <ul className="verifyEmail">
									<li>EMAIL</li>
									<li>
										<Form.Control type="text" placeholder="|" />
										<Form.Control type="text" placeholder="|" />
										<Form.Control type="text" placeholder="|" />
										<Form.Control type="text" placeholder="|" />
									</li>
									<li>
										<Navbar.Text>
											<span> <span></span></span>
											<a href="#">Resend</a>
										</Navbar.Text>
									</li>
								</ul> */}
							</div>
							<div className='text-center mt-3'>
								{isDisabled === false ? (
									<Button
										className='text-white'
										disabled={isDisabled}

										onClick={resendOTP}
									>
										Resend OTP
									</Button>
								) : (
									<p className='text-dark' >{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</p>
								)}

							</div>

							<div className="verifyBtns">
								<Link to="/login" className="secondaryBtn me-1 me-md-2" variant="">Cancel</Link>
								<Button className="primaryBtn ms-1 ms-md-2" variant="" type="button" onClick={(e) => verifyAction(e.target.value)}>Verify Now</Button>

							</div>
						</Form>
					</Col>
				</Row>
			</div>

		</Container>
	);
};


export default Verification;
