import React from 'react'
import ViewCTNFT from '../component/ViewCTNFT'

function ViewNFT() {
  return (
    <>
        <ViewCTNFT />
    </>
  )
}

export default ViewNFT