import styles from "./blog.module.scss";
import BASE_URL from "../../utils/appUrls";

const NewsSection = ({ data }) => {
  return (
    <div className={styles.NewsSectionWrapper}>
      <div className={styles.NewsSection}>
        <div className={styles.NewsSectionTitle}>
          <div dangerouslySetInnerHTML={{ __html: data?.title }} />
        </div>
        {data?.banner_image.length > 0 &&
            data?.banner_image.map((info, index) => (
            <>
            {index === 0 || index == 2 || index == 4 ? (
            <div className={styles.NewsSectionRowWrapper}>
              <img
                src={BASE_URL.HOST + info?.image_url}
                className={styles.NewsSectionRowImage}
              />
              <div
                className={styles.NewsSectionRowContent}
                style={{
                  backgroundImage: BASE_URL.HOST + info?.image_url,
                }}
              >
                <div className={styles.NewsSectionRowContentSubtitle}>
                  <b className={styles.NewsSectionRowContentSubtitleBold}>
                    {info?.head}
                  </b>
                </div>
                <div className={styles.NewsSectionRowContentTitle}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: info?.heading,
                    }}
                  />
                </div>
                <div className={styles.NewsSectionRowContentDescription}>
                  <div
                    dangerouslySetInnerHTML={{ __html: info?.text }}
                  />
                </div>
                <div className={styles.NewsSectionRowContentLinks}>
                  <b className={styles.NewsSectionRowContentSubtitleBold}>
                    Read More
                    <img src={"/images/common/arrow.svg"} />
                  </b>
                </div>
              </div>
            </div>
            ) : (
            <div className={styles.NewsSectionRowWrapper}>
              <div className={styles.NewsSectionRowContent}>
                <div className={styles.NewsSectionRowContentSubtitle}>
                  <b className={styles.NewsSectionRowContentSubtitleBold}>
                    {info?.head}
                  </b>
                </div>
                <div className={styles.NewsSectionRowContentTitle}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: info?.heading,
                    }}
                  />
                </div>
                <div className={styles.NewsSectionRowContentDescription}>
                  <div
                    dangerouslySetInnerHTML={{ __html: info?.text }}
                  />
                </div>
                <div className={styles.NewsSectionRowContentLinks}>
                  <b className={styles.NewsSectionRowContentSubtitleBold}>
                    Read More
                    <img src={"/images/common/arrow.svg"} />
                  </b>
                </div>
              </div>
              <img
                src={BASE_URL.HOST + info?.image_url}
                className={styles.NewsSectionRowImage}
              />
            </div>
          )}
          </>
        ))}
      </div>
    </div>
  );
};

export default NewsSection;
