import styles from "./about.module.scss";
const About = ({ data }) => {
    return (
        <div className={styles.AboutUs}>
            <div className="container-wrapper">
                <div className={styles.aboutMainTitle}>
                    <div dangerouslySetInnerHTML={{ __html: data?.title }} />
                </div>
                <div className={styles.AboutUsText}>
                    <p dangerouslySetInnerHTML={{ __html: data?.banner_text }} />
                </div>
            </div>
        </div>
    );
};

export default About;