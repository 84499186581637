import Header from "../component/Header";
import Footer from "../component/Footer";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useToast } from "../context/Toast/useToast";
import { useDispatch, useSelector } from "react-redux";
import { setLoginCheck, setProfile } from "../slices/auth";
import {
  getCartData,
  getQuateDate,
  getUserDetails,
} from "../action/Api.action";
import { StorageHelper } from "../utils/StorageHelper";
import { setCartData, setQuoteData } from "../slices/home";
import MiniFooter from "../component/MiniFooter";
const Layout = ({ children }) => {
  const { addToast } = useToast();

  const [visibleFooter, setVisibleFooter] = useState(true);
  const LoginCheck = useSelector((state) => state?.auth);

  const footer = useSelector((state) => state?.home?.footer);
  const history = useHistory();
  const dispatch = useDispatch();

  const userDetail = StorageHelper.getUserInfo();
  const [UserId, AccessToken, WalletAddress] = [
    userDetail.user_id,
    userDetail.access_token,
    userDetail.wallet_address,
  ];
  const fetchUserDetail = async () => {
    const result = await getUserDetails();
    if (result?.status) {
      const firstFour = WalletAddress?.substr(0, 4);
      const lastFour = WalletAddress?.substr(
        WalletAddress?.length - 4,
        WalletAddress?.length
      );
      const Wallet_Address = firstFour + "......" + lastFour;
      dispatch(setProfile({ ...result?.data, walletAddress: Wallet_Address }));
      dispatch(setLoginCheck(true));
    }
    // else {
    //   addToast({ message: "Something went wrong", type: "error" });
    // }
  };
  const fetchQuoteData = async () => {
    const result = await getQuateDate();
    dispatch(setQuoteData(result?.order_id));
    fetchCartData(result?.order_id);
  };
  const fetchCartData = async (data) => {
    const body = {
      order_id: data,
    };
    const result = await getCartData(body);
    if (result.status) {
      dispatch(setCartData(result?.cartDetail));
    }
  };
  useEffect(() => {
    if (LoginCheck?.loginCheck === false || LoginCheck?.profileData != {}) {
      if (UserId && AccessToken) {
        fetchUserDetail();
        fetchQuoteData();
      } else {
        //localStorage.clear();
       //  history.push("/");
      }
    }
  }, [LoginCheck.loginCheck]);
  useEffect(() => {
    setVisibleFooter(footer);
  }, [footer]);
  // useEffect(() => {
  //   if (quoteData !== null) {
  //     fetchCartData();
  //   }
  // }, [quoteData]);
  // console.log("children", children);
  return (
    <>
      <div>
        <Header />
        <div className="main-layout" visibleFooter={visibleFooter}>
          {children}
        </div>
        {visibleFooter ? <Footer /> : <MiniFooter />}
        {/* {window.location.pathname !== "/edit-profile" ? (
          <>{visibleFooter ? <Footer /> : <MiniFooter />}</>
        ) : (
          ""
        )} */}
      </div>
    </>
  );
};

export default Layout;
