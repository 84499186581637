import styles from "./blog.module.scss";
import BASE_URL from "../../utils/appUrls";

const TrendingSection = ({ data }) => {
  return (
    <div className={styles.TrendingSectionWrapper}>
      <div className={styles.TrendingSection}>
        <div className={styles.TrendingSectionTitle}>
          <div dangerouslySetInnerHTML={{ __html: data?.title }} />
        </div>
        <div className={styles.TrendingSectionRowWrapper}>
          {data?.banner_image.length > 0 &&
            data?.banner_image.map((info, index) => (
            <>
              <div className={styles.TrendingSectionCard} key={index}>
                <img
                  src={BASE_URL.HOST + info.image_url}
                  className={styles.TrendingSectionCardImage}
                />
                <div className={styles.TrendingSectionCardContent}>
                  <div className={styles.TrendingSectionRowContentSubtitle}>
                    <b className={styles.TrendingSectionRowContentSubtitleBold}>
                      {info?.head}
                    </b>
                  </div>
                  <div className={styles.TrendingSectionRowContentTitle}>
                    <div dangerouslySetInnerHTML={{ __html: info?.heading }} />
                  </div>
                  <div className={styles.TrendingSectionRowContentDescription}>
                    <div dangerouslySetInnerHTML={{ __html: info?.text }} />
                  </div>
                  <div className={styles.TrendingSectionRowContentLinks}>
                    <b className={styles.TrendingSectionRowContentSubtitleBold}>
                      Read More
                      <img
                        src={"/images/common/arrow.svg"}
                        className={styles.NewsSectionButtonArrow}
                        />
                    </b>
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrendingSection;
