import React, { useEffect, useState } from "react";
import { Modal, Nav } from 'react-bootstrap'

function KycStatus(props) {
  const [showWallet, setShowWallet] = useState(true);

    useEffect(() => {
        setShowWallet(props.statusshow);
        // setShowFucn(props.walletFunc)
        console.log('deepak',props);
      }, [props]);
      const showFucn = () => {
        props.walletFunc();
      };
  return (
    <>
          <Modal className="kycstatusdialog" size="lg" centered show={showWallet}>
            <div className="kycstatusBox">
              <img src="/images/svg/kyStatusImage.svg" alt="" width={350} />
              <h1>Verifying Your Details</h1>
              <p>Your KYC  details are currently being processed. Our team is diligently working on verifying the information provided to ensure compliance with regulatory standards.</p>
              <Nav.Link to={'/dashboard'}> <button  className="secondaryBtn  mx-auto w-auto btn px-5">Back to Home</button> </Nav.Link>
            </div>
          </Modal>
    </>
  )
}

export default KycStatus