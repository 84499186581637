import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Button } from "@blueprintjs/core";
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
// import toastr from "../_Common/toaster";
import { useToast } from "../../context/Toast/useToast";
import { connectWalletAction, createWalletAction } from "../../action/walletApiAction";
import CustomLoader from "../../common/customLoader/index"

const WalletNotFound = ({ removeSpacing }) => {
  const { addToast } = useToast();
  const [showPass, setshowPass] = useState("password");
  const [confshowPass, setconfshowPass] = useState("password");
  const [showWPass, setshowWPass] = useState("password");

  const history = useHistory();
  const [fields, setFields] = useState({
    password: "",
    confirmPassword: "",
    email: localStorage.getItem("email"),
    user_id: localStorage.getItem("userId")
  })
  const [loading, setLoading] = useState(false)

  const [walletPassword, setWalletPassword] = useState({
    walletPassVal: "",
    user_id: localStorage.getItem('userId')
  })
  const [isWalletConnected, setIsWalletConnected] = useState('false')
  const [isWalletAddress, setIsWalletAddress] = useState('')
  useEffect(() => {
    setIsWalletConnected(localStorage.getItem('wallet_connect'))
    setIsWalletAddress(localStorage.getItem('wallet_address'))
  }, [])

  useEffect(() => {
  }, [isWalletConnected, isWalletAddress])

  const handleCreateConnectWallet = async () => {

    if (walletPassword.walletPassVal === "") {

      return addToast({
        message: "Wallet Password is required",
        type: "error",
      });

    }

    setLoading(true)
    let res = await connectWalletAction(walletPassword)
    if (res.status === "Error") {
      setLoading(false)
      return addToast({
        message: "Wrong Password",
        type: "error",
      });
    }
    if (res.status === "success") {
      localStorage.setItem("balance", res.data.balance)
      addToast({
        message: "Wallet Connected Successfully",
        type: "success",
      });
      setLoading(false)
      localStorage.setItem("wallet_connect", true)
      history.push(`/dashboard`);
    }
  }

  const handleChange = (name, value) => {
    setFields((p) => ({ ...p, [name]: value }))
  }
  const handleWalletPassChange = (name, value) => {
    setWalletPassword((p) => ({ ...p, [name]: value }))
  }
  const handleSubmit = async () => {
    let valid = true
    if (fields.password === "") {
      valid = false
      return addToast({
        message: "Password is required",
        type: "error",
      });
    }
    if (fields.confirmPassword === "") {
      valid = false
      return addToast({
        message: "Confirm Password is required",
        type: "error",
      });
    }
    if (fields.password !== fields.confirmPassword) {
      valid = false
      return addToast({
        message: "Password and Confirm Password Do not match",
        type: "error",
      })
    }else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(fields.password)) {
      return addToast({
        message: 'Invalid password. Password must be 8-16 characters, and must have at least one uppercase, one lowercase, one number and one special character',
        type: "error",
      })

    };
    if (fields.email === "" && fields.user_id === "") {
      return addToast({
        message: "Login Required!!",
        type: "error",
      })
    };
    const response = await createWalletAction(fields)
    if (response.status === "success") {
      localStorage.setItem("wallet_connect", true)
      localStorage.setItem("wallet_address", response.data.address)

      localStorage.setItem("private_key", response.data.private_key)
    }
    history.push(`/dashboard`);
  }
  useEffect(() => { console.log("walletPassword", walletPassword); }, [walletPassword])
  const showPassword = (e) => {
    console.log(e.target);
    const getPassId = document.getElementById(e)
    if (getPassId.id === 'password') {
      setshowPass(showPass === 'password' ? 'text' : 'password')
    } else if (getPassId.id === 'wpassword') {
      setshowWPass(showWPass === 'password' ? 'text' : 'password')

    }

    else {
      setconfshowPass(confshowPass === 'password' ? 'text' : 'password')
    }


  }
  return (
    <>
      {loading && (
        <div>
          <CustomLoader />
        </div>
      )}
      <div className={'walletNotFound ' + (!removeSpacing ? styles.NoBondsFound : styles.noMarginBond)}>
        <div className={styles.icon}>
          <img src="/images/svg/walletNotFound.svg" alt="Wallet Not Found" />
        </div>
        {(isWalletConnected === 'false' && isWalletAddress === '') &&
          <>
            {(isWalletConnected === 'false' && isWalletAddress === '') && <div className={styles.text}>Wallet not found !</div>}

            <div className="mb-3 mt-3 w-25">
              <div className="position-relative">
                <input
                  type={showPass}
                  className="form-control"
                  placeholder="Password"
                  id="password"
                  name="password"
                  autoComplete="none"
                  onChange={(e) => handleChange("password", e.target.value)}
                />
                <button type="button" className="btn passwordSHow" onClick={() => showPassword('password')}><i class={"fa-regular " + (showPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>
              </div>
              {/* {errors.password && <span>This field is required</span>} */}
            </div>
            <div className="mb-3 w-25">
              <div className="position-relative">
                <input
                  type={confshowPass}
                  className="form-control"
                  placeholder="Confirm Password"
                  id="cpassword"
                  name="cpassword"
                  autoComplete="none"
                  onChange={(e) => handleChange("confirmPassword", e.target.value)}


                />
                <button type="button" className="btn passwordSHow" onClick={() => showPassword('cpassword')}><i class={"fa-regular  " + (confshowPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>
              </div>
              {/* {errors.password && <span>This field is required</span>} */}
            </div>


            <div className={styles.text}>Please input your password to create a wallet </div>
            <Button
              className="primaryBtn btn"
              onClick={() => handleSubmit()}
            >
              Create Wallet
            </Button>
          </>

        }
        {(isWalletAddress !== '' && isWalletConnected === 'false') && <div className="mb-3 text-center w-25">
          <div className="position-relative mb-3">
            <input
              type={showWPass}
              className="form-control"
              placeholder="Enter Wallet Password"
              id="wpassword"
              name="wpassword"
              autoComplete="none"
              onChange={(e) => handleWalletPassChange("walletPassVal", e.target.value)}


            />
            <button type="button" className="btn passwordSHow" onClick={() => showPassword('wpassword')}><i class={"fa-regular " + (showWPass === 'text' ? 'fa-eye' : 'fa-eye-slash')}></i></button>
          </div>
          {/* <div className={styles.text}>Please {(isWalletAddress === '' && isWalletConnected === 'false') ? "create" : "connect"} wallet to continue</div> */}
          <div className={'mt-3 mx-auto ' + styles.text}>
            {/* <Link to="/" className="primaryBtn" onClick={handleCreateConnectWallet}> */}
            {(isWalletAddress !== '' && isWalletConnected === 'false') && <>
              <Button
                className="primaryBtn btn mx-auto"
                onClick={handleCreateConnectWallet}
              >
                Connect Wallet
              </Button></>
            }
            {/* </Link> */}
          </div>
        </div>}



        {/* <div className={!removeSpacing ? styles.NoBondsFound : styles.noMarginBond}>
      <div className={styles.icon}>
      <img src="/images/svg/walletNotFound.svg" alt="Wallet Not Found" />
      </div>
      <div className={styles.heading}>Wallet not found !</div>
      <div className={styles.text}>Please {localStorage.getItem("wallet_address") === '' ? "Create" : "Connect" } Wallet to continue</div>
        <div className={styles.text}>
        <Link to="/" className="primaryBtn btn" onClick={handleClick}>
          {localStorage.getItem("wallet_address") === '' ? "Create" : "Connect" } Wallet
      </Link>
      </div> */}
      </div>

    </>
  );
};

export default WalletNotFound;
