import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import * as Yup from "yup";
import { FormikProps, useFormik } from "formik";
import CustomInput from "../../../common/input";
import CustomSelect from "../../../common/select";
import classNames from "classnames";
import axios, { AxiosRequestConfig } from "axios";
// import ProfileUpdate from "components/modal/profileUpdate";
import { OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
// import { getInvestorDetails } from "components/editProfile/InvestorProfile/API";
import { StorageHelper } from "../../../utils/StorageHelper";
import Url from "../../../utils/appUrls";
import {
  fetchCountryData,
  getStatesList,
  uploadProfileImage,
  getUserDetails,
} from "../../../action/Api.action";
import { useToast } from "../../../context/Toast/useToast";
import Loader from "../../../common/loader";
import CustomLoader from "../../../common/customLoader";
import MultiselectWithBadges from "../../../common/multiselectWithBadges";
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../../slices/auth";
import {fetchUser} from '../../../action/Api.action';
const curr_year = new Date().getFullYear()
export const validateIssuerProfileSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  mobile: Yup.string()
    .required("Phone is required")
    .min(9, "Phone Number must be at least 9 characters.")
    .max(15, "Phone Number must not be more than 15 characters.")
    .matches(/^[+]{0,1}[0-9]{9,15}$/, "Invalid mobile number"),
    website: Yup.string()
    .required("website is required")
    .matches(/^(http(s)?:\/\/.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/, "Invalid website"),
  street: Yup.string()
    .trim()
    .required("Address Line 1 is required")
    .max(150, "Address Line 1 must not be more than 150 characters."),
  street2: Yup.string()
    .trim()
    .optional()
    .max(150, "Address Line 2 must not be more than 150 characters."),
  city: Yup.string()
    .trim()
    .required("City is required")
    .max(50, "City must not be more than 50 characters."),
  zip: Yup.string()
    .optional()
    .max(10, "Zip must not be more than 10 characters.")
    .matches(/([0-9])/g, "Accept only numeric value."),
  country_id: Yup.number()
    .required("Country is required")
    .min(0, "Please Select a country."),
  short_name: Yup.string()
    .trim("Company Name cannot include leading and trailing spaces")
    .required("Company Name is required"),
  year_of_inception: Yup.string()
    .test('CurrYear',"Year of inception must be less than current year", value => Number(value) <= curr_year)
    .trim("Year of Inception cannot include leading and trailing spaces")
    .min(4, "Year of Inception must be at least 4 characters.")
    .max(4, "Year of Inception must not be more than 4 characters.")
    .matches(/([0-9])/g, "Accept only numeric value.")
    .required("Year of Inception is required"),
  current_md_id: Yup.string()
    .trim("Current MD cannot include leading and trailing spaces")
    .required("Current MD is required"),
  company_registration_number: Yup.string()
    .trim(
      "Company Registration Number cannot include leading and trailing spaces"
    )
    .required("Company Registration Number is required"),
  head_office: Yup.string()
    .trim("Head Office cannot include leading and trailing spaces")
    .required("Head Office is required"),
  phone: Yup.string()
    .optional()
    .min(9, "Phone must be at least 9 characters.")
    .max(15, "Phone must not be more than 15 characters.")
    .matches(/^[+]{0,1}[0-9]{9,15}$/, "Invalid phone number"),
   annual_revenue_ids: Yup.array().of(
     Yup.object().shape({
       year: Yup.string().matches(/^[0-9]{4}$/, "Annual revenue year is invalid."),
       revenue: Yup.string().matches(/^(1-9)?[0-9]{1,}$/, "Invalid revenue value"),
     })
   ),
});


const initialValues = {
  name: "",
  email: "",
  mobile: "",
  street: "",
  street2: "",
  city: "",
  state_name: "",
  country_name: "",
  state_id: -1,
  country_id: -1,
  zip: "",
  phone: "",
  short_name: "",
  partner_id: -1,
  portal_user_type: "",
  issuer_id: -1,
  // issuer_type: "",
  // industry_ids: [],
  annual_revenue_ids: [],
  image_url: "",
  image: "",
  year_of_inception: "",
  current_md_id: "",
  company_registration_number: "",
  company_type: "",
  head_office: "",
  website: "",
};
const AddInformation = ({ changeStep }) => {
  const [details , setDetails] = useState({
    name : "",
    email : "",
    phone : "",
    mobile: "",
  })
  //custom code
  const handleFetch = async (data) => {
    console.log("My test 1 " ,localStorage.getItem('userId'))
    let userData  = new FormData();
    userData.append('user_id',localStorage.getItem("userId"))
    console.log(userData)
    try {
      let userFetch = await fetchUser(userData)
     console.log(userFetch)  
    
    //  document.getElementById('userName').value =  userFetch.user 
    //  document.getElementById('userEmail').value = userFetch.email 
    //  document.getElementById('userMobile').value =userFetch.phone 
    //  document.getElementById('userPhone').value = userFetch.phone 
    //  setDetails({
    //   name :  userFetch.user ,
    //   email : userFetch.email ,
    //   phone : userFetch.Phone, 
    //  })

     const newState = {
      ...formik.values,
      name:  userFetch.user ,
      email: userFetch.email,
      mobile:userFetch.Phone,
      phone: userFetch.Phone,
    };
    formik.setFormikState((prevState) => ({
      ...prevState,
      values: newState,
    }));




    } catch (error) {
      console.log(error)
    }
    console.log(details)
  }
  
    useEffect(() => {
      handleFetch()
      }, []);
    

  //custom code ends
  const userDetail = StorageHelper.getUserInfo();
  const [UserId, AccessToken, WalletAddress] = [
    userDetail.user_id,
    userDetail.access_token,
    userDetail.wallet_address,
  ];
  const { addToast} = useToast();
  const dispatch = useDispatch();
  const [IsLoading, setIsLoading] = useState(false);
  const [IsSaving, setIsSaving] = useState(false);
  const authcontext = useSelector((state) => state.auth);
  const partner_type = authcontext?.profileData?.partner_type;
  const partner_state = authcontext?.profileData?.partner_state;

  const readOnly = partner_type === "producer" && partner_state === "approved";

  const [IssuerProfileValues, setIssuerProfileValues] = useState(initialValues);
  const fetchIssuerProfile = async (user_id, token) => {
    setIsLoading(true);
    const result = await getUserDetails(user_id, token);
    if (result.status) {
      setIssuerProfileValues(result.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      //   const result = await getInvestorDetails(user_id, token);
      //   if (result.data.status) {
      //     setIssuerProfileValues({
      //       ...initialValues,
      //       ...result.data.result[0],
      //     });
      //   }
      //   setIsLoading(false);
    }
  };
  const ImageInputRef = useRef(null);
  const [Image, setImage] = useState(null);
  const [ModalOpen, setModalOpen] = useState(false);
  const [uploadproImage, SetuploadproImage] = useState()

  const handleSubmit = async (data) => {
    setIsLoading(true);
    setIsSaving(true);
    // if (data.annual_revenue_ids.length < 1) {
    //   addToast({ message: "Please add Annual Revenue", type: "error" });
    //   return null;
    // }
    let issuer_image = localStorage.getItem("issuer_image");

    let currencyData = CurrencyList.filter((i) => i.name === "AUD");
    const currency_id = currencyData[0].currency_id;
    
    const anuualRevenueArr = data.annual_revenue_ids.map((categoryObj) => ({
      //@ts-ignore
      /*currency: isNaN(categoryObj.currency)
        ? //@ts-ignore
          categoryObj.currency_id
        : categoryObj.currency,*/
      currency: currency_id,
      revenue: categoryObj.revenue,
      year: categoryObj.year,
    }));

    const ReqData = {
      // auth_access_token: AccessToken,
      user_id: parseInt(UserId),
      name: data.name.trim(),
      email: data.email.trim(),
      street: data.street.trim(),
      street2: data.street2.trim(),
      city: data.city.trim(),
      zip: data.zip.trim(),
      state_id:
        parseInt(String(data.state_id)) > -1
          ? parseInt(String(data.state_id))
          : false,
      country_id:
        parseInt(String(data.country_id)) > -1
          ? parseInt(String(data.country_id))
          : false,
      current_md: data.current_md_id.trim(),
      head_office: data.head_office.trim(),
      website: data.website.trim(),
      // issuer_type: data.issuer_type,
      year_of_inception:
        parseInt(String(data.year_of_inception)) > -1
          ? parseInt(String(data.year_of_inception))
          : false,
      company_registration_number: data.company_registration_number.trim(),
      short_name: data.short_name.trim(),
      mobile: data.mobile,
      phone: data.phone,
      partner_type: "producer",
      // annual_revenue_ids: data.annual_revenue_ids.map(({currency_id, ...rest}) => rest),
      annual_revenue_ids: anuualRevenueArr,
      // industry_ids: data.industry_ids.map((i) => i.industry_id),
    };

    if (formik.values.image || issuer_image === "true" || true) {
      const config = {
        method: "post",
        url: Url.HOST + "/" + Url.PROFILE_UPDATE,
        headers: {
          "Content-Type": "text/plain",
        },
        data: ReqData,
      };
      const response = await axios(config)
        .then(async (res) => {
          //const result = await getUserDetails();
          if (res.data.status ) {
          /**  const firstFour = WalletAddress?.substr(0, 4);
            const lastFour = WalletAddress?.substr(
              WalletAddress?.length - 4,
              WalletAddress?.length
            );
            const Wallet_Address = firstFour + "......" + lastFour;
            dispatch(
              setProfile({ ...result?.data, walletAddress: Wallet_Address })
            ); */
            addToast({
              message: "Information has been added successfully.",
              type: "success",
            });
            localStorage.removeItem("issuer_image");
            changeStep(1);
          } else {
            let errormsg;
            if (res && res?.data) {
              errormsg = res?.data?.message;
            } else {
              errormsg = "Profile update failed.";
            }
            addToast({ message: errormsg, type: "error" });
          }
          return res;
        })
        .catch((err) => {
          addToast({ message: "Profile update failed.", type: "error" });
          return err;
        });
    } else {
      addToast({ message: "Please upload profile image.", type: "error" });
    }
    setIsSaving(false);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchIssuerProfile(UserId, AccessToken);
  }, [UserId, AccessToken]);
  const [UploadProgress, setUploadProgress] = useState();
  const formik = useFormik({
    initialValues: IssuerProfileValues,
    validationSchema: validateIssuerProfileSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: handleSubmit,
  });
  const [IndustryIds, setIndustryIds] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [IssuerType, setIssuerType] = useState([]);
  const [CountryList, setCountryList] = useState([]);
  useEffect(() => {
    const fetchDropDownData = async () => {
      const result = await fetchCountryData();
      setCountryList(result.result[0].country_ids);
      //   setIssuerType(result.data.result[0].issuer_type);
      //   setIndustryIds(
      //     result.data.result[0].industry_ids.map((i) => ({
      //       industry_id: i.industry_id,
      //       industry_name: i.name,
      //     }))
      //   );
      setCurrencyList(result.result[0].currency_ids);
    };
    fetchDropDownData();
  }, []);
  const [StateList, setStateList] = useState([]);
  useEffect(() => {
    const fetchStateList = async (country_id) => {
      const result = await getStatesList(country_id);
      if (result?.status) {
        setStateList(result.result);
      }
    };
    fetchStateList(formik.values?.country_id);
  }, [formik.values?.country_id]);
  const [AnnualRevenueEditIndex, setAnnualRevenueEditIndex] = useState(-1);
  const [AnnualRevenueSave, setAnnualRevenueSave] = useState(false);
  const [Copied, setCopied] = useState(false);

  let currencyData = CurrencyList.filter((i) => i.name === "AUD");


  // new route to fetch user data 
useEffect(()=>{
  if(Image !== null || formik.values.image_url){
    SetuploadproImage(true)
  }
},[Image, formik.values.image_url])
  
const removeAssets = () =>{
  SetuploadproImage(false)
}


  return (
    <div className="quoteRequestSec ">
    <div className="moreInfoSec signUpForm formContent  boxShaddow burger">
    <div className={styles.AddInformation}>
      {IsLoading && (
        <div className={styles.LoadingRow}>
          <CustomLoader />
        </div>
      )}
      <div className={styles.HeaderRow}>
        <h4 className="heading ">Personal Information</h4>
        <div
          className={styles.RefreshButton}
          onClick={() => {
            const newState = {
              ...formik.values,
              name: IssuerProfileValues.name,
              email: IssuerProfileValues.email,
              mobile: IssuerProfileValues.mobile,
              phone: IssuerProfileValues.phone,
              website: IssuerProfileValues.website,
            };
            formik.setFormikState((prevState) => ({
              ...prevState,
              values: newState,
            }));
            formik.validateForm(newState);
          }}>
          <img
            src="/images/common/Refresh_Icon.png"
            className={styles.RefreshIcon}
          />
          Reset Section
        </div>
      </div>
      <div className={'mb-3 ' + styles.Row}>
        <div className={styles.ImageUpload}>
          <input
            type="file"
            ref={ImageInputRef}
            className={styles.Input}
            onChange={(e) => {
              if (e.target.files !== null && e.target.files.length > 0) {
                const img = e.target.files[0];
                if (
                  img.size < 2000000 &&
                  (img.type === "image/jpeg" ||
                    img.type === "image/png" ||
                    img.type === "image/jpg")
                ) {
                  setImage(img);
                  setUploadProgress(0);
                  setIsLoading(true)
                  uploadProfileImage({
                    image: img,
                    auth_access_token: AccessToken,
                    user_id: UserId,
                    onProgressUpload: (event) => {
                      setUploadProgress(
                        Math.round((100 * event.loaded) / event.total)
                      );
                    },
                  }).then((res) => {
                    setIsLoading(false)
                    setUploadProgress(undefined);
                    if (res.status) {
                      addToast({
                        message: "Logo updated successfully.",
                        type: "success",
                      });
                      // fetchIssuerProfile(UserId, AccessToken);
                      localStorage.setItem("issuer_image", true);
                    } else {
                      addToast({
                        message: "Logo update failed.",
                        type: "error",
                      });
                    }
                  });
                } else {
                  if (img.size > 2000000) {
                    addToast({
                      message: "Image size should be less than 2MB.",
                      type: "error",
                    });
                  }
                  if (
                    img.type !== "image/jpeg" &&
                    img.type !== "image/png" &&
                    img.type !== "image/jpg"
                  ) {
                    addToast({
                      message: "Image type should be jpeg, png or jpg.",
                      type: "error",
                    });
                  }
                }
              }
            }}
          />
          {uploadproImage === true ? (
            <>
            <div className={"photoUpload "  + styles.photoUpload}>
              <div className="imgpreview">
              <img  src={
                  Image !== null
                    ? URL.createObjectURL(Image)
                    : formik.values.image_url
                } className="img-fluid" alt="" />
              <button   onClick={() =>{removeAssets()}} className="closeBtnimg" type="button">
                <img src="/images/svg/CloseWindow.svg" alt="" />
              </button>
              </div>
          </div>
            </>
          ):(
            <>
            <div className={"photoUpload " + styles.photoUpload}>
              <label htmlFor="profilephoto"  onClick={() => {
                    if (ImageInputRef !== null) {
                      ImageInputRef.current.click();
                    }
                  }}>
              <img src={"/images/svg/UploadImage.svg"} alt="upload" />
              <h3>Upload Logo</h3>
              </label>
          </div>
            </>
          )}
        
          
          <div className={styles.UploadWrapper} hidden>
            <div className={styles.TitleWrapper}>
              <div className={styles.Title}>Logo</div>
              <div className={styles.UploadButton}>
                {/* <img
                  src="/plus-outline.png"
                  alt="Image Upload"
                  onClick={() => {
                    if (ImageInputRef !== null) {
                      ImageInputRef.current!.click();
                    }
                  }}
                /> */}
                <img
                  src="/images/common/upload.png"
                  style = {{"width" : "30px","height" : "30px"}}
                  alt="Change Image"
                  onClick={() => {
                    if (ImageInputRef !== null) {
                      ImageInputRef.current.click();
                    }
                  }}
                />
                {/*<img
                  src="/images/profile/trash.png"
                  alt="Delete Image"
                  onClick={() => {
                    setImage(null);
                  }}
                />*/}
              </div>
            </div>
            <div className={styles.PreviewWrapper}>
              <img
                src={
                  Image !== null
                    ? URL.createObjectURL(Image)
                    : formik.values.image_url
                }
                className={styles.Preview}
              />
            </div>
            {!!UploadProgress && (
              <ProgressBar
                now={UploadProgress}
                label={`${UploadProgress}%`}
                animated
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            flex: 1,
            gap: 30,
          }}>
          {/* <div className={styles.Row}>
            <div
              className={styles.ReadOnly}
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(WalletAddress);
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 2000);
              }}>
             
              <div
                className={styles.Value}
                style={{
                  color: Copied ? "#5ba0ba" : "#181716",
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    backdropFilter: "blur(10px)",
                    backgroundColor: "#01d3a1",
                    gap: 10,
                    padding: 10,
                    borderLeft: "10px solid #01d3a1",
                  }}>
                  <b>{Copied ? "Copied" : "Copy"}</b>
                  <i className="fa-solid fa-copy"></i>
                </div>
                <div
                  style={{
                    textOverflow: "ellipsis",
                    width: "100%",
                    flexShrink: 1,
                    display: "inline-block",
                    whiteSpace: "nowrap",
                  }}>
                  {WalletAddress}
                </div>
              </div>
            </div>
          </div> */}
          <div className={  styles.Row}>
            <CustomInput
              id = "userName"
              label="Name"
              important
              name="name"
              onChange={formik.handleChange}
              value={formik.values?.name || details.name}
              error={formik.errors?.name}
              readonly={readOnly}
            />
            <CustomInput
            id = "userEmail"
              label="Email"
              important
              name="email"
              onChange={formik.handleChange }
              value={formik.values?.email || details.email}
              error={formik.errors?.email}
              readonly={true}
            />
          </div>
          <div className={styles.Row}>
          <CustomInput
            id = "userPhone"
              label="Mobile"
              name="phone"
              onChange={formik.handleChange}
              value={formik.values?.phone || details.phone}
              error={formik.errors?.phone}
              readonly={readOnly}
            />
            <CustomInput
            id = "userMobile"
              label="Phone"
              important
              name="mobile"
              onChange={formik.handleChange}
              // value={formik.values?.mobile || details.phone}
              error={formik.errors?.mobile}
              readonly={false}
            />
            
            <CustomInput
              label="Website"
              name="website"
              onChange={formik.handleChange}
              value={formik.values?.website}
              error={formik.errors?.website}
              readonly={readOnly}
            />
          </div>
        </div>
      </div>
      <div className={styles.HeaderRow}>
      <h4 className="heading ">Address</h4>
        <div
          className={styles.RefreshButton}
          onClick={(e) => {
            const newState = {
              ...formik.values,
              street: IssuerProfileValues.street,
              street2: IssuerProfileValues.street2,
              city: IssuerProfileValues.city,
              zip: IssuerProfileValues.zip,
              country_id: IssuerProfileValues.country_id,
              state_id: IssuerProfileValues.state_id,
            };
            formik.setFormikState((prevState) => ({
              ...prevState,
              values: newState,
            }));
            formik.validateForm(newState);
          }}>
          <img
            src="/images/common/Refresh_Icon.png"
            className={styles.RefreshIcon}
          />
          Reset Section
        </div>
      </div>
      <div className={styles.Row}>
        <CustomInput
          label="Address Line 1"
          important
          name="street"
          onChange={formik.handleChange}
          value={formik.values?.street}
          error={formik.errors?.street}
          readonly={readOnly}
        />
        <CustomInput
          label="Address Line 2"
          name="street2"
          onChange={formik.handleChange}
          value={formik.values?.street2}
          error={formik.errors?.street2}
          readonly={readOnly}
        />
      </div>
      <div className={styles.Row}>
        <CustomInput
          label="City"
          important
          name="city"
          onChange={formik.handleChange}
          value={formik.values?.city}
          error={formik.errors?.city}
          readonly={readOnly}
        />
        <CustomInput
          label="Zip"
          name="zip"
          onChange={formik.handleChange}
          value={formik.values?.zip}
          error={formik.errors?.zip}
          readonly={readOnly}
        />
        <CustomSelect
          label="Country"
          important
          items={CountryList.map((c) => ({
            label: c.name,
            value: c.country_id,
          }))}
          selectedValue={formik.values?.country_id}
          onChange={(v) => {
            formik.handleChange({ target: { name: "country_id", value: v } });
          }}
          error={formik.errors?.country_id}
          readonly={readOnly}
        />
        <CustomSelect
          label="State"
          items={StateList.map((c) => ({ label: c.name, value: c.state_id }))}
          selectedValue={formik.values?.state_id}
          onChange={(v) => {
            formik.handleChange({ target: { name: "state_id", value: v } });
          }}
          error={formik.errors?.state_id}
          readonly={readOnly}
        />
      </div>
      <div className={styles.HeaderRow}>
      <h4 className="heading ">Company Information</h4>
        <div
          className={styles.RefreshButton}
          onClick={(e) => {
            const newState = {
              ...formik.values,
              short_name: IssuerProfileValues.short_name,
              year_of_inception: IssuerProfileValues.year_of_inception,
              // issuer_type: IssuerProfileValues.issuer_type,
              current_md_id: IssuerProfileValues.current_md_id,
              company_registration_number:
                IssuerProfileValues.company_registration_number,
              head_office: IssuerProfileValues.head_office,
              industry_ids: IssuerProfileValues.industry_ids,
              annual_revenue_ids: IssuerProfileValues.annual_revenue_ids,
            };
            formik.setFormikState((prevState) => ({
              ...prevState,
              values: newState,
            }));
            formik.validateForm(newState);
          }}>
          <img
            src="/images/common/Refresh_Icon.png"
            className={styles.RefreshIcon}
          />
          Reset Section
        </div>
      </div>
      <div className={styles.Row}>
        <CustomInput
          name="short_name"
          label="Company Name"
          important
          onChange={formik.handleChange}
          value={formik.values?.short_name}
          error={formik.errors?.short_name}
          readonly={readOnly}
        />
        <CustomInput
          name="year_of_inception"
          important
          label="Year of Inception"
          onChange={formik.handleChange}
          value={String(formik.values?.year_of_inception)}
          error={formik.errors?.year_of_inception}
          readonly={readOnly}
        />
        {/* <CustomSelect
          label="Issuer Type"
          important
          items={IssuerType.map((i) => ({
            label: i.name,
            value: i.issuer_type_id,
          }))}
          selectedValue={formik.values?.issuer_type}
          onChange={(v) => {
            formik.handleChange({
              target: { name: "issuer_type", value: v },
            });
          }}
          error={formik.errors?.issuer_type}
          readonly={readOnly}
        /> */}
      </div>
      <div className={styles.Row}>
        <CustomInput
          label="Current MD/CEO"
          important
          name="current_md_id"
          onChange={formik.handleChange}
          value={formik.values?.current_md_id}
          error={formik.errors?.current_md_id}
          readonly={readOnly}
        />
        <CustomInput
          label="Company Registration Number"
          important
          name="company_registration_number"
          onChange={formik.handleChange}
          value={formik.values?.company_registration_number}
          error={formik.errors?.company_registration_number}
          readonly={readOnly}
        />
        <CustomInput
          label="Head Office"
          important
          name="head_office"
          onChange={formik.handleChange}
          value={formik.values?.head_office}
          error={formik.errors?.head_office}
          readonly={readOnly}
        />
      </div>
      <div className={styles.Row}>
        {/* <MultiselectWithBadges
          readonly={readOnly}
          options={IndustryIds.map((i) => ({
            label: i.industry_name,
            value: String(i.industry_id),
          }))}
          name="Industry"
          selected={formik.values?.industry_ids.map((i) => ({
            label: i.industry_name,
            value: String(i.industry_id),
          }))}
          onAdd={(v) => {
            const addedAlready =
              formik.values.industry_ids.findIndex(
                (i) => String(i.industry_id) === v
              ) > -1;
            const item = IndustryIds.find((i) => {
              return String(i.industry_id) === v;
            });
            if (!addedAlready && !!item) {
              const newIndustryIds = [
                ...formik.values.industry_ids.map((i) => ({
                  label: i.industry_name,
                  value: i.industry_id,
                })),
                { label: item.industry_name, value: item.industry_id },
              ];
              formik.handleChange({
                target: {
                  name: "industry_ids",
                  value: newIndustryIds.map((i) => ({
                    industry_id: i.value,
                    industry_name: i.label,
                  })),
                },
              });
            }
          }}
          onDelete={(v) => {
            formik.handleChange({
              target: {
                name: "industry_ids",
                value: formik.values.industry_ids.filter(
                  (i) => String(i.industry_id) !== v
                ),
              },
            });
          }}
        /> */}
      </div>
      <div className={styles.Row}>
        <div className={styles.AddRevenue}>
        <h4 className="heading ">Annual Revenue</h4>
        <div className='table-resposive tableBox kyctable'>
          <table>
              <thead>
                  <tr>
                      <td>Year</td>
                      <td>Revenue ({"AUD"})</td>
                      <td>Action</td>
                  </tr>
              </thead>
             
             
              <tbody>
              {formik.values?.annual_revenue_ids.map((a, index) =>
                AnnualRevenueEditIndex === index ? (
                  <>
                  <tr>
                    <td>
                    <input
                  className={'form-control w-auto mx-auto'}
                  value={a.year}
                  type="text" placeholder="Enter Year"
                  maxLength={4}
                  onChange={(e) => {
                    formik.handleChange({
                      target: {
                        name: "annual_revenue_ids",
                        value: [
                          ...formik.values.annual_revenue_ids.slice(0, index),
                          {
                            ...formik.values.annual_revenue_ids[index],
                            year: e.target.value,
                          },
                          ...formik.values.annual_revenue_ids.slice(index + 1),
                        ],
                      },
                    });
                  }}
                />
                    </td>
                    <td>
                    <input
                    className={'form-control w-auto mx-auto'}

                  value={a.revenue}
                  type="text"
                  maxLength={20} placeholder="Enter Revenue"
                  onChange={(e) => {
                    formik.handleChange({
                      target: {
                        name: "annual_revenue_ids",
                        value: [
                          ...formik.values.annual_revenue_ids.slice(0, index),
                          {
                            ...formik.values.annual_revenue_ids[index],
                            revenue: e.target.value,
                          },
                          ...formik.values.annual_revenue_ids.slice(index + 1),
                        ],
                      },
                    });
                  }}
                />
                    </td>
                    <td>
                    <div className="btnbox">

                    <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={<Tooltip>Save</Tooltip>}>
                    <button className="btn py-0">
                    <i
                      className="fa-solid fa-floppy-disk"
                      onClick={(e) => {
                        e.preventDefault();
                        if (readOnly) {
                          addToast({
                            message:
                              "The record has been approved. You can't edit this record.",
                            type: "error",
                          });
                          return;
                        }
                        setAnnualRevenueEditIndex(-1);
                        setAnnualRevenueSave(true);
                      }}></i>
                    </button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={<Tooltip>Delete</Tooltip>}>
                    <button className="btn py-0">
                    <i
                      className="fa-solid fa-trash-can"
                      onClick={(e) => {
                        e.preventDefault();
                        if (readOnly) {
                          addToast({
                            message:
                              "The record has been approved. You can't delete this record.",
                            type: "error",
                          });
                          return;
                        }
                        formik.handleChange({
                          target: {
                            name: "annual_revenue_ids",
                            value: [
                              ...formik.values.annual_revenue_ids.slice(
                                0,
                                index
                              ),
                              ...formik.values.annual_revenue_ids.slice(
                                index + 1
                              ),
                            ],
                          },
                        });
                        setAnnualRevenueEditIndex(-1);
                      }}></i></button>
                  </OverlayTrigger>
                  </div>
                    </td>
                  </tr>
                  </>
                ):(
                  <>
                    <tr>
                      <td>
                      {a.year}

                      {
                        //@ts-ignore

                        String(a.year) === "" ? (
                          <div className={styles.error}>Year cannot be empty.</div>
                        ) : (
                          !String(a.year).match(/([0-9])/g) && (
                            <div className={styles.error}>
                              Year must be a number.
                            </div>
                          )
                        )
                      }
                      </td>
                      <td>
                      {a.revenue}

                      {
                        //@ts-ignore
                        String(a.revenue) === "" ? (
                          <div className={styles.error}>
                            Revenue cannot be empty.
                          </div>
                        ) : (
                          !String(a.revenue).match(/([0-9])/g) && (
                            <div className={styles.error}>
                              Revenue must be a number.
                            </div>
                          )
                        )
                      }
                      </td>
                      <td>
                      <div className="btnbox">
                      <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={<Tooltip>Edit</Tooltip>}>
                     <button className="btn py-0">
                    <i
                      className="fa-solid fa-pencil"
                      onClick={(e) => {
                        e.preventDefault();
                        if (readOnly) {
                          addToast({
                            message:
                              "The record has been approved. You can't edit this record.",
                            type: "error",
                          });
                          return;
                        }
                        setAnnualRevenueEditIndex(index);
                      }}></i></button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={<Tooltip>Delete</Tooltip>}>
                     <button className="btn py-0">
                    <i
                      className="fa-solid fa-trash-can"
                      onClick={(e) => {
                        e.preventDefault();
                        if (readOnly) {
                          addToast({
                            message:
                              "The record has been approved. You can't delete this record.",
                            type: "error",
                          });
                          return;
                        }
                        formik.handleChange({
                          target: {
                            name: "annual_revenue_ids",
                            value: [
                              ...formik.values.annual_revenue_ids.slice(
                                0,
                                index
                              ),
                              ...formik.values.annual_revenue_ids.slice(
                                index + 1
                              ),
                            ],
                          },
                        });
                        setAnnualRevenueEditIndex(-1);
                      }}></i></button>
                  </OverlayTrigger>
                  </div>
                      </td>

                    </tr>
                  </>
                )
              
              )}
              </tbody>
            </table>
          </div>
          <div className="d-none">
          <div className={styles.Header}>
            <div className={styles.Title}>Year</div>
            <div className={styles.Title}>Revenue ({"AUD"})</div>
            {/*<div className={styles.Title}>Currency</div>*/}
            <div className={styles.Title}>Action</div>
          </div>
          {formik.values?.annual_revenue_ids.map((a, index) =>
            AnnualRevenueEditIndex === index ? (
              <div className={classNames([styles.TableRow, styles.activeLine])}>
                <input
                  className={styles.Input}
                  value={a.year}
                  type="text"
                  maxLength={4}
                  onChange={(e) => {
                    formik.handleChange({
                      target: {
                        name: "annual_revenue_ids",
                        value: [
                          ...formik.values.annual_revenue_ids.slice(0, index),
                          {
                            ...formik.values.annual_revenue_ids[index],
                            year: e.target.value,
                          },
                          ...formik.values.annual_revenue_ids.slice(index + 1),
                        ],
                      },
                    });
                  }}
                />
                <input
                  className={styles.Input}
                  value={a.revenue}
                  type="text"
                  maxLength={20}
                  onChange={(e) => {
                    formik.handleChange({
                      target: {
                        name: "annual_revenue_ids",
                        value: [
                          ...formik.values.annual_revenue_ids.slice(0, index),
                          {
                            ...formik.values.annual_revenue_ids[index],
                            revenue: e.target.value,
                          },
                          ...formik.values.annual_revenue_ids.slice(index + 1),
                        ],
                      },
                    });
                  }}
                />
                {/*<div className={styles.Input}>
                  <CustomSelect
                    items={currencyData.map((i) => ({
                      label: i.name,
                      value: i.currency_id,
                    }))}
                    selectedValue={a.currency}
                    onChange={(v) => {
                      formik.handleChange({
                        target: {
                          name: "annual_revenue_ids",
                          value: [
                            ...formik.values.annual_revenue_ids.slice(0, index),
                            {
                              ...formik.values.annual_revenue_ids[index],
                              currency: v,
                            },
                            ...formik.values.annual_revenue_ids.slice(
                              index + 1
                            ),
                          ],
                        },
                      });
                    }}
                  />
                </div>*/}
                <div className={styles.Title}>
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={<Tooltip>Save</Tooltip>}>
                    <i
                      className="fa-solid fa-floppy-disk"
                      onClick={(e) => {
                        e.preventDefault();
                        if (readOnly) {
                          addToast({
                            message:
                              "The record has been approved. You can't edit this record.",
                            type: "error",
                          });
                          return;
                        }
                        setAnnualRevenueEditIndex(-1);
                        setAnnualRevenueSave(true);
                      }}></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={<Tooltip>Delete</Tooltip>}>
                    <i
                      className="fa-solid fa-trash-can"
                      onClick={(e) => {
                        e.preventDefault();
                        if (readOnly) {
                          addToast({
                            message:
                              "The record has been approved. You can't delete this record.",
                            type: "error",
                          });
                          return;
                        }
                        formik.handleChange({
                          target: {
                            name: "annual_revenue_ids",
                            value: [
                              ...formik.values.annual_revenue_ids.slice(
                                0,
                                index
                              ),
                              ...formik.values.annual_revenue_ids.slice(
                                index + 1
                              ),
                            ],
                          },
                        });
                        setAnnualRevenueEditIndex(-1);
                      }}></i>
                  </OverlayTrigger>
                </div>
              </div>
            ) : (
              <div className={styles.TableRow}>
                <div className={styles.Title}>
                  {a.year}

                  {
                    //@ts-ignore

                    String(a.year) === "" ? (
                      <div className={styles.error}>Year cannot be empty.</div>
                    ) : (
                      !String(a.year).match(/([0-9])/g) && (
                        <div className={styles.error}>
                          Year must be a number.
                        </div>
                      )
                    )
                  }
                </div>
                <div className={styles.Title}>
                  {a.revenue}

                  {
                    //@ts-ignore
                    String(a.revenue) === "" ? (
                      <div className={styles.error}>
                        Revenue cannot be empty.
                      </div>
                    ) : (
                      !String(a.revenue).match(/([0-9])/g) && (
                        <div className={styles.error}>
                          Revenue must be a number.
                        </div>
                      )
                    )
                  }
                </div>
                {/*<div className={styles.Title}>
                  {
                    "AUD"

                    AnnualRevenueSave && AnnualRevenueEditIndex === -1 ? (
                      CurrencyList.find(
                        (c) => String(c.currency_id) === String(a.currency)
                      )?.name
                    : // a.currency
                    AnnualRevenueEditIndex === -1
                    ? CurrencyList.find(
                        (c) => String(c.name) === String(a.currency)
                      )?.name
                    : CurrencyList.find(
                        (c) => String(c.currency_id) === String(a.currency)
                      )?.name
                    )
                  }
                </div>*/}
                <div className={styles.Title}>
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={<Tooltip>Edit</Tooltip>}>
                    <i
                      className="fa-solid fa-pencil"
                      onClick={(e) => {
                        e.preventDefault();
                        if (readOnly) {
                          addToast({
                            message:
                              "The record has been approved. You can't edit this record.",
                            type: "error",
                          });
                          return;
                        }
                        setAnnualRevenueEditIndex(index);
                      }}></i>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key={"top"}
                    placement={"top"}
                    overlay={<Tooltip>Delete</Tooltip>}>
                    <i
                      className="fa-solid fa-trash-can"
                      onClick={(e) => {
                        e.preventDefault();
                        if (readOnly) {
                          addToast({
                            message:
                              "The record has been approved. You can't delete this record.",
                            type: "error",
                          });
                          return;
                        }
                        formik.handleChange({
                          target: {
                            name: "annual_revenue_ids",
                            value: [
                              ...formik.values.annual_revenue_ids.slice(
                                0,
                                index
                              ),
                              ...formik.values.annual_revenue_ids.slice(
                                index + 1
                              ),
                            ],
                          },
                        });
                        setAnnualRevenueEditIndex(-1);
                      }}></i>
                  </OverlayTrigger>
                </div>
              </div>
            )
          )}
          </div>
          <div
            className={styles.AddNewLine}
            onClick={(e) => {
              e.preventDefault();
              formik.handleChange({
                target: {
                  name: "annual_revenue_ids",
                  value: [
                    ...formik.values.annual_revenue_ids,
                    {
                      year: "",
                      revenue: "",
                      currency: "",
                    },
                  ],
                },
              });
              setAnnualRevenueEditIndex(
                formik.values.annual_revenue_ids.length
              );
            }}>
            <i className="fa-solid fa-plus"></i> Add a Line
          </div>
        </div>
      </div>
      <div className={styles.Row}>
        <div className={styles.Buttons}>
          <div
            className={'  secondaryBtn   btn w-auto ms-auto'}
            onClick={async (e) => {
              e.preventDefault();
              if (readOnly) {
                changeStep(1);
                return;
              }
              const err = await formik.validateForm();
              if (Object.values(err).length === 0) {
                formik.handleSubmit();
              } else {
                Object.values(err).forEach((e) => {
                  let msg;
                  if (Array.isArray(e)) {
                    e.forEach((i) => {
                      console.log("iiiiiiii", i);
                      if (i !== undefined) {
                        if (i.year) {
                          msg = i.year;
                        } else if (i.revenue) {
                          msg = i.revenue;
                        } else {
                          msg = "Please fill annual revenues.";
                        }
                      } else {
                        msg = "Please fill annual revenues.";
                      }
                    });
                  } else {
                    //msg = e;
                    msg = "Please fill all mandatory fields.";
                  }
                  addToast({
                    message: msg,
                    type: "error",
                  });
                });
              }
            }}>
            {IsSaving ? <Loader /> : "Next"}
          </div>
        </div>
      </div>
      {/* <ProfileUpdate
        handleClose={() => {
          setModalOpen(false);
        }}
        modalOpen={ModalOpen}
        label="Profile Updated Successfully"
      /> */}
    </div>
    </div>
    </div>
  );
};

export default AddInformation;
