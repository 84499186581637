import React, { useEffect, useRef, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { NAME } from "../../constant/constant";
import { capitalizeFirstLetter, useContainerDimensions } from "../Constant";
import style from "./style.module.scss";
const LineCharts = ({ data }) => {
  const [lineData, setLineData] = useState();
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);

  useEffect(() => {
    if (data) {
      const arr = [];
      Object.entries(data).map((info) => {
        arr.push({ name: capitalizeFirstLetter(info[0]), amount: info[1] });
      });
      setLineData(arr);
    }
  }, []);
  return (
    <>
      <div className={` ${style.customCard}`}>
        <div className="card">
          <div className={`card-body ${style.lineChart}`} ref={componentRef}>
            <h3>Monthly {NAME.certificate} Selling</h3>
            <LineChart
              width={width - 20}
              height={300}
              data={lineData}
              margin={{
                top: 30,
                right: 10,
                left: 0,
                bottom: 5,
              }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="amount"
                stroke="#008caa"
                strokeWidth={3}
                dot={{ strokeWidth: 2, r: 5 }}
              />
            </LineChart>
          </div>
        </div>
      </div>
    </>
  );
};
export default LineCharts;
