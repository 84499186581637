import React, { useEffect } from 'react'
import WalletMenu from '../WalletMenu'
import { Col, Row } from 'react-bootstrap';

function Index(props) {
    const {page} = props
    useEffect(()=>{
      
      console.log(props);
  },[props])
  return (
    <>
      <div className='walletMaineBox'>    
        <WalletMenu pagename={page} />
        <div className='walletRanderBox'>
          <Row className='justify-content-end'>
          <Col lg={2}>
            <select className='form-control trasectionselect form-select'>
              <option>Withdrawal</option>
              <option>Deposit</option>

            </select>
          </Col>
            <Col lg={12}>
              <div className='TrasactionTable table-responsive'>
                <table>
                  <thead>
                    <tr>
                        <td>Type</td>
                        <td>ID</td>
                        <td>Sender</td>
                        <td>Recipient</td>
                        <td>Amount</td>
                        <td>Date</td>
                        <td>Status</td>
                        </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td> Withdraw </td>
                      <td>  <div className='d-flex align-items-center gap-2 justify-content-center '>23fhgfdtywdbs... <button class="btn"><i class="fa-regular fa-copy"></i></button></div> </td>
                      <td>  <div className='d-flex align-items-center gap-2 justify-content-center '>23fhgfdtywdbs... <button class="btn"><i class="fa-regular fa-copy"></i></button></div> </td>

                      <td> Account</td>

                      <td> 123.895 USDC </td>
                      <td> 16-jun-2021 </td>
                      <td className='success'> Complete </td>

                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </div>
    </div>
    </>
  )
}

export default Index