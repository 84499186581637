import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getPageTypeData } from "../action/Api.action";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../component/About/banner";
import About from "../component/About/About";
import OurMission from "../component/About/OurMission";
import OurVision from "../component/About/OurVision";
import NewsSection from "../component/Blog/NewsSection";
import TrendingSection from "../component/Blog/TrendingSection";
import ShortReads from "../component/Blog/ShortReads";
import FactSec from "../component/Home/FactSec";
import FeaturesSec from "../component/Home/FeaturesSec";
import MoreDetailsSec from "../component/Home/MoreDetailsSec";
//import LatestRequestSec from "../component/Home/LatestRequestSec";
import PostRequestSec from "../component/Home/PostRequestSec";
import { useLoader } from "../context/Loader/useLoader";
import { setFooter } from "../slices/home";
import Footer from "../component/Footer";
import { setProfile } from "../slices/auth";

const Home = ({ history }) => {
  const { setLoading } = useLoader();
  const dispatch = useDispatch();
  const { pageType } = useParams();
  const [banners, setBanners] = useState([]);
  const [bannerSections, setBannerSections] = useState([]);
  const headerList = useSelector((state) => state?.home?.headerList);

  const handleCustomBack = () => {
    history.push('/');
  };

  const handleBackButton = () => {
    // Call your custom function
    handleCustomBack();
  }
  /* Api Calling */
  const fetchPageData = async (
    link = "/api/v1/homepage/home",
    pageType = "home"
  ) => {
    const result = await getPageTypeData(pageType);
    if (result) {
      result.banner_ids && setBanners(result.banner_ids);
      result.banner_section && setBannerSections(result.banner_section);
      setLoading(false);
    } else {
      console.log(result);
      setLoading(false);
    }
  };
  useEffect(() => {
    window.onpopstate = handleBackButton
    console.log("THIS IS CALLED");
    dispatch(setFooter(true));
    //setLoading(true, "Loading...");
    window.scrollTo(0, 0);
    setBannerSections([]);
    setBanners([]);
    headerList &&
      headerList.length > 0 &&
      headerList.map((header, index) => {
        if (!pageType) {
          fetchPageData();
        } else if (header.page_type_name === pageType) {
          fetchPageData(`/${header.cms_url}`, pageType);
        }
      });
  }, [pageType, headerList]);

  return (
    <Container>
      {banners?.length > 0 && <Banner banners={banners} pageType={pageType} />}

      {bannerSections?.length > 0 &&
        bannerSections?.map((info, index) => (
          <>
            <div key={index}>
              {pageType === undefined && (
                <>
                  {info.banner_for === "users" ? <FactSec data={info} /> : null}
                  {info.banner_type == "ir_cl" ? (
                    <FeaturesSec data={info} />
                  ) : null}
                  {info.banner_for == "videos" ? (
                    <MoreDetailsSec data={info} />
                  ) : null}
                  {/*info.banner_type == "token" ? (
							<LatestRequestSec data={info} />
						) : null*/}
                  {info.banner_type == "i_w_c" ? (
                    <PostRequestSec data={info} />
                  ) : null}
                </>
              )}

              {info.banner_type == "text" && pageType == "about" ? (
                <About data={info} />
              ) : (
                ""
              )}
              {info.banner_type == "ir_cl" && pageType == "about" ? (
                <OurMission data={info} />
              ) : (
                ""
              )}
              {info.banner_type == "il_cr" && pageType == "about" ? (
                <OurVision data={info} />
              ) : (
                ""
              )}
              {info.banner_type === "image_2_2" && pageType == "blog" ? (
                <NewsSection data={info} key={index} />
              ) : (
                ""
              )}
              {info.banner_for === "trending" && pageType == "blog" ? (
                <TrendingSection data={info} key={index} />
              ) : (
                ""
              )}
              {info.banner_for === "short_reads" && pageType == "blog" ? (
                <ShortReads data={info} key={index} />
              ) : (
                ""
              )}
            </div>
          </>
        ))}
    </Container>
  );
};

export default Home;
